<template>
	<v-app>
		<!-- Navigation -->
		<web-navigation></web-navigation>

		<!-- Sizes your content based upon application components -->
		<v-main class="tw-bg-gray-100">
			<!-- Provides the application the proper gutter -->
			<v-container class="py-10">
				<test-drive-form></test-drive-form>
			</v-container>
		</v-main>
		
		<!-- Footer -->
		<web-footer></web-footer>
	</v-app>
</template>

<script>
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
import TestDriveForm from "../../components/web/TestDriveForm";

export default {
	components: {
		WebNavigation,
		WebFooter,
		TestDriveForm,
	},
};
</script>

<style scoped>
</style>