<template>
  <v-row align="center" justify="center">
    <v-col
      cols="12"
      md="10"
      v-for="(inventory, index) in inventories"
      class="black--text"
      :key="index"
    >
      <v-card flat class="tw-px-8 lg:tw-px-48">
        <v-row>
          <v-col cols="12" md="8">
            <v-carousel hide-delimiters height="300">
              <v-carousel-item
                v-for="(gallery, i) in inventory.inventory_galleries"
                :key="i"
                :src="gallery.image"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col cols="12" md="4">
            <h2 class="text-h5">
              {{ inventory.dealer.company }}
            </h2>
            <h2 class="tw-text-sm">
              Tipe:
              {{ inventory.model }}
            </h2>
            <h2 class="tw-text-sm">
              Tahun:
              {{ inventory.year }}
            </h2>
            <h2 class="tw-text-sm">
              KM:
              {{ inventory.mileage }} Km
            </h2>
            <h2 class="tw-text-sm">
              Eksterior:
              {{ inventory.exterior }}
            </h2>
            <h2 class="tw-text-sm">
              Interior:
              {{ inventory.interior }}
            </h2>
            <h2 class="tw-text-sm">
              {{ inventory.isp_validation }}
            </h2>
            <h2 class="tw-text-sm mb-2">
              Rp.
              {{
                new Intl.NumberFormat("id-ID", {
                  maximumSignificantDigits: 3,
                }).format(inventory.price)
              }}
            </h2>
            <div>
              <v-btn
                fab
                x-small
                elevation="0"
                color="green accent-3"
                class="focus:tw-outline-none"
                @click="buttonClicked(inventory.id, inventory.phone)"
              >
                <v-icon color="white">mdi-whatsapp</v-icon>
              </v-btn>
              {{ inventory.phone_name }}
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-divider
        class="tw-bg-gray-900"
        v-if="index != inventories.length - 1"
      ></v-divider>
    </v-col>
    <inventory-dialog :id="id" :phone="phone"></inventory-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InventoryDialog from "@/components/web/InventoryDialog";

export default {
  components: {
    InventoryDialog,
  },
  data() {
    return {
      id: 0,
      phone: "",
    };
  },
  computed: {
    ...mapState("inventory", ["inventories"]),
  },
  methods: {
    ...mapActions("inventory", ["getInventories"]),
    buttonClicked(id, phone) {
      this.id = id;
      this.phone = phone;
      this.$store.dispatch("toogleDialogInventory");
    },
  },
  mounted() {
    this.getInventories();
  },
};
</script>

<style></style>
