<template>
	<v-dialog v-model="dialogCollection" scrollable persistent max-width="800">
		<v-card v-if="product">
			<v-card-title class="headline">
				{{ product.name }}
				<v-spacer></v-spacer>
				<v-btn @click="toggleDialogCollection" fab x-small color="red darken-2" dark>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-img :src="product.image" height="300" contain></v-img>
			<v-card-text v-html="product.desc" class="black--text text-body-1"></v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	computed: {
		...mapState(["dialogCollection"]),
		...mapState("product", ["product"]),
	},
	methods: {
		...mapActions(["toggleDialogCollection"]),
	},
};
</script>

<style>
</style>