<template>
	<div>
		<v-form @submit.prevent="storeCar(form)">
			<v-container>
				<!-- text form mobil -->
				<v-text-field
					v-model="form.name"
					placeholder="Nama mobil"
					required
					:error-messages="errors.name"
					prepend-icon="mdi-car"
					class="mb-3"
				></v-text-field>
				<!-- text form limited -->
				<v-select
					v-model="form.limited"
					:error-messages="errors.limited"
					:items="limited"
					item-text="name"
					item-value="key"
					label="Pilih Limited"
					prepend-icon="mdi-view-list"
				></v-select>
				<!-- text form gambar -->
				<v-file-input
					v-model="form.thumbnail_image"
					:error-messages="errors.thumbnail_image"
					:clearable="false"
					chips
					label="Masukan Gambar"
				></v-file-input>
				<!-- text form link -->
				<v-text-field
					v-model="form.link"
					placeholder="Masukan Link"
					required
					:error-messages="errors.link"
					prepend-icon="mdi-home"
					class="mb-3"
				></v-text-field>
				<!-- text form gambar Utama -->
				<v-file-input
					v-model="form.main_image"
					:error-messages="errors.main_image"
					:clearable="false"
					chips
					label="Masukan Gambar Utama"
				></v-file-input>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" right class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
			name: "",
			limited: '',
			link: '',
			main_image: [],
			thumbnail_image: [],
		},
		limited: [
			{key: 1, name: 'limited'},
			{key: 0, name: 'non limited'}
		]
	}),
	methods: {
		...mapActions("car", ["storeCar"]),
	},
	computed: {
		...mapState(["errors"]),
	},
};
</script>

<style>
</style>