import ContactLog from '../../../api/ContactLog'
import router from '../../../router'

export const getContactLogs = ({ commit }) => {
  ContactLog.all().then(response => {
    commit('SET_CONTACT_LOGS', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getContactLog = ({ commit }, payload) => {
  ContactLog.show(payload).then(response => {
    commit('SET_CONTACT_LOG', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeContactLog = ({ commit, dispatch }, payload) => {
  ContactLog.store(payload).then(response => {
    dispatch('removeErrors', null, { root: true })
    commit('SET_IS_CLICK', true)
    commit('SET_PHONE', response.data.sales_phone)
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateContactLog = ({ dispatch }, payload) => {
  ContactLog.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-contact-log' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteContactLog = ({ commit }, payload) => {
  ContactLog.delete(payload).then(() => {
    commit('UNSET_CONTACT_LOG', payload)
  }).catch(error => {
    console.log(error)
  });
}

export const unsetIsClick = ({ commit }) => {
  commit('SET_UNCLICK', false)
}
