export const SET_ERRORS = (state, payload) => {
  state.errors = payload;
};

export const UNSET_ERRORS = (state) => {
  state.errors = [];
};

export const TOGGLE_DIALOG = (state) => {
  state.dialogCollection = !state.dialogCollection;
};

export const TOGGLE_OFFER = (state) => {
  state.dialogOffer = !state.dialogOffer;
};

export const SET_NOTIFICATIONS = (state, payload) => {
  state.notifications = payload;
};

export const UNSET_NOTIFICATIONS = (state) => {
  state.notifications = null;
};

export const TOGGLE_DIALOG_CONTACT_DEALER = (state) => {
  state.dialogContactDealer = !state.dialogContactDealer;
};

export const TOGGLE_DIALOG_INVENTORY = (state) => {
  state.dialogInventory = !state.dialogInventory;
};
