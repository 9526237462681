<template>
  <div>
    <v-alert prominent color="grey darken-3" dark v-show="isBooking">
      <v-row align="center" justify="space-between">
        <v-col cols="12" key="1">
          <span class="text-h4" v-show="$vuetify.breakpoint.mdAndUp">{{
            notifications
          }}</span>
          <span class="text-h6" v-show="$vuetify.breakpoint.smAndDown">{{
            notifications
          }}</span>
        </v-col>
      </v-row>
    </v-alert>

    <v-card max-width="600" class="mx-auto" v-show="isBooking == false">
      <v-card-title>Informasi Anda</v-card-title>
      <v-card-text>
        <v-form class="contact-form" @submit.prevent="storeBookingDrive(form)">
          <v-text-field
            label="Nama"
            name="name"
            :error-messages="errors.name"
            v-model="form.name"
          ></v-text-field>
          <v-text-field
            label="e-mail"
            name="email"
            :error-messages="errors.email"
            v-model="form.email"
          ></v-text-field>
          <v-text-field
            label="Telepon"
            name="phone"
            :error-messages="errors.no_telp"
            v-model="form.no_telp"
          ></v-text-field>
          <v-textarea
            label="Alamat"
            name="address"
            rows="3"
            :error-messages="errors.address"
            v-model="form.address"
          ></v-textarea>
          <v-select
            label="Model"
            :items="cars"
            :error-messages="errors.car"
            item-text="name"
            item-value="name"
            v-model="form.car"
          ></v-select>
          <v-text-field
            label="Tanggal"
            type="datetime-local"
            :error-messages="errors.date"
            name="date"
            date
            v-model="form.date"
          ></v-text-field>
          <v-select
            label="Kota"
            :items="cities"
            :error-messages="errors.city"
            item-text="name"
            item-value="id"
            v-on:change="getDealerByCity(form.city)"
            v-model="form.city"
          ></v-select>
          <v-select
            label="Pilihan Dealer"
            :items="dealerByCity"
            :error-messages="errors.dealer"
            item-text="company"
            item-value="company"
            v-model="form.dealer"
          ></v-select>
          Dengan ini saya memberikan persetujuan atas data yang dikumpulkan
          untuk diproses dan digunakan oleh PT Mercedes-Benz Distribution
          Indonesia dan dealer resmi untuk tujuan memberi informasi dan
          penawaran mengenai produk serta jasa melalui:
          <v-checkbox
            v-model="form.contact_options"
            color="indigo"
            :error-messages="errors.contact_options"
            label="Telepon"
            value="phone"
            class="mt-5"
          ></v-checkbox>
          <v-checkbox
            v-model="form.contact_options"
            color="indigo"
            :error-messages="errors.contact_options"
            label="e-mail"
            value="email"
            class="mt-0"
          ></v-checkbox>
          <v-checkbox
            v-model="form.contact_options"
            color="indigo"
            :error-messages="errors.contact_options"
            label="SMS/WhatsApp"
            value="whatsapp"
            class="mt-0"
          ></v-checkbox>
          Untuk informasi lebih lanjut mengenai perlindungan data, kunjungi:
          <a href="http://mb4.me/dataprotectionID"
            >http://mb4.me/dataprotectionID</a
          >
          <br />*Apabila e-mail tidak di terima dalam 5 (lima) menit, silahkan
          membuka "spam box" Anda
          <div class="text-right mt-4">
            <v-btn color="primary" type="submit" small tile depressed
              >Submit</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    form: {
      car: "",
      name: "",
      email: "",
      no_telp: "",
      address: "",
      date: "",
      dealer: "",
      contact_options: [],
    },
  }),
  computed: {
    ...mapState(["errors"]),
    ...mapState("car", ["cars"]),
    ...mapState("city", ["cities"]),
    ...mapState("dealer", ["dealers", "dealerByCity"]),
    ...mapState("web", ["isBooking", "notifications"]),
  },
  methods: {
    ...mapActions("car", ["getCars"]),
    ...mapActions("city", ["getCities"]),
    ...mapActions("dealer", ["getDealers", "getDealerByCity"]),
    ...mapActions("web", ["storeBookingDrive"]),
  },
  mounted() {
    this.getCars();
    this.getCities();
    this.getDealers();
  },
};
</script>

<style></style>
