<template>
  <v-row
    align="center"
    justify="center"
    v-if="Object.keys(carInterior).length > 0"
  >
    <v-col cols="12" md="10">
      <v-container fluid class="lg:tw-px-32">
        <h1 class="tw-text-2xl tw-tracking-wide text-center mb-6">
          See the Interior
        </h1>

        <iframe
          width="100%"
          height="640"
          style="width: 100%; height: 640px; border: none; max-width: 100%;"
          frameborder="0"
          allowfullscreen
          allow="xr-spatial-tracking; gyroscope; accelerometer"
          scrolling="no"
          :src="carInterior.link"
        >
        </iframe>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    id: null,
  },
  computed: {
    ...mapState("web", ["carInterior"]),
  },
  methods: {
    ...mapActions("web", ["getInterior"]),
  },
  mounted() {
    this.getInterior(this.id);
  },
};
</script>

<style></style>
