export const SET_CARS = (state, payload) => {
  state.cars = payload
}

export const SET_CAR = (state, payload) => {
  state.car = payload
}

export const UNSET_CAR = (state, payload) => {
  state.cars = state.cars.filter(item => {
    return item.id !== payload
  })
}

export const SET_LIMITED_CAR = (state, payload) => {
  state.limitedCar = payload
}
