import CodeInventoryLog from '../../../api/CodeInventoryLog'

export const getCodeInventoryLogs = ({ commit }) => {
  CodeInventoryLog.all().then(response => {
    commit('SET_CODE_INVENTORY_LOGS', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getCodeInventoryLog = ({ commit }, payload) => {
  CodeInventoryLog.show(payload).then(response => {
    commit('SET_CODE_INVENTORY_LOG', response.data)
  }).catch(error => {
    console.log(error)
  });
}


export const deleteCodeInventoryLog = ({ commit }, payload) => {
  CodeInventoryLog.delete(payload).then(() => {
    commit('UNSET_CODE_INVENTORY_LOG', payload)
  }).catch(error => {
    console.log(error)
  });
}
