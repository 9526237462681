import Api from "./Api";

const END_POINT = "/guests";

export default {
  all() {
    return Api.get(END_POINT);
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  store(data) {
    return Api.post(END_POINT, {
      code: data.code,
    });
  },
  
  checkCode(formGuest, dealer) {
    return Api.post('checkCode', {
      code: formGuest.code,
      company_name: dealer.company
    });
  },
  
  update(data) {
    return Api.put(`${END_POINT}/${data.id}`, {
      code: data.code,
    });
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
