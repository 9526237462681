export const SET_CAR_VARIANTS = (state, payload) => {
  state.carVariants = payload
}

export const SET_CAR_VARIANT = (state, payload) => {
  state.carVariant = payload
}

export const UNSET_CAR_VARIANT = (state, payload) => {
  state.carVariants = state.carVariants.filter(item => {
    return item.id !== payload
  })
}