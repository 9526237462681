import CarThreeSixty from '../../../api/CarThreeSixty'
import router from '../../../router'

export const getCarThreeSixties = ({ commit }) => {
  CarThreeSixty.all().then(response => {
    commit('SET_CAR_THREE_SIXTIES', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getCarThreeSixty = ({ commit }, payload) => {
  CarThreeSixty.show(payload).then(response => {
    commit('SET_CAR_THREE_SIXTY', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeCarThreeSixty = ({ dispatch }, payload) => {
  CarThreeSixty.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-car-three-sixty' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateCarThreeSixty = ({ dispatch }, payload) => {
  CarThreeSixty.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-car-three-sixty' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteCarThreeSixty = ({ commit }, payload) => {
  CarThreeSixty.delete(payload).then(() => {
    commit('UNSET_CAR_THREE_SIXTY', payload)
  }).catch(error => {
    console.log(error)
  });
}
