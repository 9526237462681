import Api from './Api'

const END_POINT = '/car-three-sixties'

export default {
  all() {
    return Api.get(END_POINT)
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`)
  },

  store(data) {
    return Api.post(END_POINT, {
      car_id: data.car_id,
      path: data.path,
    })
  },

  update(data) {
    return Api.put(`${END_POINT}/${data.id}`, {
      car_id: data.car_id,
      path: data.path,
    })
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`)
  }
}