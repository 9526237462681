<template>
  <v-app>
    <!-- Navigation -->
    <web-navigation></web-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="lg:tw-py-8">
        <h1 class="text-h4 text-capitalize tw-mt-4 px-2">frequently asked questions</h1>
        <faq-content></faq-content>
      </v-container>
    </v-main>

    <!-- Footer -->
    <web-footer></web-footer>
  </v-app>
</template>

<script>
import WebNavigation from "@/components/web/WebNavigation";
import WebFooter from "@/components/web/WebFooter";
import FaqContent from "@/components/web/FaqContent";

export default {
  components: {
    WebNavigation,
    WebFooter,
    FaqContent,
  },
};
</script>

<style>
</style>