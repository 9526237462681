import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";
import VueAnalytics from "vue-analytics";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },

  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // check if token available
    if (store.state.auth.token === null) {
      // if token null redirect to login
      next({
        name: "login",
      });
    } else {
      // if token available redirect to destination
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

Vue.use(VueAnalytics, {
  id: "UA-178742493-5",
  router,
});

export default router;
