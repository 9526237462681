<template>
	<div>
		<v-form @submit.prevent="storeContactDealer(form)">
			<v-container>
				<!-- text select dealer -->
				<v-select
					v-model="form.dealer_id"
					:items="dealers"
					item-text="company"
					item-value="id"
					label="Pilih Dealer"
					prepend-icon="mdi-office-building"
					:error-messages="errors.dealer_id"
					required
				></v-select>
				<!-- text form name -->
				<v-text-field
					v-model="form.name"
					placeholder="Masukan Nama"
					required
					prepend-icon="mdi-format-color-text"
					class="mb-3"
					:error-messages="errors.name"
				></v-text-field>
				<!-- text form status -->
				<v-select
					v-model="form.status"
					:error-messages="errors.status"
					:items="status"
					item-text="name"
					item-value="key"
					label="Pilih status"
					prepend-icon="mdi-view-list"
				></v-select>
				<!-- text form date -->
				<v-text-field
					v-model="form.tanggal"
					placeholder="Masukan Nama"
					required
					prepend-icon="mdi-calendar"
					class="mb-3"
					type="date"
					:error-messages="errors.tanggal"
				></v-text-field>
				<!-- text form phone -->
				<v-text-field
					v-model="form.phone"
					placeholder="Masukan Nomer Phone"
					required
					prepend-icon="mdi-phone"
					class="mb-3"
					:error-messages="errors.phone"
				></v-text-field>
				<!-- text form shift Start -->
				<v-text-field
					v-model="form.shift_start"
					placeholder="Masukan Jam Mulai"
					required
					type="time"
					prepend-icon="mdi-clock-time-three"
					class="mb-3"
					:error-messages="errors.shift_start"
				></v-text-field>
				<!-- text form shift end -->
				<v-text-field
					v-model="form.shift_end"
					placeholder="Masukan Jam Selesai"
					required
					type="time"
					prepend-icon="mdi-clock-time-nine"
					class="mb-3"
					:error-messages="errors.shift_end"
				></v-text-field>
		
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
			dealer_id: "",
			phone: "",
			name: "",
			tanggal: "",
			status: "",
			shift_start: "",
			shift_end: "",
		},
		status: [
			{key: 1, name: 'active'},
			{key: 0, name: 'non active'}
		]
	}),
	methods: {
		...mapActions("contactDealer", ["storeContactDealer"]),
		...mapActions("dealer", ["getDealers"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("dealer", ["dealers"]),
	},
	mounted() {
		this.getDealers();
	},
};
</script>

<style>
</style>