export const SET_INVENTORIES = (state, payload) => {
  state.inventories = payload
}

export const SET_INVENTORY = (state, payload) => {
  state.inventory = payload
}

export const UNSET_INVENTORY = (state, payload) => {
  state.inventories = state.inventories.filter(item => {
    return item.id !== payload
  })
}

export const SET_INVENTORY_CATEGORY = (state, payload) => {
  state.inventoryByCategory = payload
}