export const SET_CODE_DEALER_LOGS = (state, payload) => {
  state.codeDealerLogs = payload
}

export const SET_CODE_DEALER_LOG = (state, payload) => {
  state.codeDealerLog = payload
}

export const UNSET_CODE_DEALER_LOG = (state, payload) => {
  state.codeDealerLogs = state.codeDealerLogs.filter(item => {
    return item.id !== payload
  })
}