<template>
	<div v-if="codeInventoryLog">
		<v-form>
			<v-container>
				<v-row>
					<v-col>
            <v-list-item-title class="pa-2">Code: {{ form.code.code }}</v-list-item-title>

						<v-list-item-title class="pa-2">No Telp: {{ form.inventory.phone }}</v-list-item-title>

						<v-list-item-title class="pa-2">Inventory: {{ form.inventory.model }}</v-list-item-title>

						<v-list-item-title class="pa-2">Dibuat pada: {{ new Date(form.created_at) }}</v-list-item-title>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	props: {
		id: null,
	},
	data: () => ({}),
	methods: {
		...mapActions("codeInventoryLog", ["getCodeInventoryLog"]),
	},
	mounted() {
		this.getCodeInventoryLog(this.id);
	},
	computed: {
		...mapState(["errors"]),
		...mapState("codeInventoryLog", ["codeInventoryLog"]),
		form: {
			get() {
				return this.codeInventoryLog;
			},
		},
	},
};
</script>

<style>
</style> 