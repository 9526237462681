import CarVariant from '../../../api/CarVariant'
import router from '../../../router'

export const getCarVariants = ({ commit }) => {
  CarVariant.all().then(response => {
    commit('SET_CAR_VARIANTS', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getCarVariant = ({ commit }, payload) => {
  CarVariant.show(payload).then(response => {
    commit('SET_CAR_VARIANT', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeCarVariant = ({ dispatch }, payload) => {
  CarVariant.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-car-variant' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateCarVariant = ({ dispatch }, payload) => {
  CarVariant.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-car-variant' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteCarVariant = ({ commit }, payload) => {
  CarVariant.delete(payload).then(() => {
    commit('UNSET_CAR_VARIANT', payload)
  }).catch(error => {
    console.log(error)
  });
}
