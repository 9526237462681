import Banner from "../../../api/Banner";
import router from "../../../router";

export const getBanners = ({ commit }) => {
  Banner.all()
    .then((response) => {
      commit("SET_BANNERS", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getBanner = ({ commit }, payload) => {
  Banner.show(payload)
    .then((response) => {
      commit("SET_BANNER", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const storeBanner = ({ dispatch }, payload) => {
  Banner.store(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-banner" });
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const updateBanner = ({ dispatch }, payload) => {
  Banner.update(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-banner" });
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const deleteBanner = ({ commit }, payload) => {
  Banner.delete(payload)
    .then(() => {
      commit("UNSET_BANNER", payload);
    })
    .catch((error) => {
      console.log(error);
    });
};
