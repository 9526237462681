<template>
	<div>
		<v-form @submit.prevent="storeCarVariant(form)">
			<v-container>
				<!-- text select mobil -->
				<v-select
					v-model="form.car_id"
					:items="cars"
					item-text="name"
					item-value="id"
					label="Pilih Mobil"
					prepend-icon="mdi-car"
					:error-messages="errors.car_id"
					required
				></v-select>
				<!-- text form warna -->
				<v-text-field
					v-model="form.color"
					placeholder="Masukan Warna"
					required
					prepend-icon="mdi-palette"
					class="mb-3"
					:error-messages="errors.color"
				></v-text-field>
				<!-- text form gambar -->
				<v-file-input
					v-model="form.image"
					:clearable="false"
					:error-messages="errors.image"
					chips
					label="Masukan Gambar"
				></v-file-input>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
			color: "",
			image: [],
			car_id: "",
		},
	}),
	methods: {
		...mapActions("carVariant", ["storeCarVariant"]),
		...mapActions("car", ["getCars"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("car", ["cars"]),
	},
	mounted() {
		this.getCars();
	},
};
</script>

<style>
</style>