import InventoryGallery from '../../../api/InventoryGallery'
import router from '../../../router'

export const getInventoryGalleries = ({ commit }) => {
  InventoryGallery.all().then(response => {
    commit('SET_INVENTORY_GALLERIES', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getInventoryGallery = ({ commit }, payload) => {
  InventoryGallery.show(payload).then(response => {
    commit('SET_INVENTORY_GALLERY', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeInventoryGallery = ({ dispatch }, payload) => {
  InventoryGallery.store(payload).then(() => {
    // return console.log(response.data)
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-inventory-gallery' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateInventoryGallery = ({ dispatch }, payload) => {
  InventoryGallery.update(payload).then(() => {
    // return console.log(response.data)
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-inventory-gallery' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteInventoryGallery = ({ commit }, payload) => {
  InventoryGallery.delete(payload).then(() => {
    commit('UNSET_INVENTORY_GALLERY', payload)
  }).catch(error => {
    console.log(error)
  });
}
