<template>
	<div>
		<v-form>
			<v-container>
				<!-- text select name -->
				<v-text-field
					v-model="form.name"
					placeholder="Masukan name"
					clearable
					required
					prepend-icon="mdi-format-title"
					class="mb-3"
				></v-text-field>
				<!-- text select no_telp -->
				<v-text-field
					v-model="form.no_telp"
					placeholder="Masukan no_telp"
					clearable
					required
					prepend-icon="mdi-phone"
					class="mb-3"
				></v-text-field>
				<!-- text select address -->
				<v-text-field
					v-model="form.address"
					placeholder="Masukan address"
					clearable
					required
					prepend-icon="mdi-map-marker"
					class="mb-3"
				></v-text-field>
				<!-- text form Product -->
				<v-select
					v-model="form.product_id"
					:items="products"
					item-text="name" 
					item-value="id"
					label="Pilih Product"
					prepend-icon="mdi-car"
					required
				></v-select>
				<!-- text error -->
				<small class="tw-text-red-500" v-if="errors.content">{{ errors.content[0] }}</small>
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="storeProductInvoice(form)" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {
	},
	data: () => ({
		form: {
			name: "",
			no_telp: "",
			address: "",
			product_id: "",
		},
	}),
	methods: {
		...mapActions("productInvoice", ["storeProductInvoice"]),
		...mapActions("product", ["getProducts"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("product", ["products"]),
	},
	mounted() {
		this.getProducts();
	},
};
</script>

<style>
	
</style>