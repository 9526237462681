import Car from '../../../api/Car'
import router from '../../../router'

export const getCars = ({ commit }) => {
  Car.all().then(response => {
    commit('SET_CARS', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getCar = ({ commit }, payload) => {
  Car.show(payload).then(response => {
    commit('SET_CAR', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeCar = ({ dispatch }, payload) => {
  Car.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-car' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateCar = ({ dispatch }, payload) => {
  Car.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-car' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteCar = ({ commit }, payload) => {
  Car.delete(payload).then(() => {
    commit('UNSET_CAR', payload)
  }).catch(error => {
    console.log(error)
  });
}

export const showLimitedCar = ({ commit }) => {
  Car.showLimitedCar().then(response => {
    commit('SET_LIMITED_CAR', response.data)
  }).catch(error => {
    console.log(error)
  });
}

