<template>
	<div>
		<!-- data table -->
		<v-data-table :headers="headers" :items="inventories" mobile-breakpoint>
			<template v-slot:item="row">
				<tr>
					<td>{{ row.index + 1 }}</td>
					<td>{{ row.item.dealer.company }}</td>
					<td>{{ row.item.model }}</td>
					<td>{{ row.item.mileage }} Km</td>
					<td>{{ row.item.price | currency }}</td>
					<td>{{ row.item.phone }}</td>
					<td>
						<v-btn
							fab
							x-small
							color="primary"
							:to="{name: 'admin-inventory-edit', params: { id: row.item.id }}"
							class="m-2"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<v-btn fab x-small color="error" class="m-2" @click="confirm(row.item)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({
		headers: [
			{ text: "#", width: 10 },
			{ text: "dealer", value: "dealer" },
			{ text: "model", value: "model" },
			{ text: "mileage", value: "mileage" },
			{ text: "price", value: "price" },
			{ text: "phone", value: "phone" },
			{ text: "Action", width: 100 },
		],
	}),
	computed: {
		...mapState("inventory", ["inventories"]),
	},
	methods: {
		...mapActions(["removeErrors"]),
		...mapActions("inventory", ["getInventories", "deleteInventory"]),
		confirm(data) {
			this.$swal({
				title: "Are you sure?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, delete it!",
			}).then((result) => {
				if (result.value) {
					this.deleteInventory(data.id);
					this.$swal("Deleted!", "Item Deleted", "success");
				}
			});
		},
	},
	mounted() {
		this.getInventories();
		this.removeErrors();
	},
};
</script>

<style>
</style>