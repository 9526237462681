<template>
	<v-app>
		<!-- Navigation -->
		<web-navigation></web-navigation>

		<!-- Sizes your content based upon application components -->
		<v-main class="tw-bg-gray-900">
			<!-- Provides the application the proper gutter -->
			<v-container></v-container>
		</v-main>

		<!-- Footer -->
		<web-footer></web-footer>
	</v-app>
</template>

<script>
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
// import FinanceContent from "../../components/web/FinanceContent";

export default {
	components: {
		WebNavigation,
		WebFooter,
		// FinanceContent,
	},
};
</script>

<style scoped>
</style>