export const SET_INVENTORY_GALLERIES = (state, payload) => {
  state.inventoryGalleries = payload
}

export const SET_INVENTORY_GALLERY = (state, payload) => {
  state.inventoryGallery = payload
}

export const UNSET_INVENTORY_GALLERY = (state, payload) => {
  state.inventoryGalleries = state.inventoryGalleries.filter(item => {
    return item.id !== payload
  })
}