import Code from "../../../api/Code";
import ContactDealer from "../../../api/ContactDealer";
import router from "../../../router";

export const getCodes = ({ commit }) => {
  Code.all()
    .then((response) => {
      commit("SET_CODES", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCode = ({ commit }, payload) => {
  Code.show(payload)
    .then((response) => {
      commit("SET_CODE", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const storeCode = ({ dispatch }, payload) => {
  Code.store(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-code" });
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const updateCode = ({ dispatch }, payload) => {
  Code.update(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-code" });
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const deleteCode = ({ commit }, payload) => {
  Code.delete(payload)
    .then(() => {
      commit("UNSET_CODE", payload);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const checkCode = ({ commit, dispatch }, payload) => {
  if (payload.formGuest.code == "" || payload.formGuest.sales_name == "") {
    if (payload.formGuest.code == "" && payload.formGuest.sales_name == "") {
      const error = {
        code: "code is required",
        sales_name: "sales name is required",
      };

      return dispatch("getErrors", error, { root: true });
    } else if (payload.formGuest.sales_name == "") {
      const error = {
        sales_name: "sales name is required",
      };

      return dispatch("getErrors", error, { root: true });
    } else if (payload.formGuest.code == "") {
      const error = {
        code: "code is required",
      };

      return dispatch("getErrors", error, { root: true });
    }
  } else {
    Code.checkCode(payload.formGuest, payload.dealer)
      .then(() => {
        ContactDealer.getByName(payload.formGuest).then((response) => {
          commit("contactLog/SET_PHONE", response.data.phone, { root: true });
        });
        commit("SET_CODE_CODE", true);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          dispatch("getErrors", error.response.data.errors, { root: true });
        }
      });
  }
};

export const checkCodeInventory = async ({ dispatch }, payload) => {
  try {
    await Code.checkCodeInventory(payload);
    dispatch("removeErrors", null, { root: true });
  } catch (error) {
    dispatch("getErrors", error.response.data.errors, { root: true });
  }
};

export const setStatusCode = ({ commit }) => {
  commit("SET_CODE_CODE", false);
};
