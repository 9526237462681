<template>
	<v-app>
		<!-- Navigation -->
		<web-navigation></web-navigation>

		<!-- Sizes your content based upon application components -->
		<v-main>
			<!-- Provides the application the proper gutter -->
			<v-container>
				<inventory-detail-list :id="id"></inventory-detail-list>
			</v-container>
		</v-main>

		<!-- Footer -->
		<web-footer></web-footer>
	</v-app>
</template>

<script>
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
import InventoryDetailList from "../../components/web/InventoryDetailList";

export default {
	props: {
    id: null
  },
	components: {
		WebNavigation,
		WebFooter,
		InventoryDetailList,
	},
};
</script>

<style scoped>
	.background {
		background: url("https://ik.imagekit.io/vconexID/Mercedes/bg-inventory_OwUhJgCb7J.jpg")
			no-repeat center center fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}

	.overlay {
		background-color: rgba(0, 0, 0, 0.7) !important;
	}
</style>