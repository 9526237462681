export const SET_PRODUCT_CATEGORIES= (state, payload) => {
  state.productCategories = payload
}

export const SET_PRODUCT_CATEGORY = (state, payload) => {
  state.productCategory = payload
}

export const UNSET_PRODUCT_CATEGORY = (state, payload) => {
  state.productCategories = state.productCategories.filter(item => {
    return item.id !== payload
  })
}