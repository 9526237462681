<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 1.0,
    }"
    min-height="200"
    transition="fade-transition"
  >
    <v-row
      class="tw-px-8 lg:tw-px-20 tw-py-20 tw-bg-black"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-col cols="12" sm="12" md="6">
        <v-hover v-slot="{ hover }">
          <v-img
            lazy-src="https://ik.imagekit.io/vconexID/New_Cars_MB_Certified-01.png"
            src="https://ik.imagekit.io/vconexID/New_Cars_MB_Certified-01.png"
            height="350"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out v-card--reveal display-3"
                style="height: 100%;"
              >
                <router-link :to="{ name: 'car' }" class="white--text">
                  New Car
                </router-link>
              </div>
            </v-expand-transition>
          </v-img>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-hover v-slot="{ hover }">
          <v-img
            lazy-src="https://ik.imagekit.io/vconexID/New_Cars_MB_Certified-02.png"
            src="https://ik.imagekit.io/vconexID/New_Cars_MB_Certified-02.png"
            height="350"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out v-card--reveal display-3"
                style="height: 100%;"
              >
                <router-link
                  :to="{ name: 'certified' }"
                  class="white--text text-center"
                >
                  Certified Used Car
                </router-link>
              </div>
            </v-expand-transition>
          </v-img>
        </v-hover>
      </v-col>
    </v-row>

    <!-- version mobile -->
    <v-row
      class="tw-px-8 lg:tw-px-20 tw-py-20 tw-bg-black"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-col cols="12" sm="12" md="6">
        <v-img
          lazy-src="https://ik.imagekit.io/vconexID/latest_New-Car.jpg"
          src="https://ik.imagekit.io/vconexID/latest_New-Car.jpg"
          height="350"
        >
        </v-img>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-img
          lazy-src="https://ik.imagekit.io/vconexID/latest_Certified-Used-Car.jpg"
          src="https://ik.imagekit.io/vconexID/latest_Certified-Used-Car.jpg"
          height="350"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-lazy>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
  }),
};
</script>

<style lang="css">
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
  background-color: #00aeef;
}
</style>
