<template>
  <v-app>
    <!-- Navigation -->
    <web-navigation></web-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <web-slider></web-slider>
      <v-container class="text-center">
        <!-- Car List -->
        <v-btn
          color="primary"
          dark
          depressed
          class="my-5"
          @click="toggleDialogOffer()"
          >Claim This Offer</v-btn
        >
        <form-claim-offer></form-claim-offer>
        <car-list></car-list>
      </v-container>
    </v-main>

    <!-- Footer -->
    <web-footer></web-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
import WebSlider from "../../components/web/WebSlider";
import CarList from "../../components/web/CarList";
import FormClaimOffer from "../../components/web/FormClaimOffer";

export default {
  components: {
    WebNavigation,
    WebFooter,
    WebSlider,
    CarList,
    FormClaimOffer,
  },
  methods: {
    ...mapActions(["toggleDialogOffer"]),
  },
};
</script>

<style></style>
