import Offer from '../../../api/Offer'
// import router from '../../../router'

export const getOffers = ({ commit }) => {
  Offer.all().then(response => {
    commit('SET_OFFERS', response.data) 
  }).catch(error => {
    console.log(error)
  });
}

export const getOffer = ({ commit }, payload) => {
  Offer.show(payload).then(response => {
    commit('SET_OFFER', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeOffer = ({ dispatch }, payload) => {
  Offer.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    dispatch('removeNotifications', null, { root: true })
    dispatch('getNotifications', 'selamat anda berhasil', { root: true })
    dispatch('toggleDialogOffer', null, { root: true })
    
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteOffer = ({ commit }, payload) => {
  Offer.delete(payload).then(() => {
    commit('UNSET_OFFER', payload)
  }).catch(error => {
    console.log(error)
  });
}
