<template>
	<div>
		<v-form @submit.prevent="storeContactDealer(form)">
			<v-container>
				<!-- text select dealer -->
				<v-select
					v-model="form.dealer_id"
					:items="dealers"
					item-text="company"
					item-value="id"
					label="Pilih Dealer"
					prepend-icon="mdi-office-building"
					:error-messages="errors.dealer_id"
					required
				></v-select>
				<!-- text form phone -->
				<v-text-field
					v-model="form.phone"
					placeholder="Masukan Nomer Phone"
					required
					prepend-icon="mdi-phone"
					class="mb-3"
					:error-messages="errors.phone"
				></v-text-field>
				<!-- text form shift Start -->
				<v-text-field
					v-model="form.shift_start"
					placeholder="Masukan Jam Mulai"
					required
					type="time"
					prepend-icon="mdi-clock-time-three"
					class="mb-3"
					:error-messages="errors.shift_start"
				></v-text-field>
				<!-- text form shift end -->
				<v-text-field
					v-model="form.shift_end"
					placeholder="Masukan Jam Selesai"
					required
					type="time"
					prepend-icon="mdi-clock-time-nine"
					class="mb-3"
					:error-messages="errors.shift_end"
				></v-text-field>
		
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
			dealer_id: "",
			phone: "",
			shift_start: "",
			shift_end: "",
		},
	}),
	methods: {
		...mapActions("contactDealer", ["storeContactDealer"]),
		...mapActions("dealer", ["getDealers"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("dealer", ["dealers"]),
	},
	mounted() {
		this.getDealers();
	},
};
</script>

<style>
</style>