import Api from './Api'

const END_POINT = '/contact-dealers'

export default {
  all() {
    return Api.get(END_POINT)
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`)
  },

  store(data) {
    return Api.post(END_POINT, {
      dealer_id: data.dealer_id,
      phone: data.phone,
      name: data.name,
      status: data.status,
      tanggal: data.tanggal,
      shift_start: data.shift_start,
      shift_end: data.shift_end,
    })
  },

  update(data) {
    // return console.log(data)
    return Api.put(`${END_POINT}/${data.id}`, {
      dealer_id: data.dealer_id,
      phone: data.phone,
      name: data.name,
      status: data.status,
      tanggal: data.tanggal,
      shift_start: data.shift_start,
      shift_end: data.shift_end,
    })
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`)
  },
  
  getByName(data) {
    return Api.post('getContactDealerByName', {
      name: data.sales_name,
    })
  }
}