<template>
  <v-row justify="center" class="black white--text mt-n3 text-justify">
      <v-col cols="12" lg="6">
          <h1 class="text-h3 text-center my-10" v-if="$vuetify.breakpoint.lgAndUp">Mercedes-Benz Star Expo 2020</h1>
          <h1 class="text-h4 text-center mt-10 mb-3" v-if="$vuetify.breakpoint.mdAndDown">Mercedes-Benz Star Expo 2020</h1>
          <div class="pa-7 pa-lg-0">
              <p>Dalam merayakan 50 tahun Mercedes-Benz di Indonesia, kami mempersembahkan pameran Mercedes-Benz virtual untuk ketiga kalinya di tahun 2020.</p>
          <p>Mercedes-Benz Star Expo 2020 mengundang pelanggan dan penggemar otomotif untuk merasakan pengalaman menarik dari Mercedes-Benz; dimana pengunjung dapat memperoleh pengalaman virtual yang unik untuk melihat dan merasakan secara virtual mobil Mercedes-Benz impian.</p>
          <v-slide-x-transition>
              <div v-if="show">
              <p>Kami menyediakan pengalaman menarik dan informatif dari Mercedes-Benz antara lain:</p>
              <ul>
                  <li>
                      <h2 class="text-h5">Product</h2>
                      <p>Jajaran kendaraan Mercedes-Benz dengan informasi spesifikasi, tambahan fitur menarik seperti "color options", "360° viewer" yang memudahkan pelanggan untuk melihat tampilan pilihan warna serta tampilan kendaraan secara menyeluruh (360°) dari sisi eksterior.</p>
                  </li>
                  <li>
                      <h2 class="text-h5">Sales Consultant</h2>
                      <p>Anda dapat berkomunikasi dengan Sales Consultant Mercedes-Benz sesuai dengan dealer resmi pilihan Anda melalui tombol “Hubungi kami”.</p>
                  </li>
                  <li>
                      <h2 class="text-h5">Virtual Showroom</h2>
                      <p>Visualisasi yang berbeda dari rangkaian lengkap Mercedes-Benz dalam bentuk 3D.</p>
                  </li>
                  <li>
                      <h2 class="text-h5">Digital Center</h2>
                      <p>Area informasi terkait e-brochure dan daftar harga dari Mercedes-Benz.</p>
                  </li>
                  <li>
                      <h2 class="text-h5">Certified Used Car</h2>
                      <p>Mercedes-Benz Certified Used Cars adalah program mobil Mercedes-Benz bekas berkualitas yang hanya tersedia di dealer resmi Mercedes-Benz.</p>
                  </li>
                  <li>
                      <h2 class="text-h5">Accessories & Collection</h2>
                      <p>Raih kesempatan untuk mendapatkan lifestyle collection yang menarik dari Mercedes-Benz Collection.</p>
                  </li>
                  <li>
                      <h2 class="text-h5">Test Drive Booking</h2>
                      <p>Reservasi test drive secara online.</p>
                  </li>
              </ul>
          </div>
          </v-slide-x-transition>
          <div class="text-center mb-10">
            <v-btn text color="primary" class="tw-font-bold" @click="show = !show" v-if="!show">read more</v-btn>
            <v-btn text color="primary" class="tw-font-bold" @click="show = !show" v-else>show less</v-btn>
          </div>
          </div>
      </v-col>
  </v-row>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
}
</script>

<style>

</style>