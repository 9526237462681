export const SET_CODES = (state, payload) => {
  state.codes = payload
}

export const SET_CODE = (state, payload) => {
  state.code = payload
}

export const SET_CODE_CODE = (state, payload) => {
  state.statusCode = payload
}

export const UNSET_CODE = (state, payload) => {
  state.codes = state.codes.filter(item => {
    return item.id !== payload
  })
}