<template>
	<div>
		<v-form @submit.prevent="storePageImage(form)">
			<v-container>
				<!-- text select title -->
				<v-text-field
					v-model="form.title"
					placeholder="Masukan title"
					required
					:error-messages="errors.title"
					prepend-icon="mdi-format-title"
					class="mb-3"
				></v-text-field>
				<!-- text select desc -->
				<v-file-input
					v-model="form.image"
					:error-messages="errors.image"
					:clearable="false"
					chips
					label="Masukan Gambar"
				></v-file-input>
				<!-- text select type -->
				<v-select
					v-model="form.type"
					:error-messages="errors.type"
					:items="items"
					label="Pilih Type"
					prepend-icon="mdi-view-list"
				></v-select>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
			title: "",
			image: [],
			type: "",
		},
		items: ["Mercedes benz certified", "Finance"],
	}),
	methods: {
		...mapActions("pageImage", ["storePageImage"]),
	},
	computed: {
		...mapState(["errors"]),
	},
};
</script>

<style>
</style>