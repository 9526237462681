<template>
	<div>
		<v-form @submit.prevent="storeProductCategory(form)">
			<v-container>
				<!-- text select name -->
				<v-text-field
					v-model="form.name"
					placeholder="Masukan name"
					:error-messages="errors.name"
					required
					prepend-icon="mdi-format-title"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
			name: "",
		},
	}),
	methods: {
		...mapActions("productCategory", ["storeProductCategory"]),
	},
	computed: {
		...mapState(["errors"]),
	},
};
</script>

<style>
</style>