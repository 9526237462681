<template>
  <v-row align="center" justify="center" v-if="carVariant.length > 0">
    <v-col cols="12" md="10">
      <v-container fluid class="lg:tw-px-32">
        <h1 class="tw-text-2xl tw-tracking-wide text-center my-3">Color Options</h1>
        <v-carousel height="auto" cycle interval="4000" hide-delimiters show-arrows-on-hover>
          <v-carousel-item v-for="(item,i) in carVariant" :key="i">
            <v-img
              eager
              :src="item.image"
              lazy-src="https://ik.imagekit.io/vconexID/Mercedes/placeholder_color_selection.jpg"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: null,
  },
  computed: {
    ...mapState("web", ["carVariant"]),
  },
  methods: {
    ...mapActions("web", ["getCarVariant"]),
  },
  mounted() {
    this.getCarVariant(this.id);
  },
};
</script>

<style>
</style>