import Api from "./Api";

const END_POINT = "/cities";

export default {
  all() {
    return Api.get(END_POINT);
  },
  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },
  store(data) {
    return Api.post(END_POINT, {
      name: data.name,
    });
  },
  update(data) {
    return Api.put(`${END_POINT}/${data.id}`, {
      name: data.name,
    });
  },
  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
