export default {
  carBanner: [],
  carDetail: [],
  carVariant: [],
  carThreeSixty: [],
  carSpecification: [],
  carExteriorGallery: [],
  carInteriorGallery: [],
  collections: [],
  carInterior: [],
  notifications: [],
  isBooking: false,
  urlVideoFrame: 'https://www.youtube.com/embed/3aSCbqOG-wQ'
}