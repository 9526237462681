export const getErrors = ({ commit }, payload) => {
  commit("SET_ERRORS", payload);
};

export const removeErrors = ({ commit }) => {
  commit("UNSET_ERRORS");
};

export const toggleDialogCollection = ({ commit }) => {
  commit("TOGGLE_DIALOG");
};

export const getNotifications = ({ commit }, payload) => {
  commit("SET_NOTIFICATIONS", payload);
};

export const removeNotifications = ({ commit }) => {
  commit("UNSET_NOTIFICATIONS");
};

export const toggleDialogOffer = ({ commit }) => {
  commit("TOGGLE_OFFER");
};

export const toogleDialogContactDealer = ({ commit }) => {
  commit("TOGGLE_DIALOG_CONTACT_DEALER");
};
export const toogleDialogInventory = ({ commit }) => {
  commit("TOGGLE_DIALOG_INVENTORY");
};
