<template>
	<div v-if="inventoryGallery">
		<v-form>
			<v-container>
				<!-- text select inventory -->
				<v-select
					v-model="form.inventory_id"
					:items="inventories"
					:error-messages="errors.inventory_id"
					item-text="model"
					item-value="id"
					label="Pilih Inventory"
					prepend-icon="mdi-car"
					required
				></v-select>
				<!-- text select left image -->
				<v-file-input :clearable="false" v-model="image" chips label="Masukan Gambar" :error-messages="errors.image"></v-file-input>
				<!-- text error -->
				<small class="tw-text-red-500" v-if="errors.content">{{ errors.content[0] }}</small>
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="updateInventoryGallery({form, image})" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
	components: {
	
	},
	props: {
		id: null,
	},
	data: () => ({
		image: [],
	}),
	methods: {
		...mapActions("inventoryGallery", ["getInventoryGallery", "updateInventoryGallery"]),
		...mapActions("inventory", ["getInventories"]),
	},
	mounted() {
		this.getInventoryGallery(this.id);
		this.getInventories();
	},
	computed: {
		...mapState(["errors"]),
		...mapState("inventoryGallery", ["inventoryGallery"]),
		...mapState("inventory", ["inventories"]),
		...mapGetters("inventoryGallery", ["formattedInventoryGallery"]),
		form: {
			get() {
				return this.formattedInventoryGallery;
			},
		},
	},
};
</script>

<style>

</style> 