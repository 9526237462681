<template>
	<div>
		<!-- data table -->
		<v-data-table :headers="headers" :items="bookingDrives" mobile-breakpoint>
			<template v-slot:item="row">
				<tr>
					<td>{{ row.index + 1 }}</td>
					<td>{{ row.item.car }}</td>
					<td>{{ row.item.name }}</td>
					<td>{{ row.item.dealer }}</td>
					<td>{{ row.item.booking_code }}</td>
					<td>{{ row.item.date }}</td>
					<td>
						<v-btn
							fab
							x-small
							color="primary"
							:to="{name: 'admin-booking-drive-edit', params: { id: row.item.id }}"
							class="m-2"
						>
							<v-icon>mdi-eye-outline</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({
		headers: [
			{ text: "#", width: 10 },
			{ text: "Name", value: "Name" },
			{ text: "Car", value: "Car" },
			{ text: "Dealer", value: "Dealer" },
			{ text: "Booking Code", value: "Booking Code" },
			{ text: "Date", value: "Date" },
			{ text: "Action", width: 100 },
		],
	}),
	computed: {
		...mapState("bookingDrive", ["bookingDrives"]),
	},
	methods: {
		...mapActions(["removeErrors"]),
		...mapActions("bookingDrive", ["getBookingDrives"]),
	},
	mounted() {
		this.getBookingDrives();
		this.removeErrors();
	},
};
</script>

<style>
</style>