<template>
	<div>
		<!-- data table -->
		<v-data-table :headers="headers" :items="codeDealerLogs" mobile-breakpoint>
			<template v-slot:item="row">
				<tr>
					<td>{{ row.index + 1 }}</td>
					<td>{{ row.item.code.code }}</td>
					<td>{{ row.item.dealer.company }}</td>
					<td>{{ row.item.contact_dealer.phone }}</td>
          <td>{{ row.item.used }}</td>
					<td>
						<v-btn
							fab
							x-small
							color="primary"
							:to="{name: 'admin-code-dealer-log-edit', params: { id: row.item.id }}"
							class="m-2"
						>
							<v-icon>mdi-eye-outline</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({
		headers: [
			{ text: "#", width: 10 },
			{ text: "Code", value: "code" },
			{ text: "Dealer", value: "dealer" },
      { text: "Sales Number", value: "sales number" },
      { text: "Used", value: "use" },
			{ text: "Action", width: 100 },
		],
	}),
	computed: {
		...mapState("codeDealerLog", ["codeDealerLogs"]),
	},
	methods: {
		...mapActions(["removeErrors"]),
		...mapActions("codeDealerLog", ["getCodeDealerLogs"]),
	},
	mounted() {
		this.getCodeDealerLogs();
		this.removeErrors();
	},
};
</script>

<style>
</style>