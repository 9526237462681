<template>
  <div>
    <v-footer dark color="black" v-show="$vuetify.breakpoint.lgAndUp">
      <v-container class>
        <v-card class="flex" color="black">
          <v-card-title class="pa-0 justify-center">
            <v-row align="center" justify="center">
              <v-col md="5" class="pr-0">
                <v-btn
                  text
                  small
                  class="tw-text-sm"
                  :href="item.url"
                  v-for="(item, index) in items"
                  :key="index"
                >{{ item.title }}</v-btn>
                <v-btn text small class="tw-text-sm" :to="{name: 'faq'}">FAQ</v-btn>
              </v-col>
              <v-col md="3" class="pl-0">
                <v-btn
                  icon
                  class="mx-2"
                  href="https://www.facebook.com/MercedesBenzID"
                  target="_blank"
                >
                  <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn icon class="mx-2" href="https://twitter.com/mbindonesia" target="_blank">
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="mx-2"
                  href="https://www.instagram.com/mercedesbenzid/"
                  target="_blank"
                >
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="mx-2"
                  href="https://www.youtube.com/user/MercedesBenzID"
                  target="_blank"
                >
                  <v-icon>mdi-youtube</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-container>
    </v-footer>

    <v-footer dark color="black" class="mt-0" v-show="$vuetify.breakpoint.mdAndDown">
      <v-row align="center" justify="center">
        <v-col cols="6">
          <v-btn
            icon
            small
            class="mx-2"
            href="https://www.facebook.com/MercedesBenzID"
            target="_blank"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn icon x-small class="mx-2" href="https://twitter.com/mbindonesia" target="_blank">
            <v-icon>mdi-twitter</v-icon>
          </v-btn>
          <v-btn
            x-small
            icon
            class="mx-2"
            href="https://www.instagram.com/mercedesbenzid/"
            target="_blank"
          >
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
          <v-btn
            icon
            x-small
            class="mx-2"
            href="https://www.youtube.com/user/MercedesBenzID"
            target="_blank"
          >
            <v-icon>mdi-youtube</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Cookies",
        url:
          "https://www.mercedes-benz.co.id/passengercars/content-pool/tool-pages/legal/cookies.html",
      },
      {
        title: "Privacy Statement",
        url:
          "https://www.mercedes-benz.co.id/passengercars/content-pool/tool-pages/legal/privacy-statement.html",
      },
      {
        title: "Legal Notice",
        url:
          "https://www.mercedes-benz.co.id/passengercars/content-pool/tool-pages/legal/legal-notice.html",
      },
    ],
  }),
};
</script>

<style>
</style>