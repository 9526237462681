<template>
  <v-row align="center" justify="center" v-if="carThreeSixty.id">
    <v-col cols="12">
      <v-container fluid class="lg:tw-px-64">
        <h1 class="tw-text-2xl tw-tracking-wide text-center my-3">360° Viewer</h1>
        <iframe
          :src="carThreeSixty.path"
          v-show="$vuetify.breakpoint.lgAndUp"
          width="750px"
          height="650px"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <iframe
          :src="carThreeSixty.path"
          v-show="$vuetify.breakpoint.mdOnly"
          width="100%"
          height="100%"
          frameborder="1"
          allowfullscreen
        ></iframe>
        <iframe
          :src="carThreeSixty.path"
          v-show="$vuetify.breakpoint.smAndDown"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: null,
  },
  computed: {
    ...mapState("web", ["carThreeSixty"]),
  },
  methods: {
    ...mapActions("web", ["getCarThreeSixty"]),
  },
  mounted() {
    this.getCarThreeSixty(this.id);
  },
};
</script>

<style>
</style>