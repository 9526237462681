<template>
	<div v-if="contactLog">
		<v-form>
			<v-container>
				<v-row>
					<v-col>
						<v-list-item-title class="pa-2">Nama: {{ form.name }}</v-list-item-title>

						<v-list-item-title class="pa-2">Email: {{ form.email }}</v-list-item-title>

						<v-list-item-title class="pa-2">No Telp: {{ form.phone }}</v-list-item-title>

						<v-list-item-title class="pa-2">Dealer: {{ form.dealer.company }}</v-list-item-title>
            
            <v-list-item-title class="pa-2">Nama Sales: {{ form.sales_name }}</v-list-item-title>
            
            <v-list-item-title class="pa-2">Nomer Sales: {{ form.sales_phone }}</v-list-item-title>

						<v-list-item-title class="pa-2">Dibuat pada: {{ new Date(form.created_at) }}</v-list-item-title>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
	components: {},
	props: {
		id: null,
	},
	data: () => ({}),
	methods: {
		...mapActions("contactLog", ["getContactLog"]),
		...mapActions("dealer", ["getDealers"]),
	},
	mounted() {
		this.getContactLog(this.id);
		this.getDealers();
	},
	computed: {
		...mapState(["errors"]),
		...mapState("contactLog", ["contactLog"]),
		...mapState("dealer", ["dealers"]),
		...mapGetters("contactLog", ["contactLogFormatted"]),
		form: {
			get() {
				return this.contactLogFormatted;
			},
		},
	},
};
</script>

<style>
</style> 