<template>
  <v-dialog
    v-model="$store.state.dialogInventory"
    max-width="500"
    @click:outside="step = 1"
    scrollable
  >
    <v-card>
      <v-card-text class="pa-0">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Option
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Fill Data
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              Finish the Process
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-btn color="primary" @click="nextStep(true)" block depressed>
                i have code
              </v-btn>

              <v-btn
                class="mt-2"
                color="secondary"
                @click="nextStep(false)"
                block
                depressed
              >
                fill form
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div v-if="haveCode">
                <v-form @submit.prevent="checkCode">
                  <v-text-field
                    label="Input your code"
                    v-model="formGuest.code"
                    :error-messages="$store.state.errors.code"
                  ></v-text-field>
                  <div class="text-right">
                    <v-btn color="secondary" class="mx-2" @click="step = 1"
                      >back</v-btn
                    >
                    <v-btn color="primary" type="submit">submit</v-btn>
                  </div>
                </v-form>
              </div>
              <div v-else>
                <v-form @submit.prevent="submitData">
                  <v-text-field
                    label="Input Your Name"
                    v-model="form.name"
                    :error-messages="$store.state.errors.name"
                  ></v-text-field>
                  <v-text-field
                    label="Input Your Email"
                    type="email"
                    v-model="form.email"
                    :error-messages="$store.state.errors.email"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.phone"
                    label="Input Your Phone Number"
                    :error-messages="$store.state.errors.phone"
                  ></v-text-field>
                  <div>
                    Dari mana anda mengetahui tentang diadakannya pameran
                    virtual Mercedes-Benz ini?
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Social Media"
                      value="Social Media"
                      class="mt-5"
                      :error-messages="$store.state.errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Website"
                      value="Website"
                      class="mt-0"
                      :error-messages="$store.state.errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Sales Consultant/Referrals"
                      value="Whatsapp"
                      class="mt-0"
                      :error-messages="$store.state.errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Iklan"
                      value="Iklan"
                      class="mt-0"
                      :error-messages="$store.state.errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Radio"
                      value="Radio"
                      class="mt-0"
                      :error-messages="$store.state.errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Lainnya"
                      value="Lainnya"
                      class="mt-0"
                      :error-messages="$store.state.errors.source"
                    ></v-checkbox>
                  </div>
                  <div>
                    Untuk informasi lebih lanjut mengenai perlindungan data,
                    kunjungi:
                    <a href="http://mb4.me/dataprotectionID"
                      >http://mb4.me/dataprotectionID</a
                    >
                  </div>
                  <div class="text-right">
                    <v-btn color="secondary" class="mx-2" @click="step = 1"
                      >back</v-btn
                    >
                    <v-btn color="primary" type="submit">submit</v-btn>
                  </div>
                </v-form>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              Dengan mengklik tombol, saya menyetujui pengumpulan, penggunaan
              dan / atau pengungkapan data pribadi saya oleh PT Mercedes-Benz
              Distribution Indonesia dan dealer resmi sesuai dengan peraturan
              perlindungan data.
              <v-btn
                color="primary"
                class="mt-4"
                block
                depressed
                dark
                :href="'https://wa.me/' + phone"
                target="_blank"
                @click="closeDialog"
              >
                Lanjutkan
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    phone: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      haveCode: false,
      form: {
        name: "",
        email: "",
        phone: "",
        source: [],
        inventory_id: this.id,
      },
      formGuest: {
        code: "",
        inventory_id: this.id,
      },
    };
  },
  methods: {
    clearInput() {
      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.source = [];
      this.formGuest.code = "";
    },
    nextStep(haveCode) {
      this.step = 2;
      this.haveCode = haveCode;
    },
    async submitData() {
      await this.$store.dispatch("inventoryLog/storeInventoryLog", this.form);
      if (this.$store.state.errors.length === 0) {
        this.step = 3;
      }
    },
    async checkCode() {
      await this.$store.dispatch("code/checkCodeInventory", this.formGuest);
      if (this.$store.state.errors.length === 0) {
        this.step = 3;
      }
    },
    closeDialog() {
      this.step = 1;
      this.$store.dispatch("toogleDialogInventory");
      this.clearInput();
    },
  },
  watch: {
    id: function() {
      this.form.inventory_id = this.id;
      this.formGuest.inventory_id = this.id;
    },
  },
};
</script>

<style></style>
