export const SET_DRIVES = (state, payload) => {
  state.bookingDrives = payload
}

export const SET_DRIVE = (state, payload) => {
  state.bookingDrive = payload
}

export const UNSET_DRIVE = (state, payload) => {
  state.bookingDrives = state.bookingDrives.filter(item => {
    return item.id !== payload
  })
}