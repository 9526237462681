<template>
  <v-row align="center" justify="center" v-if="car && car.link !== 'https://www.google.com'">
    <v-col cols="12" sm="8" md="6" lg="6">  
        <v-btn depressed color="primary" block v-show="$vuetify.breakpoint.mdAndUp">
          <a :href="car.link" target="_blank" class="white--text tw-normal-case tw-tracking-widest">
            Kunjungi www.mercedes-benz.co.id untuk informasi lebih lanjut
          </a>
        </v-btn>
        <v-btn depressed color="primary" block v-show="$vuetify.breakpoint.smAndDown">
          <a :href="car.link" target="_blank" class="white--text tw-normal-case tw-leading-4 tw-tracking-widest">
            Kunjungi www.mercedes-benz.co.id
            <br>
            untuk informasi lebih lanjut
          </a>
        </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
		id: null,
  },
  computed: {
		...mapState("car", ["car"]),
	},
	methods: {
		...mapActions("car", ["getCar"]),
	},
	mounted() {
		this.getCar(this.id);
	},
}
</script>

<style>

</style>