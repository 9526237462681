export const SET_FAQS = (state, payload) => {
  state.faqs = payload;
};

export const SET_FAQ = (state, payload) => {
  state.faq = payload;
};

export const UNSET_FAQ = (state, payload) => {
  state.faqs = state.faqs.filter((item) => {
    return item.id !== payload;
  });
};
