export const SET_PRODUCT_INVOICES= (state, payload) => {
  state.productInvoices = payload
}

export const SET_PRODUCT_INVOICE = (state, payload) => {
  state.productInvoice = payload
}

export const UNSET_PRODUCT_INVOICE = (state, payload) => {
  state.productInvoices = state.productInvoices.filter(item => {
    return item.id !== payload
  })
}