export const SET_PAGE_IMAGES = (state, payload) => {
  state.pageImages = payload
}

export const SET_PAGE_IMAGE = (state, payload) => {
  state.pageImage = payload
}

export const UNSET_PAGE_IMAGE = (state, payload) => {
  state.pageImages = state.pageImages.filter(item => {
    return item.id !== payload
  })
}

export const SET_PAGE_IMAGE_FINANCE = (state, payload) => {
  state.pageImageFinance = payload
}