<template>
	<!-- check if banner state loaded -->
	<div v-if="city">
		<v-form>
			<v-container>
				<!-- text form city -->
				<v-text-field label="Name :" v-model="form.name" :error-messages="errors.name"></v-text-field>
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="updateCity(form)" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	props: {
		id: null,
	},
	methods: {
		...mapActions("city", ["getCity", "updateCity"]),
	},
	mounted() {
		this.getCity(this.id);
	},
	computed: {
		...mapState("city", ["city"]),
		...mapState(["errors"]),
		form: {
			get() {
				return this.city;
			},
		},
	},
};
</script>