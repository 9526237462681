<template>
	<div v-if="offer">
		<v-form>
			<v-container>
				<v-row>
					<v-col>
						<v-list-item-title class="pa-2">Nama: {{ form.name }}</v-list-item-title>

						<v-list-item-title class="pa-2">Email: {{ form.email }}</v-list-item-title>

						<v-list-item-title class="pa-2">No Telp: {{ form.phone }}</v-list-item-title>

						<v-list-item-title class="pa-2">Dealer: {{ form.car }}</v-list-item-title>
						
						<v-list-item-title class="pa-2">Dealer: {{ form.contact_options }}</v-list-item-title>

						<v-list-item-title class="pa-2">Dibuat pada: {{ new Date(form.created_at) }}</v-list-item-title>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	props: {
		id: null,
	},
	data: () => ({}),
	methods: {
		...mapActions("offer", ["getOffer"]),
	},
	mounted() {
		this.getOffer(this.id);
	},
	computed: {
		...mapState(["errors"]),
		...mapState("offer", ["offer"]),
		form: {
			get() {
				return this.offer;
			},
		},
	},
};
</script>

<style>
</style> 