<template>
  <v-app>
    <!-- Navigation -->
    <web-navigation></web-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main class="background">
      <!-- Provides the application the proper gutter -->
      <v-container class="fill-height align-start" fluid>
        <collection-content></collection-content>
        <collection-dialog></collection-dialog>
      </v-container>
    </v-main>

    <!-- Footer -->
    <web-footer></web-footer>
  </v-app>
</template>

<script>
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
import CollectionContent from "../../components/web/CollectionContent";
import CollectionDialog from "../../components/web/CollectionDialog";

export default {
  components: {
    WebNavigation,
    WebFooter,
    CollectionContent,
    CollectionDialog,
  },
};
</script>

<style scoped>
.background {
  background: url("https://ik.imagekit.io/vconexID/latar_MB_Collection_new_2.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>