<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 1.0,
    }"
    min-height="200"
    transition="fade-transition"
  >
    <v-row
      class="tw-px-10 lg:tw-px-20 tw-py-20"
      v-if="$vuetify.breakpoint.mdAndUp"
      align="center"
      justify="center"
    >
      <v-col cols="12" sm="12" md="6">
        <v-img
          contain
          lazy-src="https://ik.imagekit.io/vconexID/dummy_virtual.jpg"
          src="https://ik.imagekit.io/vconexID/dummy_virtual.jpg"
          height="auto"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <h1 class="text-h4 tw-text-center">
          Visit Our Virtual Showroom
        </h1>
        <div class="tw-text-center tw-mt-3 tw-mb-3 lg:tw-mb-0">
          <v-btn depressed color="primary" :to="{ name: 'virtual' }"
            >Click here</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-row
      class="tw-px-8 tw-py-10"
      v-if="$vuetify.breakpoint.smAndDown"
      align="center"
      justify="center"
    >
      <v-col cols="12" sm="12" md="6">
        <h1 class="text-h4 tw-mb-4 tw-text-center">
          Visit Our Virtual Showroom
        </h1>
        <div class="tw-text-center tw-mb-4">
          <v-btn depressed color="primary" :to="{ name: 'virtual' }"
            >Click here</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-img
          contain
          lazy-src="https://ik.imagekit.io/vconexID/dummy_virtual.jpg"
          src="https://ik.imagekit.io/vconexID/dummy_virtual.jpg"
          height="auto"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-lazy>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
  }),
};
</script>

<style></style>
