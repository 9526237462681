<template>
	<div>
		<v-form @submit.prevent="storeProduct(form)">
			<v-container>
				<!-- text select name -->
				<v-text-field
					v-model="form.name"
					:error-messages="errors.name"
					placeholder="Masukan name"
					required
					prepend-icon="mdi-format-title"
					class="mb-3"
				></v-text-field>
				<!-- text select desc -->
				<quill-editor ref="myQuillEditor" v-model="form.desc" />
				<!-- text select price -->
				<v-text-field
					v-model="form.price"
					:error-messages="errors.price"
					placeholder="Masukan price"
					type="number"
					min="0"
					required
					prepend-icon="mdi-cash-usd"
					class="mb-3"
				></v-text-field>
				<!-- text form gambar -->
				<v-file-input
					v-model="form.image"
					:error-messages="errors.image"
					:clearable="false"
					chips
					label="File input"
				></v-file-input>
				<!-- text form mobil -->
				<v-select
					v-model="form.product_category_id"
					:items="productCategories"
					:error-messages="errors.product_category_id"
					item-text="name"
					item-value="id"
					label="Pilih Product Category"
					prepend-icon="mdi-rectangle"
					required
				></v-select>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";

export default {
	components: {
		quillEditor,
	},
	data: () => ({
		form: {
			name: "",
			desc: "",
			image: [],
			price: "",
			product_category_id: "",
		},
	}),
	methods: {
		...mapActions("product", ["storeProduct"]),
		...mapActions("productCategory", ["getProductCategories"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("productCategory", ["productCategories"]),
	},
	mounted() {
		this.getProductCategories();
	},
};
</script>

<style>
	.ql-container {
		height: 300px !important;
	}
</style>