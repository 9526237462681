// web
import CarPage from "../pages/web/CarPage";
import HomePage from "../pages/web/HomePage";
import CarDetailPage from "../pages/web/CarDetailPage";
import BrochurePage from "../pages/web/BrochurePage";
import CertifiedPage from "../pages/web/CertifiedPage";
import InventoryPage from "../pages/web/InventoryPage";
import InventoryDetailPage from "../pages/web/InventoryDetailPage";
import CollectionPage from "../pages/web/CollectionPage";
import TestDrivePage from "../pages/web/TestDrivePage";
import FinancePage from "../pages/web/FinancePage";
import VirtualPage from "../pages/web/VirtualPage";
import DealerPage from "../pages/web/DealerPage";
import FaqPage from "../pages/web/FaqPage";

// auth
import LoginPage from "../pages/auth/LoginPage";

// admin
import AdminPage from "../pages/admin/AdminPage";
import DashboardPage from "../pages/admin/dashboard/DashboardPage";
import BannerIndexPage from "../pages/admin/banner/BannerIndexPage";
import BannerCreatePage from "../pages/admin/banner/BannerCreatePage";
import BannerEditPage from "../pages/admin/banner/BannerEditPage";
import CarIndexPage from "../pages/admin/car/CarIndexPage";
import CarCreatePage from "../pages/admin/car/CarCreatePage";
import CarEditPage from "../pages/admin/car/CarEditPage";
import CarVariantIndexPage from "../pages/admin/carVariant/CarVariantIndexPage";
import CarVariantCreatePage from "../pages/admin/carVariant/CarVariantCreatePage";
import CarVariantEditPage from "../pages/admin/carVariant/CarVariantEditPage";
import CarThreeSixtyIndexPage from "../pages/admin/carThreeSixty/CarThreeSixtyIndexPage";
import CarThreeSixtyCreatePage from "../pages/admin/carThreeSixty/CarThreeSixtyCreatePage";
import CarThreeSixtyEditPage from "../pages/admin/carThreeSixty/CarThreeSixtyEditPage";
import CarDetailIndexPage from "../pages/admin/carDetail/CarDetailIndexPage";
import CarDetailCreatePage from "../pages/admin/carDetail/CarDetailCreatePage";
import CarDetailEditPage from "../pages/admin/carDetail/CarDetailEditPage";
import InventoryIndexPage from "../pages/admin/inventory/InventoryIndexPage";
import InventoryCreatePage from "../pages/admin/inventory/InventoryCreatePage";
import InventoryEditPage from "../pages/admin/inventory/InventoryEditPage";
import FaqIndexPage from "../pages/admin/faq/FaqIndexPage";
import FaqCreatePage from "../pages/admin/faq/FaqCreatePage";
import FaqEditPage from "../pages/admin/faq/FaqEditPage";
import InventoryGalleryIndexPage from "../pages/admin/inventoryGallery/InventoryGalleryIndexPage";
import InventoryGalleryCreatePage from "../pages/admin/inventoryGallery/InventoryGalleryCreatePage";
import InventoryGalleryEditPage from "../pages/admin/inventoryGallery/InventoryGalleryEditPage";
import PageContentIndexPage from "../pages/admin/pageContent/PageContentIndexPage";
import PageContentCreatePage from "../pages/admin/pageContent/PageContentCreatePage";
import PageContentEditPage from "../pages/admin/pageContent/PageContentEditPage";
import PageImageIndexPage from "../pages/admin/pageImage/PageImageIndexPage";
import PageImageCreatePage from "../pages/admin/pageImage/PageImageCreatePage";
import PageImageEditPage from "../pages/admin/pageImage/PageImageEditPage";
import ProductCategoryIndexPage from "../pages/admin/productCategory/ProductCategoryIndexPage";
import ProductCategoryCreatePage from "../pages/admin/productCategory/ProductCategoryCreatePage";
import ProductCategoryEditPage from "../pages/admin/productCategory/ProductCategoryEditPage";
import ProductIndexPage from "../pages/admin/product/ProductIndexPage";
import ProductCreatePage from "../pages/admin/product/ProductCreatePage";
import ProductEditPage from "../pages/admin/product/ProductEditPage";
import BookingDriveIndexPage from "../pages/admin/bookingDrive/BookingDriveIndexPage";
import BookingDriveCreatePage from "../pages/admin/bookingDrive/BookingDriveCreatePage";
import BookingDriveEditPage from "../pages/admin/bookingDrive/BookingDriveEditPage";
import ProductInvoiceIndexPage from "../pages/admin/productInvoice/ProductInvoiceIndexPage";
import ProductInvoiceCreatePage from "../pages/admin/productInvoice/ProductInvoiceCreatePage";
import ProductInvoiceEditPage from "../pages/admin/productInvoice/ProductInvoiceEditPage";
import DealerIndexPage from "../pages/admin/dealer/DealerIndexPage";
import DealerCreatePage from "../pages/admin/dealer/DealerCreatePage";
import DealerEditPage from "../pages/admin/dealer/DealerEditPage";
import ContactDealerIndexPage from "../pages/admin/contactDealer/ContactDealerIndexPage";
import ContactDealerCreatePage from "../pages/admin/contactDealer/ContactDealerCreatePage";
import ContactDealerEditPage from "../pages/admin/contactDealer/ContactDealerEditPage";
import ContactLogIndexPage from "../pages/admin/contactLog/ContactLogIndexPage";
import ContactLogCreatePage from "../pages/admin/contactLog/ContactLogCreatePage";
import ContactLogEditPage from "../pages/admin/contactLog/ContactLogEditPage";
import OfferIndexPage from "../pages/admin/offer/OfferIndexPage";
import OfferCreatePage from "../pages/admin/offer/OfferCreatePage";
import OfferEditPage from "../pages/admin/offer/OfferEditPage";
import InteriorIndexPage from "../pages/admin/interior/InteriorIndexPage";
import InteriorCreatePage from "../pages/admin/interior/InteriorCreatePage";
import InteriorEditPage from "../pages/admin/interior/InteriorEditPage";
import CodeIndexPage from "../pages/admin/code/CodeIndexPage";
import CodeCreatePage from "../pages/admin/code/CodeCreatePage";
import CodeEditPage from "../pages/admin/code/CodeEditPage";
import CodeDealerLogIndexPage from "../pages/admin/codeDealerLog/CodeDealerLogIndexPage";
import CodeDealerLogEditPage from "../pages/admin/codeDealerLog/CodeDealerLogEditPage";
import CityIndexPage from "../pages/admin/city/CityIndexPage";
import CityCreatePage from "../pages/admin/city/CityCreatePage";
import CityEditPage from "../pages/admin/city/CityEditPage";
import InventoryLogIndexPage from "../pages/admin/inventoryLog/InventoryLogIndexPage";
import InventoryLogEditPage from "../pages/admin/inventoryLog/InventoryLogEditPage";
import CodeInventoryLogIndexPage from "../pages/admin/codeInventoryLog/CodeInventoryLogIndexPage";
import CodeInventoryLogEditPage from "../pages/admin/codeInventoryLog/CodeInventoryLogEditPage";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/product",
    name: "car",
    component: CarPage,
  },
  {
    path: "/digital-center",
    name: "brochure",
    component: BrochurePage,
  },
  {
    path: "/certified-used-car",
    name: "certified",
    component: CertifiedPage,
  },
  {
    path: "/mercedes-benz-certified/inventory",
    name: "inventory",
    component: InventoryPage,
  },
  {
    path: "/sales-consultant",
    name: "dealer",
    component: DealerPage,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqPage,
  },
  {
    path: "/product/:id",
    name: "product",
    props: true,
    component: CarDetailPage,
  },
  {
    path: "/inventory/:id",
    name: "inventory-detail",
    component: InventoryDetailPage,
    props: true,
  },
  {
    path: "/accessories-collection",
    name: "collection",
    component: CollectionPage,
  },
  {
    path: "/virtual",
    name: "virtual",
    component: VirtualPage,
  },
  {
    path: "/test-drive-booking",
    name: "test-drive",
    component: TestDrivePage,
  },
  {
    path: "/financing-offer",
    name: "finance",
    component: FinancePage,
  },
  {
    path: "/about",
    name: "About",
  },
  {
    path: "/login-mercedes",
    component: LoginPage,
    name: "login",
  },
  {
    path: "/admin",
    component: AdminPage,
    redirect: { name: "admin-dashboard" },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "admin-dashboard",
        component: DashboardPage,
      },
      {
        path: "banners",
        name: "admin-banner",
        component: BannerIndexPage,
      },
      {
        path: "banners/create",
        name: "admin-banner-create",
        component: BannerCreatePage,
      },
      {
        path: "banners/edit/:id",
        props: true,
        name: "admin-banner-edit",
        component: BannerEditPage,
      },
      {
        path: "dealers",
        name: "admin-dealer",
        component: DealerIndexPage,
      },
      {
        path: "dealers/create",
        name: "admin-dealer-create",
        component: DealerCreatePage,
      },
      {
        path: "dealers/edit/:id",
        props: true,
        name: "admin-dealer-edit",
        component: DealerEditPage,
      },
      {
        path: "cars",
        name: "admin-car",
        component: CarIndexPage,
      },
      {
        path: "cars/create",
        name: "admin-car-create",
        component: CarCreatePage,
      },
      {
        path: "cars/edit/:id",
        props: true,
        name: "admin-car-edit",
        component: CarEditPage,
      },
      {
        path: "car-variants",
        name: "admin-car-variant",
        component: CarVariantIndexPage,
      },
      {
        path: "car-variants/create",
        name: "admin-car-variant-create",
        component: CarVariantCreatePage,
      },
      {
        path: "car-variants/edit/:id",
        props: true,
        name: "admin-car-variant-edit",
        component: CarVariantEditPage,
      },
      {
        path: "car-three-sixties",
        name: "admin-car-three-sixty",
        component: CarThreeSixtyIndexPage,
      },
      {
        path: "car-three-sixties/create",
        name: "admin-car-three-sixty-create",
        component: CarThreeSixtyCreatePage,
      },
      {
        path: "car-three-sixties/edit/:id",
        props: true,
        name: "admin-car-three-sixty-edit",
        component: CarThreeSixtyEditPage,
      },
      {
        path: "car-details",
        name: "admin-car-detail",
        component: CarDetailIndexPage,
      },
      {
        path: "car-details/create",
        name: "admin-car-detail-create",
        component: CarDetailCreatePage,
      },
      {
        path: "car-details/edit/:id",
        props: true,
        name: "admin-car-detail-edit",
        component: CarDetailEditPage,
      },
      {
        path: "inventories",
        name: "admin-inventory",
        component: InventoryIndexPage,
      },
      {
        path: "inventories/create",
        name: "admin-inventory-create",
        component: InventoryCreatePage,
      },
      {
        path: "inventories/edit/:id",
        props: true,
        name: "admin-inventory-edit",
        component: InventoryEditPage,
      },
      {
        path: "faqs",
        name: "admin-faq",
        component: FaqIndexPage,
      },
      {
        path: "faqs/create",
        name: "admin-faq-create",
        component: FaqCreatePage,
      },
      {
        path: "faqs/edit/:id",
        props: true,
        name: "admin-faq-edit",
        component: FaqEditPage,
      },
      {
        path: "inventory-galleries",
        name: "admin-inventory-gallery",
        component: InventoryGalleryIndexPage,
      },
      {
        path: "inventory-galleries/create",
        name: "admin-inventory-gallery-create",
        component: InventoryGalleryCreatePage,
      },
      {
        path: "inventory-galleries/edit/:id",
        props: true,
        name: "admin-inventory-gallery-edit",
        component: InventoryGalleryEditPage,
      },
      {
        path: "page-contents",
        name: "admin-page-content",
        component: PageContentIndexPage,
      },
      {
        path: "page-contents/create",
        name: "admin-page-content-create",
        component: PageContentCreatePage,
      },
      {
        path: "page-contents/edit/:id",
        props: true,
        name: "admin-page-content-edit",
        component: PageContentEditPage,
      },
      {
        path: "page-images",
        name: "admin-page-image",
        component: PageImageIndexPage,
      },
      {
        path: "page-images/create",
        name: "admin-page-image-create",
        component: PageImageCreatePage,
      },
      {
        path: "page-images/edit/:id",
        props: true,
        name: "admin-page-image-edit",
        component: PageImageEditPage,
      },
      {
        path: "product-categories",
        name: "admin-product-category",
        component: ProductCategoryIndexPage,
      },
      {
        path: "product-categories/create",
        name: "admin-product-category-create",
        component: ProductCategoryCreatePage,
      },
      {
        path: "product-categories/edit/:id",
        props: true,
        name: "admin-product-category-edit",
        component: ProductCategoryEditPage,
      },
      {
        path: "products",
        name: "admin-product",
        component: ProductIndexPage,
      },
      {
        path: "products/create",
        name: "admin-product-create",
        component: ProductCreatePage,
      },
      {
        path: "products/edit/:id",
        props: true,
        name: "admin-product-edit",
        component: ProductEditPage,
      },
      {
        path: "booking-drives",
        name: "admin-booking-drive",
        component: BookingDriveIndexPage,
      },
      {
        path: "booking-drives/create",
        name: "admin-booking-drive-create",
        component: BookingDriveCreatePage,
      },
      {
        path: "booking-drives/edit/:id",
        props: true,
        name: "admin-booking-drive-edit",
        component: BookingDriveEditPage,
      },
      {
        path: "product-invoices",
        name: "admin-product-invoice",
        component: ProductInvoiceIndexPage,
      },
      {
        path: "product-invoices/create",
        name: "admin-product-invoice-create",
        component: ProductInvoiceCreatePage,
      },
      {
        path: "product-invoices/edit/:id",
        props: true,
        name: "admin-product-invoice-edit",
        component: ProductInvoiceEditPage,
      },
      {
        path: "contact-dealers",
        name: "admin-contact-dealer",
        component: ContactDealerIndexPage,
      },
      {
        path: "contact-dealers/create",
        name: "admin-contact-dealer-create",
        component: ContactDealerCreatePage,
      },
      {
        path: "contact-dealers/edit/:id",
        props: true,
        name: "admin-contact-dealer-edit",
        component: ContactDealerEditPage,
      },
      {
        path: "contact-logs",
        name: "admin-contact-log",
        component: ContactLogIndexPage,
      },
      {
        path: "contact-logs/create",
        name: "admin-contact-log-create",
        component: ContactLogCreatePage,
      },
      {
        path: "contact-logs/edit/:id",
        props: true,
        name: "admin-contact-log-edit",
        component: ContactLogEditPage,
      },
      {
        path: "offers",
        name: "admin-offer",
        component: OfferIndexPage,
      },
      {
        path: "offers/create",
        name: "admin-offer-create",
        component: OfferCreatePage,
      },
      {
        path: "offers/edit/:id",
        props: true,
        name: "admin-offer-edit",
        component: OfferEditPage,
      },
      {
        path: "interiors",
        name: "admin-interior",
        component: InteriorIndexPage,
      },
      {
        path: "interiors/create",
        name: "admin-interior-create",
        component: InteriorCreatePage,
      },
      {
        path: "interiors/edit/:id",
        props: true,
        name: "admin-interior-edit",
        component: InteriorEditPage,
      },
      {
        path: "codes",
        name: "admin-code",
        component: CodeIndexPage,
      },
      {
        path: "codes/create",
        name: "admin-code-create",
        component: CodeCreatePage,
      },
      {
        path: "codes/edit/:id",
        props: true,
        name: "admin-code-edit",
        component: CodeEditPage,
      },
      {
        path: "code-dealer-logs",
        name: "admin-code-dealer-log",
        component: CodeDealerLogIndexPage,
      },
      {
        path: "code-dealer-logs/edit/:id",
        props: true,
        name: "admin-code-dealer-log-edit",
        component: CodeDealerLogEditPage,
      },
      {
        path: "cities",
        name: "admin-city",
        component: CityIndexPage,
      },
      {
        path: "cities/create",
        name: "admin-city-create",
        component: CityCreatePage,
      },
      {
        path: "cities/edit/:id",
        props: true,
        name: "admin-city-edit",
        component: CityEditPage,
      },
      {
        path: "inventory-logs",
        name: "admin-inventory-log",
        component: InventoryLogIndexPage,
      },
      {
        path: "inventory-logs/edit/:id",
        props: true,
        name: "admin-inventory-log-edit",
        component: InventoryLogEditPage,
      },
      {
        path: "code-inventory-logs",
        name: "admin-code-inventory-log",
        component: CodeInventoryLogIndexPage,
      },
      {
        path: "code-inventory-logs/edit/:id",
        props: true,
        name: "admin-code-inventory-log-edit",
        component: CodeInventoryLogEditPage,
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "home" },
  },
];

export default routes;
