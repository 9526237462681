export const SET_CITIES = (state, payload) => {
  state.cities = payload;
};

export const SET_CITY = (state, payload) => {
  state.city = payload;
};

export const UNSET_CITY = (state, payload) => {
  state.cities = state.cities.filter((item) => {
    return item.id !== payload;
  });
};
