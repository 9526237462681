import Faq from "../../../api/Faq";
import router from "../../../router";

export const getFaqs = ({ commit }) => {
  Faq.all()
    .then((response) => {
      commit("SET_FAQS", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getFaq = ({ commit }, payload) => {
  Faq.show(payload)
    .then((response) => {
      commit("SET_FAQ", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const storeFaq = ({ dispatch }, payload) => {
  Faq.store(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-faq" });
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const updateFaq = ({ dispatch }, payload) => {
  Faq.update(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-faq" });
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const deleteFaq = ({ commit }, payload) => {
  Faq.delete(payload)
    .then(() => {
      commit("UNSET_FAQ", payload);
    })
    .catch((error) => {
      console.log(error);
    });
};
