export const SET_CAR_THREE_SIXTIES = (state, payload) => {
  state.carThreeSixties = payload
}

export const SET_CAR_THREE_SIXTY = (state, payload) => {
  state.carThreeSixty = payload
}

export const UNSET_CAR_THREE_SIXTY = (state, payload) => {
  state.carThreeSixties = state.carThreeSixties.filter(item => {
    return item.id !== payload
  })
}