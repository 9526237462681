<template>
	<div>
		<v-form @submit.prevent="storeCity(form)">
			<v-container>
				<!-- text form city -->
				<v-text-field label="Name :" v-model="form.name" :error-messages="errors.name"></v-text-field>
				<!-- button submit -->
				<div class="text-left">
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
            name: ''
		},
	}),
	methods: {
		...mapActions("city", ["storeCity"]),
	},
	computed: {
		...mapState(["errors"]),
	}
};
</script>