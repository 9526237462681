<template>
  <div v-if="form">
    <v-form @submit.prevent="updateFaq(form)">
      <v-container>
        <!-- question input -->
        <p>Question :</p>
        <quill-editor ref="myQuillEditor" v-model="form.question" :error-messages="errors.question" />
        <!-- answer input -->
        <p class="mt-4">Answer :</p>
        <quill-editor ref="myQuillEditor" v-model="form.answer" :error-messages="errors.answer" />
        <div class="text-left">
          <!-- button submit -->
          <v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  props: {
    id: null,
  },
  methods: {
    ...mapActions("faq", ["getFaq", "updateFaq"]),
  },
  mounted() {
    this.getFaq(this.id);
  },
  computed: {
    ...mapState(["errors"]),
    ...mapState("faq", ["faq"]),
    form: {
      get() {
        return this.faq;
      },
    },
  },
};
</script>

<style>

</style> 