<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 1.0,
    }"
    min-height="200"
    transition="fade-transition"
  >
    <v-row justify="center" class="tw-px-8 lg:tw-px-64 tw-py-20 tw-bg-black">
      <v-col cols="12" sm="12" md="12">
        <h1 class="text-h2 tw-capitalize tw-text-white tw-text-center" v-if="$vuetify.breakpoint.mdAndUp">
          Media Review
        </h1>
         <h1 class="text-h4 tw-capitalize tw-text-white tw-text-center" v-if="$vuetify.breakpoint.smAndDown">
          Media Review
        </h1>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <iframe
          width="100%"
          height="400"
          :src="urlVideoFrame"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-col>
      <v-col cols="6" sm="6" md="3" v-for="(item, i) in itemsFrame" :key="i">
        <div class="tw-cursor-pointer" v-on:click="changeUrl(item.urlFrame)">
          <v-img :src="item.image" height="100"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-lazy>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    itemsFrame: [
      {
        image:
          "https://ik.imagekit.io/vconexID/1.png",
        urlFrame: "https://www.youtube.com/embed/3aSCbqOG-wQ?autoplay=1",
      },
      {
        image:
          "https://ik.imagekit.io/vconexID/2.png",
        urlFrame: "https://www.youtube.com/embed/Jax-vRzT4NU?autoplay=1",
      },
      {
        image:
          "https://ik.imagekit.io/vconexID/3.png",
        urlFrame: "https://www.youtube.com/embed/5Z6WG8VuWXc?autoplay=1",
      },
      {
        image:
          "https://ik.imagekit.io/vconexID/4.png",
        urlFrame: "https://www.youtube.com/embed/TDCpuShPgvQ?autoplay=1",
      },
    ],
    isActive: false,
  }),
  computed: {
    ...mapState("web", ["urlVideoFrame"]),
  },
  methods: {
    ...mapActions("web", ["changeUrlFrame"]),
    changeUrl(val) {
      this.changeUrlFrame(val);
    },
  },
};
</script>

<style></style>
