<template>
	<div v-if="inventory">
		<v-form>
			<v-container>
				<!-- text form dealer -->
				<v-select
					v-model="form.dealer_id"
					:error-messages="errors.dealer_id"
					:items="dealers"
					item-text="company"
					item-value="id"
					label="Pilih Dealer"
					prepend-icon="mdi-car"
					required
				></v-select>
				<!-- text form model -->
				<v-text-field
					v-model="form.model"
					placeholder="Masukan Model"
					:error-messages="errors.model"
					required
					class="mb-3"
					prepend-icon="mdi-format-title"
				></v-text-field>
				<!-- text form price -->
				<v-text-field
					v-model="form.price"
					placeholder="Masukan harga"
					type="number"
					min="0"
					:error-messages="errors.price"
					required
					class="mb-3"
					prepend-icon="mdi-cash-usd"
				></v-text-field>
				<!-- text form mileage -->
				<v-text-field
					v-model="form.mileage"
					:error-messages="errors.mileage"
					placeholder="Masukan mileage"
					required
					class="mb-3"
					prepend-icon="mdi-gas-station"
				></v-text-field>
				<!-- text form isp_validation -->
				<v-text-field
					v-model="form.isp_validation"
					:error-messages="errors.isp_validation"
					placeholder="Masukan isp_validation"
					required
					prepend-icon="mdi-numeric"
					class="mb-3"
				></v-text-field>
				<!-- text form exterior -->
				<v-text-field
					v-model="form.exterior"
					:error-messages="errors.exterior"
					placeholder="Masukan exterior"
					required
					prepend-icon="mdi-moon-waxing-crescent"
					class="mb-3"
				></v-text-field>
				<!-- text form interior -->
				<v-text-field
					v-model="form.interior"
					:error-messages="errors.interior"
					placeholder="Masukan interior"
					required
					prepend-icon="mdi-moon-waning-crescent"
					class="mb-3"
				></v-text-field>
				<!-- text form phone -->
				<v-text-field
					v-model="form.phone"
					:error-messages="errors.phone"
					placeholder="Masukan phone"
					required
					type="numeric"
					prepend-icon="mdi-phone"
					class="mb-3"
				></v-text-field>
				<!-- text form phone name -->
				<v-text-field
					v-model="form.phone_name"
					:error-messages="errors.phone_name"
					placeholder="Masukan Nama Whatsapp"
					required
					prepend-icon="mdi-phone"
					class="mb-3"
				></v-text-field>
					<!-- text form phone name -->
				<v-text-field
					v-model="form.year"
					:error-messages="errors.year"
					placeholder="Masukan Tahun"
					required
					prepend-icon="mdi-home"
					class="mb-3"
				></v-text-field>
				<!-- text error -->
				<small class="tw-text-red-500" v-if="errors.content">{{ errors.content[0] }}</small>
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="updateInventory(form)" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
	props: {
		id: null,
	},
	data: () => ({}),
	methods: {
		...mapActions("inventory", ["getInventory", "updateInventory"]),
		...mapActions("dealer", ["getDealers"]),
	},
	mounted() {
		this.getInventory(this.id);
		this.getDealers();
	},
	computed: {
		...mapState(["errors"]),
		...mapState("inventory", ["inventory"]),
		...mapState("dealer", ["dealers"]),
		...mapGetters("inventory", ["inventoryFormatted"]),
		form: {
			get() {
				return this.inventoryFormatted;
			},
		},
	},
};
</script>

<style>
	.ck-editor__editable {
		min-height: 300px;
	}
</style> 