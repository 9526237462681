import Api from "./Api";

const END_POINT = "/booking-drives";

export default {
  all() {
    return Api.get(END_POINT);
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  store(data) {
    // return console.log(data)
    return Api.post(END_POINT, {
      name: data.name,
      car: data.car,
      email: data.email,
      no_telp: data.no_telp,
      address: data.address,
      date: data.date,
      dealer: data.dealer,
      contact_options: data.contact_options.toString(),
      source: data.source.toString(),
    });
  },

  update(data) {
    return Api.put(`${END_POINT}/${data.id}`, {
      name: data.name,
      car: data.car,
      email: data.email,
      no_telp: data.no_telp,
      address: data.address,
      date: data.date,
      contact_options: data.contact_options,
    });
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
