<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 1.0,
    }"
    min-height="200"
    transition="fade-transition"
  >
    <v-row class="tw-px-8 lg:tw-px-20 tw-py-20 tw-bg-black">
      <v-col cols="12" sm="12" md="6">
        <h1 class="text-h2 tw-text-white tw-mb-16" v-if="$vuetify.breakpoint.mdAndUp">
          Mercedes-Benz Star Expo
        </h1>
        <h1 class="text-h3 tw-text-white" v-if="$vuetify.breakpoint.smAndDown">
          Mercedes-Benz
        </h1>
        <h1 class="text-h3 tw-text-white tw-mb-16" v-if="$vuetify.breakpoint.smAndDown">
          Star Expo
        </h1>
        <h6 class="lg:tw-text-xl tw-text-lg tw-text-white">
          3-6 Desember 2020. Ashta district 8 (SCBD), 11.00-21.00 WIB.
        </h6>
        <p class="lg:tw-text-lg tw-text-base tw-text-white tw-mt-10">
          Diskusikan kebutuhan berkendara Anda dengan sales consultant kami untuk penawaran khusus yang hanya berlaku selama event berlangsung.
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-img
          lazy-src="https://ik.imagekit.io/vconexID/dummy_line_art_gedung.png"
          src="https://ik.imagekit.io/vconexID/dummy_line_art_gedung.png"
          height="auto"
          class="mt-6"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-lazy>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
  }),
};
</script>

<style></style>
