import axios from "axios";
import app from "../main";
import store from "../store";

const Api = axios.create({
  baseURL: "https://mercedes-exhibition-api.herokuapp.com/api/",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

Api.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + store.state.auth.token || null;
  app.$Progress.start();
  return config;
});

Api.interceptors.response.use((response) => {
  if (response.status === 401) {
    store.dispatch("auth/deleteToken", null, { root: true });
  }
  app.$Progress.finish();
  return response;
});

export default Api;
