<template>
	<div v-if="Object.keys(inventoryLog).length > 0">
		<v-form>
			<v-container>
				<v-row>
					<v-col>
						<v-list-item-title class="pa-2">Nama: {{ form.name }}</v-list-item-title>

						<v-list-item-title class="pa-2">Email: {{ form.email }}</v-list-item-title>

						<v-list-item-title class="pa-2">No Telp: {{ form.phone }}</v-list-item-title>
            
						<v-list-item-title class="pa-2">Dealer: {{ form.inventory.model }}</v-list-item-title>

						<v-list-item-title class="pa-2">Dibuat pada: {{ new Date(form.created_at) }}</v-list-item-title>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	props: {
		id: null,
	},
	data: () => ({}),
	methods: {
		...mapActions("inventoryLog", ["getInventoryLog"]),
	},
	mounted() {
		this.getInventoryLog(this.id);
	},
	computed: {
		...mapState(["errors"]),
		...mapState("inventoryLog", ["inventoryLog"]),
		form: {
			get() {
				return this.inventoryLog;
			},
		},
	},
};
</script>

<style>
</style> 