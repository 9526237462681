<template>
	<div v-if="interior">
		<v-form @submit.prevent="updateInterior(form)">
			<v-container>
				<!-- text select mobil -->
				<v-select
					v-model="form.car_id"
					:error-messages="errors.car_id"
					:items="cars"
					item-text="name"
					item-value="id"
					label="Pilih Mobil"
					prepend-icon="mdi-car"
					required
				></v-select>
				<!-- text form link -->
				<v-text-field
					v-model="form.link"
					placeholder="link 360 image"
					required
					prepend-icon="mdi-rotate-3d"
					class="mb-3"
					:error-messages="errors.link"
				></v-text-field>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
	components: {},
	props: {
		id: null,
	},
	methods: {
		...mapActions("interior", ["getInterior", "updateInterior"]),
		...mapActions("car", ["getCars"]),
	},
	mounted() {
		this.getInterior(this.id);
		this.getCars();
	},
	computed: {
		...mapState(["errors"]),
		...mapState("interior", ["interior"]),
		...mapGetters("interior", ["interiorFormatted"]),
		...mapState("car", ["cars"]),
		form: {
			get() {
				return this.interiorFormatted;
			},
		},
	},
};
</script>

<style>
</style> 