<template>
	<div>
		<v-form @submit.prevent="storePageContent(form)">
			<v-container>
				<!-- text select title -->
				<v-text-field
					v-model="form.title"
					placeholder="Masukan title"
					required
					:error-messages="errors.title"
					prepend-icon="mdi-format-title"
					class="mb-3"
				></v-text-field>
				<!-- text select desc -->
				<quill-editor ref="myQuillEditor" v-model="form.desc" />
				<!-- text select type -->
				<v-select
					v-model="form.type"
					:error-messages="errors.type"
					:items="items"
					label="Pilih Type"
					prepend-icon="mdi-view-list"
				></v-select>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";

export default {
	components: {
		quillEditor,
	},
	data: () => ({
		form: {
			title: "",
			desc: "",
			type: "",
		},
		items: ["Mercedes benz certified", "Finance"],
	}),
	methods: {
		...mapActions("pageContent", ["storePageContent"]),
	},
	computed: {
		...mapState(["errors"]),
	},
};
</script>

<style>
	.ql-container {
		height: 300px !important;
	}
</style>