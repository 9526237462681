<template>
  <div>
    <!-- data table -->
    <v-data-table :headers="headers" :items="codes" mobile-breakpoint>
      <template v-slot:item="row">
        <tr>
          <td>{{ row.index + 1 }}</td>
          <td>{{ row.item.code }}</td>
          <td v-if="row.item.status === 0">
            <v-chip class="ma-2" color="red accent-2" dark>
              Belum Terpakai
            </v-chip>
          </td>
          <td v-else>
            <v-chip class="ma-2" color="green accent-3" dark>
              Terpakai
            </v-chip>
          </td>
          <td>
            <v-btn
              fab
              x-small
              color="primary"
              :to="{ name: 'admin-code-edit', params: { id: row.item.id } }"
              class="m-2"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              color="error"
              class="m-2"
              @click="confirm(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    headers: [
      { text: "#", width: 10 },
      { text: "Code", value: "code" },
      { text: "Status", value: "status" },
      { text: "Action", width: 100 },
    ],
  }),
  computed: {
    ...mapState("code", ["codes"]),
  },
  methods: {
    ...mapActions(["removeErrors"]),
    ...mapActions("code", ["getCodes", "deleteCode"]),
    confirm(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.value) {
          this.deleteCode(data.id);
          this.$swal("Deleted!", "Item Deleted", "success");
        }
      });
    },
  },
  mounted() {
    this.getCodes();
    this.removeErrors();
  },
};
</script>

<style></style>
