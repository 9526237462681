<template>
	<div>
    <div v-for="(detail, index) in carDetail" :key="index">
      <v-row class="py-12" justify="space-between" v-if="index % 2 == 0 && $vuetify.breakpoint.mdAndUp">
        <v-col cols="12" lg="8">
          <v-img v-if="detail.image" :src="detail.image"></v-img>
          <!-- iframe for mobile -->
          <iframe v-else-if="detail.video && $vuetify.breakpoint.mdAndDown" width="100%" height="250px" :src="detail.video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <!-- iframe for desktop -->
          <iframe v-else-if="detail.video && $vuetify.breakpoint.mdAndUp" width="100%" height="500px" :src="detail.video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         
        </v-col>
        <v-col cols="12" lg="4">
          <h1 class="tw-text-lg tw-tracking-wide">
            {{ detail.title }}
          </h1>
          <v-divider class="tw-bg-black tw-w-10 tw-mt-1"></v-divider>
          <div v-html="detail.desc" class="mt-5"></div>
        </v-col>
      </v-row>
      <v-row class="py-12" justify="space-between" v-else v-show="$vuetify.breakpoint.mdAndUp">
        <v-col cols="12" lg="4">
          <h1 class="tw-text-lg tw-tracking-wide">
            {{ detail.title }}
          </h1>
          <v-divider class="tw-bg-black tw-w-10 tw-mt-1"></v-divider>
          <div v-html="detail.desc" class="mt-5"></div>
        </v-col>
        <v-col cols="12" lg="8">
          
          <v-img v-if="detail.image" :src="detail.image"></v-img>
          <!-- iframe for mobile -->
          <iframe v-else-if="detail.video && $vuetify.breakpoint.mdAndDown"  width="100%" height="250px" :src="detail.video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <!-- iframe for desktop -->
          <iframe v-else-if="detail.video && $vuetify.breakpoint.mdAndUp"  width="100%" height="500px" :src="detail.video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </v-col>
      </v-row>
      <v-row justify="space-between" v-show="$vuetify.breakpoint.smAndDown">
        <v-col cols="12" lg="4">
          <h1 class="tw-text-lg tw-tracking-wide">
            {{ detail.title }}
          </h1>
          <v-divider class="tw-bg-black tw-w-10 tw-mt-1"></v-divider>
          <div v-html="detail.desc" class="mt-5"></div>
        </v-col>
        <v-col cols="12" lg="8">
          
          <v-img v-if="detail.image" :src="detail.image"></v-img>
          <!-- iframe for mobile -->
          <iframe v-else-if="detail.video && $vuetify.breakpoint.mdAndDown"  width="100%" height="250px" :src="detail.video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <!-- iframe for desktop -->
          <iframe v-else-if="detail.video && $vuetify.breakpoint.mdAndUp"  width="100%" height="500px" :src="detail.video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </v-col>
      </v-row>
       
    </div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	props: {
		id: null,
	},
	components: {
	},
	computed: {
		...mapState("web", ["carDetail"]),
	},
	methods: {
		...mapActions("web", ["getCarDetail"]),
	},
	mounted() {
		this.getCarDetail(this.id);
	},
};
</script>

<style>
  .ql-size-huge {
    font-size: 30px !important;
    font-family: "corpoa" !important;
  }

  ul {
    list-style-type: disc !important;
  }
</style>