<template>
	<div>
		<v-form>
			<v-container>
				<!-- text select inventory -->
				<v-select
					v-model="form.inventory_id"
					:items="inventories"
					:error-messages="errors.inventory_id"
					item-text="model"
					item-value="id"
					label="Pilih Inventory"
					prepend-icon="mdi-car"
					required
				></v-select>
				<!-- text select left image -->
				<v-file-input :clearable="false" v-model="form.image" chips label="Masukan Gambar" :error-messages="errors.image"></v-file-input>
				<!-- text error -->
				<small class="tw-text-red-500" v-if="errors.content">{{ errors.content[0] }}</small>
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="storeInventoryGallery(form)" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {
	},
	data: () => ({
		form: {
			inventory_id: "",
			image: [],
		},
	}),
	methods: {
		...mapActions("inventoryGallery", ["storeInventoryGallery"]),
		...mapActions("inventory", ["getInventories"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("inventory", ["inventories"]),
	},
	mounted() {
		this.getInventories();
	},
};
</script>

<style>
	
</style>