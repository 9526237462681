<template>
  <div>
    <!-- App wrapper -->
    <v-app>
      <!-- Navigation -->
      <admin-navigation></admin-navigation>
      <!-- Content Wrapper -->
      <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <!-- Content -->
          <v-card max-width="1000" class="px-2">
            <v-row align="center" justify="space-between">
              <!-- Title -->
              <v-card-title>Tambah Item</v-card-title>
              <!-- Button back -->
              <v-card-title>
                <v-btn fab small :to="{name: 'admin-faq'}">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-card-title>
            </v-row>
            <!-- Form -->
            <faq-create-form></faq-create-form>
          </v-card>
        </v-container>
      </v-main>

      <v-footer app>
        <v-icon>mdi-copyright</v-icon>
        {{ new Date().getFullYear() }} V-CONNEXT
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import AdminNavigation from "../../../components/admin/AdminNavigation";
import FaqCreateForm from "../../../components/admin/FaqCreateForm";

export default {
  components: {
    AdminNavigation,
    FaqCreateForm,
  },
};
</script>

<style>
</style>