import City from "../../../api/City";
import router from "../../../router";

export const getCities = ({ commit }, payload) => {
  City.all(payload)
    .then((response) => {
      commit("SET_CITIES", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCity = ({ commit }, payload) => {
  City.show(payload)
    .then((response) => {
      commit("SET_CITY", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const storeCity = ({ dispatch }, payload) => {
  City.store(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-city" });
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const updateCity = ({ dispatch }, payload) => {
  City.update(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-city" });
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const deleteCity = ({ commit }, payload) => {
  City.delete(payload)
    .then(() => {
      commit("UNSET_CITY", payload);
    })
    .catch((error) => {
      console.log(error);
    });
};
