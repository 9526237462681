import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

// import modules
import car from "./modules/car";
import auth from "./modules/auth";
import banner from "./modules/banner";
import dealer from "./modules/dealer";
import contactDealer from "./modules/contactDealer";
import contactLog from "./modules/contactLog";
import bookingDrive from "./modules/bookingDrive";
import carVariant from "./modules/carVariant";
import carThreeSixty from "./modules/carThreeSixty";
import carDetail from "./modules/carDetail";
import inventory from "./modules/inventory";
import inventoryGallery from "./modules/inventoryGallery";
import pageContent from "./modules/pageContent";
import pageImage from "./modules/pageImage";
import product from "./modules/product";
import productCategory from "./modules/productCategory";
import productInvoice from "./modules/productInvoice";
import offer from "./modules/offer";
import faq from "./modules/faq";
import interior from "./modules/interior";
import guest from "./modules/guest";
import code from "./modules/code";
import codeDealerLog from "./modules/codeDealerLog";
import codeInventoryLog from "./modules/codeInventoryLog";
import city from "./modules/city";
import inventoryLog from "./modules/inventoryLog";

//web
import web from "./modules/web";

// import state
import state from "./state";
import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

Vue.use(Vuex);

// instance secure ls
var ls = new SecureLS({ isCompression: false });

// vuex pesistedstate config
const dataState = createPersistedState({
  paths: ["auth"],
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    banner,
    dealer,
    bookingDrive,
    car,
    carVariant,
    carThreeSixty,
    carDetail,
    inventory,
    inventoryGallery,
    pageContent,
    pageImage,
    productCategory,
    product,
    productInvoice,
    web,
    contactDealer,
    contactLog,
    offer,
    faq,
    interior,
    guest,
    code,
    codeDealerLog,
    city,
    inventoryLog,
    codeInventoryLog,
  },
  plugins: [dataState],
});
