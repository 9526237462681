<template>
  <v-container>
    <v-expansion-panels class="mt-5" v-if="faqs">
      <v-expansion-panel v-for="(item,i) in faqs" :key="i">
        <v-expansion-panel-header class="text-h6">
          <div v-html="item.question"></div>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-html="item.answer" class="px-6 pb-3"></v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("faq", ["getFaqs"]),
  },
  computed: {
    ...mapState("faq", ["faqs"]),
  },
  mounted() {
    this.getFaqs();
  },
};
</script>

<style>
</style>