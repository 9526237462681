<template>
	<div>
		<!-- data table -->
		<v-data-table :headers="headers" :items="contactDealers" mobile-breakpoint>
			<template v-slot:item="row">
				<tr>
					<td>{{ row.index + 1 }}</td>
					<td>{{ row.item.dealer.company }}</td>
					<td>{{ row.item.name }}</td>
          <td>{{ row.item.phone }}</td>
				<td>
						<v-btn x-small depressed color="green accent-4" dark v-if="row.item.status == 1">
							active
						</v-btn>
						<v-btn x-small depressed v-else color="grey darken-3" dark>
							no active
						</v-btn>
					</td>
					<td>{{ row.item.tanggal }}</td>
					<td>{{ row.item.shift_start }}</td>
					<td>{{ row.item.shift_end }}</td>
					<td>
						<v-btn
							fab
							x-small
							color="primary"
							:to="{name: 'admin-contact-dealer-edit', params: { id: row.item.id }}"
							class="m-2"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<v-btn fab x-small color="error" class="m-2" @click="confirm(row.item)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({
		headers: [
			{ text: "#", width: 10 },
			{ text: "Dealer", value: "Dealer" },
      { text: "Name", value: "Name" },
      { text: "Phone", value: "phone" },
			{ text: "status", value: "status" },
			{ text: "tanggal", value: "tanggal" },
			{ text: "Start", value: "Start" },
			{ text: "End", value: "End" },
			{ text: "Action", width: 100 },
		],
	}),
	computed: {
		...mapState("contactDealer", ["contactDealers"]),
	},
	methods: {
		...mapActions(["removeErrors"]),
		...mapActions("contactDealer", ["getContactDealers", "deleteContactDealer"]),
		confirm(data) {
			this.$swal({
				title: "Are you sure?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, delete it!",
			}).then((result) => {
				if (result.value) {
					this.deleteContactDealer(data.id);
					this.$swal("Deleted!", "Item Deleted", "success");
				}
			});
		},
	},
	mounted() {
		this.getContactDealers();
		this.removeErrors();
	},
};
</script>

<style>
</style>