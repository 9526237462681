<template>
  <div>
    <!-- Mobile Navigation -->
    <v-navigation-drawer
      right
      dark
      v-model="drawer"
      color="black"
      v-if="$vuetify.breakpoint.mdAndDown"
      app
    >
      <!-- Logo -->
      <v-img
        max-width="249"
        max-height="80"
        src="https://ik.imagekit.io/vconexID/Logo_MB_50th-01.png"
        contain
      ></v-img>
      <v-divider dark></v-divider>
      <!-- Navigation -->
      <v-list>
        <v-subheader class="text-capitalize">navigation</v-subheader>
        <v-list-item-group class="tw-pb-32">
          <v-list-item
            exact
            :to="item.url ? { name: item.url } : null"
            v-for="(item, i) in navigationMobile"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(item, i) in navigationFooter"
            :key="'f' + i"
            :href="item.url"
            target="_blank"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Desktop App Bar -->
    <v-app-bar
      app
      flat
      height="150"
      absolute
      dark
      color="#000000"
      v-if="$vuetify.breakpoint.lgAndUp"
    >
      <v-container class="pt-5 pl-0">
        <v-row>
          <v-col cols="6">
            <!-- Logo -->
            <router-link :to="{ name: 'home' }">
              <v-img
                max-width="249"
                max-height="57"
                src="https://ik.imagekit.io/vconexID/Mercedes/mb-logo_8_manW5qk.png"
              ></v-img>
            </router-link>
          </v-col>
          <v-col cols="6">
            <v-img
              class="float-right"
              max-width="170"
              max-height="58"
              src="https://ik.imagekit.io/vconexID/Logo_MB_50th-01.png"
              contain
            ></v-img>
          </v-col>
          <!-- Navigation on desktop -->
          <v-col cols="12">
            <v-btn
              text
              v-for="(item, index) in navigations"
              :key="index"
              exact
              :class="{
                'tw-bg-blue-600 hover:tw-bg-blue-500 white--text tw-rounded mx-1':
                  item.url == 'virtual',
              }"
              :to="item.url ? { name: item.url } : null"
              class="text-capitalize tw-text-gray-500 hover:tw-text-white"
              >{{ item.title }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- Mobile App Bar -->
    <v-app-bar
      app
      flat
      absolute
      height="100"
      dark
      color="#000000"
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <!-- Logo -->
      <router-link :to="{ name: 'home' }">
        <v-img
          max-width="249"
          max-height="57"
          src="https://ik.imagekit.io/vconexID/Mercedes/mb-logo_8_manW5qk.png"
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <div
        @click="drawer = !drawer"
        class="text-uppercase text-center pt-3 blue px-2 mr-n4 tw-h-full tw-w-20"
      >
        menu
        <br />
        <v-icon large>mdi-menu</v-icon>
      </div>
    </v-app-bar>
    <cart-dialog></cart-dialog>
    <!-- <v-tour
      name="nav"
      :steps="steps"
      :options="options"
      v-if="$vuetify.breakpoint.mdAndDown"
    ></v-tour> -->
  </div>
</template>

<script>
import CartDialog from "./CartDialog";
import { mapActions } from "vuex";

export default {
  components: {
    CartDialog,
  },
  data: () => ({
    drawer: false,
    navContact: false,
    navMobile: 1,
    navigations: [
      { title: "Home", url: "home" },
      { title: "Product", url: "car" },
      { title: "Sales Consultant", url: "dealer" },
      { title: "Virtual Showroom", url: "virtual" },
      { title: "Digital Center", url: "brochure" },
      { title: "Certified Used Car", url: "certified" },
      { title: "Accessories & Collection", url: "collection" },
      { title: "Test Drive Booking", url: "test-drive" },
    ],
    navigationMobile: [
      { title: "Home", url: "home", icon: "mdi-home" },
      { title: "Product", url: "car", icon: "mdi-car-outline" },
      { title: "Sales Consultant", url: "dealer", icon: "mdi-city" },
      { title: "Virtual Showroom", url: "virtual", icon: "mdi-rotate-3d" },
      { title: "Digital Center", url: "brochure", icon: "mdi-download" },
      { title: "Certified Used Car", url: "certified", icon: "mdi-car" },
      {
        title: "Accessories & Collection",
        url: "collection",
        icon: "mdi-bolt",
      },
      { title: "Test Drive Booking", url: "test-drive", icon: "mdi-steering" },
    ],
    navigationFooter: [
      {
        title: "Cookies",
        url:
          "https://www.mercedes-benz.co.id/passengercars/content-pool/tool-pages/legal/cookies.html",
      },
      {
        title: "Privacy Statement",
        url:
          "https://www.mercedes-benz.co.id/passengercars/content-pool/tool-pages/legal/privacy-statement.html",
      },
      {
        title: "Legal Notice",
        url:
          "https://www.mercedes-benz.co.id/passengercars/content-pool/tool-pages/legal/legal-notice.html",
      },
      { title: "FAQ", url: "/faq" },
    ],
    steps: [
      {
        target: "#mobile", // We're using document.querySelector() under the hood
        content: `Buka Navigasi Di sini`,
      },
    ],
    options: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: "Skip tour",
        buttonPrevious: "Previous",
        buttonNext: "Next",
        buttonStop: "Mengerti",
      },
    },
  }),
  methods: {
    ...mapActions(["toggleDialogCart"]),
  },
  mounted: function() {
    this.$tours["nav"] ? this.$tours["nav"].start() : "";
  },
};
</script>

<style></style>
