import ProductInvoice from '../../../api/ProductInvoice'
import router from '../../../router'

export const getProductInvoices = ({ commit }) => {
  ProductInvoice.all().then(response => {
    commit('SET_PRODUCT_INVOICES', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getProductInvoice = ({ commit }, payload) => {
  ProductInvoice.show(payload).then(response => {
    commit('SET_PRODUCT_INVOICE', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeProductInvoice = ({ dispatch }, payload) => {
  ProductInvoice.store(payload).then(() => {
    // return console.log(response.data)
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-product-invoice' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateProductInvoice = ({ dispatch }, payload) => {
  ProductInvoice.update(payload).then(() => {
    // return console.log(response.data)
    router.push({ name: 'admin-product-invoice' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteProductInvoice = ({ commit }, payload) => {
  ProductInvoice.delete(payload).then(() => {
    commit('UNSET_PRODUCT_INVOICE', payload)
  }).catch(error => {
    console.log(error)
  });
}
