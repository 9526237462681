<template>
	<div>
		<!-- App wrapper -->
		<v-app>
			<!-- Navigation -->
			<admin-navigation></admin-navigation>
			<!-- Content Wrapper -->
			<v-main>
				<!-- Provides the application the proper gutter -->
				<v-container fluid>
					<!-- Content -->
					<v-card max-width="1000" class="px-2">
						<v-row align="center" justify="space-between">
							<!-- Title -->
							<v-card-title class="tw-text-2xl">Offer Data</v-card-title>
							<!-- Add Button -->
						</v-row>
						<!-- Table -->
						<offer-table></offer-table>
					</v-card>
				</v-container>
			</v-main>

			<v-footer app>
				<v-icon>mdi-copyright</v-icon>
				{{ new Date().getFullYear() }} V-CONNEXT
			</v-footer>
		</v-app>
	</div>
</template>

<script>
import AdminNavigation from "../../../components/admin/AdminNavigation";
import OfferTable from "../../../components/admin/OfferTable";

export default {
	components: {
		AdminNavigation,
		OfferTable,
	},
};
</script>

<style>
</style>