import ContactDealer from '../../../api/ContactDealer'
import router from '../../../router'

export const getContactDealers = ({ commit }) => {
  ContactDealer.all().then(response => {
    commit('SET_CONTACT_DEALERS', response.data) 
  }).catch(error => {
    console.log(error)
  });
}

export const getContactDealer = ({ commit }, payload) => {
  ContactDealer.show(payload).then(response => {
    commit('SET_CONTACT_DEALER', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeContactDealer = ({ dispatch }, payload) => {
  ContactDealer.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-contact-dealer' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateContactDealer = ({ dispatch }, payload) => {
  ContactDealer.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-contact-dealer' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteContactDealer = ({ commit }, payload) => {
  ContactDealer.delete(payload).then(() => {
    commit('UNSET_CONTACT_DEALER', payload)
  }).catch(error => {
    console.log(error)
  });
}
