export const SET_INTERIORS = (state, payload) => {
  state.interiors = payload
}

export const SET_INTERIOR = (state, payload) => {
  state.interior = payload
}

export const UNSET_INTERIOR = (state, payload) => {
  state.interiors = state.interiors.filter(item => {
    return item.id !== payload
  })
}