export const SET_DEALERS = (state, payload) => {
  state.dealers = payload
}

export const SET_DEALER_BY_CITY = (state, payload) => {
  state.dealerByCity = payload
}

export const SET_DEALER = (state, payload) => {
  state.dealer = payload
}

export const UNSET_DEALER = (state, payload) => {
  state.dealers = state.dealers.filter(item => {
    return item.id !== payload
  })
}

export const SET_CONTACT_DEALER = (state, payload) => {
  state.contact_dealer = payload
}

