<template>
	<div>
		<v-form @submit.prevent="storeCarThreeSixty(form)">
			<v-container>
				<!-- text select mobil -->
				<v-select
					v-model="form.car_id"
					:items="cars"
					:error-messages="errors.car_id"
					item-text="name"
					item-value="id"
					label="Pilih Mobil"
					prepend-icon="mdi-car"
					required
				></v-select>
				<!-- text form path -->
				<v-text-field
					v-model="form.path"
					placeholder="link 360 image"
					:error-messages="errors.path"
					required
					prepend-icon="mdi-rotate-3d"
					class="mb-3"
				></v-text-field>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
			path: "",
			car_id: "",
		},
	}),
	methods: {
		...mapActions("carThreeSixty", ["storeCarThreeSixty"]),
		...mapActions("car", ["getCars"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("car", ["cars"]),
	},
	mounted() {
		this.getCars();
	},
};
</script>

<style>
</style>