<template>
  <div v-if="codeDealerLog">
    <v-form>
      <v-container>
        <v-row>
          <v-col>
            <v-list-item-title class="pa-2"
              >Code: {{ form.code.code }}</v-list-item-title
            >

            <v-list-item-title class="pa-2"
              >No Telp: {{ form.contact_dealer.phone }}</v-list-item-title
            >

            <v-list-item-title class="pa-2"
              >Dealer: {{ form.dealer.company }}</v-list-item-title
            >

            <v-list-item-title class="pa-2"
              >Dibuat pada: {{ new Date(form.created_at) }}</v-list-item-title
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  props: {
    id: null,
  },
  data: () => ({}),
  methods: {
    ...mapActions("codeDealerLog", ["getCodeDealerLog"]),
  },
  mounted() {
    this.getCodeDealerLog(this.id);
  },
  computed: {
    ...mapState(["errors"]),
    ...mapState("codeDealerLog", ["codeDealerLog"]),
    form: {
      get() {
        return this.codeDealerLog;
      },
    },
  },
};
</script>

<style></style>
