export const SET_CAR_BANNER = (state, payload) => {
  state.carBanner = payload
}

export const SET_CAR_DETAIL = (state, payload) => {
  state.carDetail = payload
}

export const SET_CAR_VARIANT = (state, payload) => {
  state.carVariant = payload
}

export const SET_CAR_INTERIOR = (state, payload) => {
  state.carInterior = payload
}


export const SET_CAR_THREE_SIXTY = (state, payload) => {
  state.carThreeSixty = payload
}

export const SET_CAR_SPECIFICATION = (state, payload) => {
  state.carSpecification = payload
}

export const SET_CAR_EXTERIOR_GALLERY = (state, payload) => {
  state.carExteriorGallery = payload
}

export const SET_CAR_INTERIOR_GALLERY = (state, payload) => {
  state.carInteriorGallery = payload
}

export const SET_CLEAR_COLLECTION = (state) => {
  state.collections = []
}

export const SET_COLLECTION = (state, payload) => {
  state.collections = payload
}

export const SET_NOTIFICATIONS = (state, payload) => {
  state.notifications = payload
}

export const UNSET_NOTIFICATION = (state) => {
  state.notifications = []
}

export const SET_IS_BOOKING = (state, payload) => {
  state.isBooking = payload
}

export const SET_URL_FRAME = (state, payload) => {
  state.urlVideoFrame = payload
}