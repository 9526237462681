import InventoryLog from "../../../api/InventoryLog";

export const getInventoryLogs = ({ commit }) => {
  InventoryLog.all()
    .then((response) => {
      commit("SET_INVENTORY_LOGS", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getInventoryLog = ({ commit }, payload) => {
  InventoryLog.show(payload)
    .then((response) => {
      commit("SET_INVENTORY_LOG", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const storeInventoryLog = async ({ dispatch }, payload) => {
  try {
    await InventoryLog.store(payload);
    dispatch("removeErrors", null, { root: true });
  } catch (error) {
    if (error.response.status === 422) {
      dispatch("getErrors", error.response.data.errors, { root: true });
    }
  }
};

export const deleteInventoryLog = ({ commit }, payload) => {
  InventoryLog.delete(payload)
    .then(() => {
      commit("UNSET_INVENTORY_LOG", payload);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const unsetIsClick = ({ commit }) => {
  commit("SET_UNCLICK", false);
};
