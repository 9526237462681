import Api from './Api'

const END_POINT = '/car-details'

export default {
  all() {
    return Api.get(END_POINT)
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`)
  },

  store(data) {
    var formData = new FormData()

    formData.append('image', data.image)
    formData.append('video', data.video)
    formData.append('desc', data.desc)
    formData.append('title', data.title)
    formData.append('car_id', data.car_id)

    return Api.post(END_POINT, formData)
  },

  update(data) {

    var formData = new FormData()

    formData.append('_method', "PATCH")
    formData.append('image_old', data.form.image)
    formData.append('image', data.image)
    formData.append('desc', data.form.desc)
    formData.append('title', data.form.title)
    formData.append('car_id', data.form.car_id)
    if (data.form.video == null) {
      formData.append('video', '')
    } else {
      formData.append('video', data.form.video)
    }

    return Api.post(`${END_POINT}/${data.form.id}`, formData)
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`)
  }
}