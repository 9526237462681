<template>
	<div>
		<v-form @submit.prevent="storeDealer(form)">
			<v-container>
				<!-- text select company -->
				<v-text-field
					v-model="form.company"
					placeholder="Masukan company"
					:error-messages="errors.company"
					required
					prepend-icon="mdi-office-building"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<!-- text select address -->
				<v-text-field
					v-model="form.address"
					placeholder="Masukan address"
					:error-messages="errors.address"
					required
					prepend-icon="mdi-map-marker"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<!-- text select phone -->
				<v-text-field
					v-model="form.phone"
					placeholder="Masukan phone"
					type="number"
					:error-messages="errors.phone"
					required
					prepend-icon="mdi-phone"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<!-- text select website -->
				<v-text-field
					v-model="form.website"
					placeholder="Masukan website"
					:error-messages="errors.website"
					required
					prepend-icon="mdi-web"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<!-- text select website name -->
				<v-text-field
					v-model="form.website_name"
					placeholder="Masukan website name"
					:error-messages="errors.website_name"
					required
					prepend-icon="mdi-web-box"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<v-select :items="cities" item-text="name" item-value="id" v-model="form.city_id" placeholder="Pilih Kota" :error-messages="errors.city_id" required :clearable="false" prepend-icon="mdi-city"></v-select>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		form: {
			company: "",
			address: "",
			phone: "",
			website: "",
			website_name: "",
			city_id: ''
		},
	}),
	methods: {
		...mapActions("dealer", ["storeDealer"]),
		...mapActions("city", ["getCities"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("city", ["cities"]),
	},
	mounted () {
		this.getCities();
	},
};
</script>

<style>
</style>