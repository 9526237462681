<template>
  <v-app>
    <!-- Navigation -->
    <web-navigation></web-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="background" fluid>
        <v-row>
          <v-col md="2" v-show="$vuetify.breakpoint.mdAndUp"></v-col>
          <v-col md="8" cols="12">
            <v-row>
              <v-col cols="12" md="12" class="overlay white--text pa-8">
                <!-- Certification Overview -->
                <div class="price-list">
                  <p class="mb-2 tw-text-lg">Certification Overview</p>
                  <v-divider class="tw-w-24 tw-bg-white"></v-divider>
                  <h2 class="text-h4 mt-5 my-3">
                    Mercedes-Benz Certified
                    Pre-owned Vehicle
                  </h2>
                  <p
                    class="tw-tracking-wide"
                  >Mercedes-Benz Certified adalah program mobil Mercedes-Benz bekas berkualitas yang hanya tersedia di dealer resmi Mercedes-Benz.</p>
                  <p
                    class="tw-tracking-wide"
                  >Setiap kendaraan Mercedes-Benz Certified telah melewati uji standar Multipoint Technical Checklist yang dilakukan oleh teknisi handal dan berpengalaman untuk menjamin kondisi mobil Anda dalam kondisi terbaik.</p>
                  <!-- <v-row align="center" justify="center">
                    <v-col cols="12" md="3" class="text-center">
                      <v-btn
                        small
                        dark
                        block
                        depressed 
                        class="mt-4"
                        :to="{name: 'inventory'}"
                        color="primary"
                      >Stock Tersedia</v-btn>
                    </v-col>
                  </v-row> -->
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <inventory-list></inventory-list>
    </v-main>
    <!-- Footer -->
    <web-footer></web-footer>
  </v-app>
</template>

<script>
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
import InventoryList from "@/components/web/InventoryList";

export default {
  components: {
    WebNavigation,
    WebFooter,
    InventoryList,
  },
};
</script>

<style scoped>
.background {
  background: url("https://ik.imagekit.io/vconexID/latar_Certified_Used_Car.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>