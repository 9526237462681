export const SET_BANNERS = (state, payload) => {
  state.banners = payload;
};

export const SET_BANNER = (state, payload) => {
  state.banner = payload;
};

export const UNSET_BANNER = (state, payload) => {
  state.banners = state.banners.filter((item) => {
    return item.id !== payload;
  });
};
