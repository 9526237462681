<template>
	<div v-if="form">
		<v-form>
			<v-container>
				<!-- text select company -->
				<v-text-field
					v-model="form.company"
					placeholder="Masukan company"
					:error-messages="errors.company"
					required
					prepend-icon="mdi-office-building"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<!-- text select address -->
				<v-text-field
					v-model="form.address"
					placeholder="Masukan address"
					:error-messages="errors.address"
					required
					prepend-icon="mdi-map-marker"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<!-- text select phone -->
				<v-text-field
					v-model="form.phone"
					placeholder="Masukan phone"
					:error-messages="errors.phone"
					required
					prepend-icon="mdi-phone"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<!-- text select website -->
				<v-text-field
					v-model="form.website"
					placeholder="Masukan website"
					:error-messages="errors.website"
					required
					prepend-icon="mdi-web"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<!-- text select website name -->
				<v-text-field
					v-model="form.website_name"
					placeholder="Masukan website name"
					:error-messages="errors.website_name"
					required
					prepend-icon="mdi-web-box"
					:clearable="false"
					class="mb-3"
				></v-text-field>
				<v-select :items="cities" item-text="name" item-value="id" v-model="form.city_id" placeholder="Pilih Kota" :error-messages="errors.city_id" required :clearable="false" prepend-icon="mdi-city"></v-select>
				<!-- text error -->
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="updateDealer(form)" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {
	
	},
	props: {
		id: null,
	},
	data: () => ({}),
	methods: {
		...mapActions("dealer", ["getDealer", "updateDealer"]),
		...mapActions("city", ["getCities"]),
	},
	mounted() {
		this.getDealer(this.id);
		this.getCities();
	},
	computed: {
		...mapState(["errors"]),
		...mapState("dealer", ["dealer"]),
		...mapState("city", ["cities"]),
		form: {
			get() {
				return this.dealer;
			},
		},
	},
};
</script>

<style>

</style> 