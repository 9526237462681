import Api from './Api'

const END_POINT = '/page-images'

export default {
  all() {
    return Api.get(END_POINT)
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`)
  },

  store(data) {
    var formData = new FormData()

    formData.append('image', data.image)
    formData.append('title', data.title)
    formData.append('type', data.type)

    return Api.post(END_POINT, formData)
  },

  update(data) {
    var formData = new FormData()

    formData.append('_method', "PATCH")
    formData.append('image_old', data.form.image)
    formData.append('image', data.image)
    formData.append('title', data.form.title)
    formData.append('type', data.form.type)

    return Api.post(`${END_POINT}/${data.form.id}`, formData)
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`)
  },

  getPageImageByType(type) {
    return Api.get('/getPageImage/' + type)
  },
}