<template>
	<v-row>
		<v-col cols="12" md="4" v-for="(car, index) in cars" :key="index">
			<v-card max-height="500" outlined>
				<v-container>
					<v-row justify="center" align="center">
						<v-col class="text-center">
							<h1 class="text-h5">{{ car.name }}</h1>
							<v-img max-width="100%" height="140" contain :src="car.thumbnail_image"></v-img>
							<v-btn
								small
								class="mt-3"
								color="primary"
								:to="{name: 'product', params: { id: car.id }}"
							>More Information</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({}),
	computed: {
		...mapState("car", ["cars"]),
	},
	methods: {
		...mapActions("car", ["getCars"]),
	},
	mounted() {
		this.getCars();
	},
};
</script>

<style>
</style>