<template>
  <div>
    <v-img
      height="auto"
      lazy-src="https://ik.imagekit.io/vconexID/header-homepage-mbstarexpo.jpg"
      src="https://ik.imagekit.io/vconexID/header-homepage-mbstarexpo.jpg"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
    </v-img>
    <v-img
      height="auto"
      lazy-src="https://ik.imagekit.io/vconexID/header-homepage-mobile-version-mbstarexpo.jpg"
      src="https://ik.imagekit.io/vconexID/header-homepage-mobile-version-mbstarexpo.jpg"
      v-if="$vuetify.breakpoint.smAndDown"
    >
    </v-img>
  </div>
</template>

<script>
export default {};
</script>
