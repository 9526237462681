<template>
	<div>
		<!-- data table -->
		<v-data-table :headers="headers" :items="pageImages" mobile-breakpoint>
			<template v-slot:item="row">
				<tr>
					<td>{{ row.index + 1 }}</td>
					<td>{{ row.item.title }}</td>
					<td>{{ row.item.type }}</td>
					<td>
						<v-img :src="row.item.image" max-height="150" max-width="150"></v-img>
					</td>
					<td>
						<v-btn
							fab
							x-small
							color="primary"
							:to="{name: 'admin-page-image-edit', params: { id: row.item.id }}"
							class="m-2"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<v-btn fab x-small color="error" class="m-2" @click="confirm(row.item)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({
		headers: [
			{ text: "#", width: 10 },
			{ text: "title", value: "title" },
			{ text: "type", value: "type" },
			{ text: "image", value: "image" },
			{ text: "Action", width: 100 },
		],
	}),
	computed: {
		...mapState("pageImage", ["pageImages"]),
	},
	methods: {
		...mapActions(["removeErrors"]),
		...mapActions("pageImage", ["getPageImages", "deletePageImage"]),
		confirm(data) {
			this.$swal({
				title: "Are you sure?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, delete it!",
			}).then((result) => {
				if (result.value) {
					this.deletePageImage(data.id);
					this.$swal("Deleted!", "Item Deleted", "success");
				}
			});
		},
	},
	mounted() {
		this.getPageImages();
		this.removeErrors();
	},
};
</script>

<style>
</style>