<template>
	<v-app>
		<!-- Navigation -->
		<web-navigation></web-navigation>

		<!-- Sizes your content based upon application components -->
		<v-main class="tw-bg-gray-900">
			<!-- Provides the application the proper gutter -->
			<v-container fluid class="tw-p-0">
				<virtual-show-room></virtual-show-room>
			</v-container>
		</v-main>

		<!-- Footer -->
		<web-footer></web-footer>
	</v-app>
</template>

<script>
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
import VirtualShowRoom from "../../components/web/VirtualShowRoom";

export default {
	components: {
		WebNavigation,
		WebFooter,
		VirtualShowRoom,
	},
};
</script>