import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
import "./assets/css/style.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueProgressBar from "vue-progressbar";
import VueCurrencyFilter from "vue-currency-filter";
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(Vuelidate)
Vue.use(VueProgressBar, {
  color: "#b2bec3",
  failedColor: "#e84118",
  thickness: "5px",
});
Vue.use(VueCurrencyFilter, {
  symbol: "Rp",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});
Vue.use(VueTour);

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
