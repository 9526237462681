<template>
  <v-img :src="car.main_image" v-if="car"></v-img> 
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: null
  },
  computed: {
		...mapState("car", ["car"]),
	},
	methods: {
		...mapActions("car", ["getCar"]),
	},
	mounted() {
		this.getCar(this.id);
	},
}
</script>

