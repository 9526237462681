<template>
	<div v-if="code">
		<v-form @submit.prevent="updateCode(form)">
			<v-container>
				<!-- text form link -->
				<v-text-field
					v-model="form.code"
					placeholder="Masukan code"
					:error-messages="errors.code"
					required
					prepend-icon="mdi-code-tags"
					class="mb-3"
				></v-text-field>
				<div class="text-left">
					<!-- button submit -->
					<v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	props: {
		id: null,
	},
	methods: {
		...mapActions("code", ["getCode", "updateCode"]),
	},
	mounted() {
		this.getCode(this.id);
	},
	computed: {
		...mapState(["errors"]),
		...mapState("code", ["code"]),
		form: {
			get() {
				return this.code;
			},
		},
	},
};
</script>

<style>
</style> 