<template>
	<v-dialog v-model="dialogCart" max-width="500">
		<v-card>
			<v-card-title class="headline">Your Items</v-card-title>

			<v-card-text>
				<v-list>
					<v-list-item-group color="black">
						<v-list-item v-for="(item, i) in collections" :key="i">
							<v-list-item-content>
								<v-list-item-title>{{ item.title }}a</v-list-item-title>
							</v-list-item-content>
							<v-list-item-content>
								<v-list-item-title>{{ item.price }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="red darken-1" text @click="toggleDialogCart">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({
		collections: [
			{
				title: "Brake Pads",
				image:
					"https://ik.imagekit.io/vconexID/Mercedes/Product/mb-brake-pads_4fqbd5jWQnZX.jpg",
				price: "1.500.000",
				description:
					"Exlusivity on the road and beyond - the Mercedez-AMG Collection",
			},
			{
				title: "Windscreen Wipers",
				image:
					"https://ik.imagekit.io/vconexID/Mercedes/Product/mb-suspensions_nrFZUv7t4.jpg",
				price: "1.500.000",
				description:
					"Scale miniatures, packed with detail - for true Mercedez-Benz fans!",
			},
			{
				title: "Suspensions",
				image:
					"https://ik.imagekit.io/vconexID/Mercedes/Product/mb-windscreen-wipers_fZNOVV_lHvH.jpg",
				price: "1.500.000",
				description:
					"Make an impression, with accessories such as sunglasses, watches or bags from the Mercedez-Benz Collection",
			},
		],
	}),
	computed: {
		...mapState(["dialogCart"]),
	},
	methods: {
		...mapActions(["toggleDialogCart"]),
	},
};
</script>

<style>
</style>