export const SET_PAGE_CONTENTS = (state, payload) => {
  state.pageContents = payload
}

export const SET_PAGE_CONTENT = (state, payload) => {
  state.pageContent = payload
}

export const UNSET_PAGE_CONTENT = (state, payload) => {
  state.pageContents = state.pageContents.filter(item => {
    return item.id !== payload
  })
}

export const SET_PAGE_CONTENT_FINANCE = (state, payload) => {
  state.pageContentFinance = payload
}