<template>
	<div>
		<!-- data table -->
		<v-data-table :headers="headers" :items="cars" mobile-breakpoint>
			<template v-slot:item="row">
				<tr>
					<td>{{ row.index + 1 }}</td>
					<td>{{ row.item.name }}</td>
          
					<td>
						<v-btn x-small depressed color="red lighten-1" dark v-if="row.item.limited == 1">
							limited
						</v-btn>
						<v-btn x-small depressed v-else color="grey darken-3" dark>
							no limited
						</v-btn>
					</td>
          <td>{{ row.item.link }}</td>
					<td>
						<v-img :src="row.item.thumbnail_image" max-height="80" max-width="80"></v-img>
					</td>
					<td>
						<v-btn
							fab
							x-small
							color="primary"
							:to="{name: 'admin-car-edit', params: { id: row.item.id }}"
							class="m-2"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<v-btn fab x-small color="error" class="m-2" @click="confirm(row.item)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({
		headers: [
			{ text: "#", width: 10 },
			{ text: "name", value: "name" },
      { text: "limited", value: "Type" },
      { text: "link", value: "link" },
			{ text: "gambar", value: "gambar" },
			{ text: "Action", width: 100 },
		],
	}),
	computed: {
		...mapState("car", ["cars"]),
	},
	methods: {
		...mapActions(["removeErrors"]),
		...mapActions("car", ["getCars", "deleteCar"]),
		confirm(data) {
			this.$swal({
				title: "Are you sure?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, delete it!",
			}).then((result) => {
				if (result.value) {
					this.deleteCar(data.id);
					this.$swal("Deleted!", "Item Deleted", "success");
				}
			});
		},
	},
	mounted() {
		this.getCars();
		this.removeErrors();
	},
};
</script>

<style>
</style>