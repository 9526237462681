import Api from "./Api";

const END_POINT = "/code-dealer-logs";

export default {
  all() {
    return Api.get(END_POINT);
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },
  
  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
