export const SET_PRODUCTS = (state, payload) => {
  state.products = payload
}

export const SET_PRODUCT = (state, payload) => {
  state.product = payload
}

export const UNSET_PRODUCT = (state, payload) => {
  state.products = state.products.filter(item => {
    return item.id !== payload
  })
}