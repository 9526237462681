<template>
	<div v-if="carVariant">
		<v-form @submit.prevent="updateCarVariant({form, image})">
			<v-container>
				<!-- text select mobil -->
				<v-select
					v-model="form.car_id"
					:items="cars"
					item-text="name"
					item-value="id"
					label="Pilih Mobil"
					prepend-icon="mdi-car"
					:error-messages="errors.car_id"
					required
				></v-select>
				<!-- text form warna -->
				<v-text-field
					v-model="form.color"
					placeholder="Masukan Warna"
					required
					prepend-icon="mdi-palette"
					class="mb-3"
					:error-messages="errors.color"
				></v-text-field>
				<!-- text form gambar -->
				<v-file-input
					v-model="image"
					:clearable="false"
					:error-messages="errors.image"
					chips
					label="Masukan Gambar"
				></v-file-input>

				<div class="text-left">
					<!-- button submit -->
					<v-btn
						color="primary"
						@click.prevent="updateCarVariant({form, image})"
						right
						class="mt-3"
					>Simpan</v-btn>
				</div>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
	components: {},
	props: {
		id: null,
	},
	data: () => ({
		image: [],
	}),
	methods: {
		...mapActions("carVariant", ["getCarVariant", "updateCarVariant"]),
		...mapActions("car", ["getCars"]),
	},
	mounted() {
		this.getCarVariant(this.id);
		this.getCars();
	},
	computed: {
		...mapState(["errors"]),
		...mapState("carVariant", ["carVariant"]),
		...mapState("car", ["cars"]),
		...mapGetters("carVariant", ["carVariantFormatted"]),
		form: {
			get() {
				return this.carVariantFormatted;
			},
		},
	},
};
</script>

<style>
</style> 