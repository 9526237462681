import Api from "./Api";

const END_POINT = "/codes";

export default {
  all() {
    return Api.get(END_POINT);
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  store(data) {
    return Api.post(END_POINT, {
      code: data.code,
    });
  },

  checkCode(formGuest, dealer) {
    // return console.log({dealer, formGuest})
    return Api.post(`${END_POINT}/checkCode`, {
      code: formGuest.code.toUpperCase(),
      dealer_id: dealer.id,
      sales_name: formGuest.sales_name,
      type: formGuest.type,
    });
  },

  checkCodeInventory(form) {
    return Api.post(`${END_POINT}/checkCode`, {
      code: form.code.toUpperCase(),
      type: "inventory",
      inventory_id: form.inventory_id,
    });
  },

  update(data) {
    return Api.put(`${END_POINT}/${data.id}`, {
      code: data.code,
    });
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
