import Api from './Api'

const END_POINT = '/inventories'

export default {
  all() {
    return Api.get(END_POINT)
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`)
  },

  store(data) {
    return Api.post(END_POINT, {
      model: data.model,
      price: data.price,
      exterior: data.exterior,
      interior: data.interior,
      mileage: data.mileage,
      isp_validation: data.isp_validation,
      phone: data.phone,
      phone_name: data.phone_name,
      year: data.year,
      dealer_id: data.dealer_id,
    })
  },

  update(data) {

    return Api.put(`${END_POINT}/${data.id}`, {
      model: data.model,
      price: data.price,
      exterior: data.exterior,
      interior: data.interior,
      mileage: data.mileage,
      isp_validation: data.isp_validation,
      phone: data.phone,
      phone_name: data.phone_name,
      year: data.year,
      dealer_id: data.dealer_id,
    })
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`)
  },

  showByCategory(id) {
    // return console.log(id)
    return Api.get('/getInventoryByCategory/' + id)
  }
}