<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 1.0,
    }"
    min-height="200"
    transition="fade-transition"
  >
    <v-row class="tw-py-6 lg:tw-px-20 lg:tw-py-10">
      <v-col cols="12" sm="12" md="12">
        <!-- version desktop -->
        <h1
          class="text-h2 tw-capitalize tw-text-center tw-mt-6"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          Our Line Up
        </h1>
        <!-- version mobile -->
        <h1
          class="text-h4 tw-capitalize tw-text-center"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          Our Line Up
        </h1>
      </v-col>

      <v-slide-group
        v-model="model"
        class="pa-4"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-slide-item v-for="(n, i) in lineUp" :key="i">
          <v-card 
            class="ma-4 tw-border-none"
            elevation="6" 
            dark
            :to="{ name: 'product', params: { id: n.id } }"
            height="auto"
            width="320"
          >
           <v-img
              :lazy-src="n.image"
              :src="n.image"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-slide-item>
      </v-slide-group>

      <!-- mobile version -->
      <v-slide-group
        v-model="model"
        center-active
        :show-arrows="true"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-slide-item v-for="(n, i) in lineUp" :key="i">
          <v-card
            class="ma-4 tw-border-none"
            elevation="6"
            height="auto"
            width="280"
            max-width="280"
            dark
            :to="{ name: 'product', params: { id: n.id } }"
          >
            <v-img
            contain
              :lazy-src="n.image"
              :src="n.image"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-row>
  </v-lazy>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
    model: null,
    lineUp: [
      {
        id: '1',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-01.jpg',
      },
      {
        id: '3',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-02.jpg',
      },
      {
        id: '4',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-03.jpg',
      },
      {
        id: '15',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-04.jpg',
      },
      {
        id: '16',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-05.jpg',
      },
      {
        id: '15',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-06.jpg',
      },
      {
        id: '5',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-07.jpg',
      },
      {
        id: '2',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-08.jpg',
      },
      {
        id: '6',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-09.jpg',
      },
      {
        id: '7',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-10.jpg',
      },
      {
        id: '8',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-11.jpg',
      },
      {
        id: '10',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-12.jpg',
      },
      {
        id: '11',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-13.jpg',
      },
      {
        id: '13',
        image: 'https://ik.imagekit.io/vconexID/Mercedes/LineUp/Line_Up_02-12-2020/Line_Up_Rev_02-14.jpg',
      },
    ]
  }),
};
</script>

<style lang="css">
.v-carousel__controls {
  bottom: 70px !important;
}

.v-slide-group__next .v-icon {
  font-size: 50px;
}

.v-slide-group__prev .v-icon {
  font-size: 50px;
}
</style>
