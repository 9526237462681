import Guest from "../../../api/Guest";
import ContactDealer from "../../../api/ContactDealer";
import router from "../../../router";

export const getGuests = ({ commit }) => {
  Guest.all()
    .then(response => {
      commit("SET_GUESTS", response.data);
    })
    .catch(error => {
      console.log(error);
    });
};

export const getGuest = ({ commit }, payload) => {
  Guest.show(payload)
    .then(response => {
      commit("SET_GUEST", response.data);
    })
    .catch(error => {
      console.log(error);
    });
};

export const storeGuest = ({ dispatch }, payload) => {
  Guest.store(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-guest" });
    })
    .catch(error => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const updateGuest = ({ dispatch }, payload) => {
  Guest.update(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      router.push({ name: "admin-guest" });
    })
    .catch(error => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const deleteGuest = ({ commit }, payload) => {
  Guest.delete(payload)
    .then(() => {
      commit("UNSET_GUEST", payload);
    })
    .catch(error => {
      console.log(error);
    });
};

export const checkCode = ({ commit, dispatch }, payload) => {
  if (payload.formGuest.code == "" || payload.formGuest.sales_name == "") {
    if (payload.formGuest.code == "" && payload.formGuest.sales_name == "") {
      const error = {
        code: "code is required",
        sales_name: "sales name is required",
      };

      return dispatch("getErrors", error, { root: true });
    } else if (payload.formGuest.sales_name == "") {
      const error = {
        sales_name: "sales name is required",
      };

      return dispatch("getErrors", error, { root: true });
    } else if (payload.formGuest.code == "") {
      const error = {
        code: "code is required",
      };

      return dispatch("getErrors", error, { root: true });
    }
  } else {
    
    Guest.checkCode(payload.formGuest, payload.dealer)
      .then(() => {
        ContactDealer.getByName(payload.formGuest).then(response => {
          commit("contactLog/SET_PHONE", response.data.phone, { root: true });
        });
        commit("SET_CODE_GUEST", true);
      })
      .catch(error => {
        if (error.response.status === 422) {
          dispatch("getErrors", error.response.data.errors, { root: true });
        }
      });
  }
};

export const setStatusCode = ({ commit }) => {
  commit("SET_CODE_GUEST", false);
};
