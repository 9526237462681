import Inventory from '../../../api/Inventory'
import router from '../../../router'

export const getInventories = ({ commit }) => {
  Inventory.all().then(response => {
    commit('SET_INVENTORIES', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getInventory = ({ commit }, payload) => {
  Inventory.show(payload).then(response => {
    commit('SET_INVENTORY', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeInventory = ({ dispatch }, payload) => {
  Inventory.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-inventory' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateInventory = ({ dispatch }, payload) => {
  Inventory.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-inventory' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteInventory = ({ commit }, payload) => {
  Inventory.delete(payload).then(() => {
    commit('UNSET_INVENTORY', payload)
  }).catch(error => {
    console.log(error)
  });
}

export const getInventoryByCategory = ({ commit }, payload) => {
  Inventory.showByCategory(payload).then(response => {
    commit('SET_INVENTORY_CATEGORY', response.data)
  }).catch(error => {
    console.log(error)
  });
}
