import Dealer from '../../../api/Dealer'
import router from '../../../router'

export const getDealers = ({ commit }) => {
  Dealer.all().then(response => {
    commit('SET_DEALERS', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getDealer = ({ commit }, payload) => {
  Dealer.show(payload).then(response => {
    commit('SET_DEALER', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeDealer = ({ dispatch }, payload) => {
  Dealer.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-dealer' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateDealer = ({ dispatch }, payload) => {
  Dealer.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-dealer' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteDealer = ({ commit }, payload) => {
  Dealer.delete(payload).then(() => {
    commit('UNSET_DEALER', payload)
  }).catch(error => {
    console.log(error)
  });
}

export const getContactDealer = ({ commit }, payload) => {
  Dealer.getContactDealer(payload).then(response => {
    commit('SET_CONTACT_DEALER', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getDealerByCity = ({ commit }, payload) => {
  Dealer.getDealerByCity(payload).then(response => {
    commit('SET_DEALER_BY_CITY', response.data)
  }).catch(error => {
    console.log(error)
  });
}
