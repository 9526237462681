import Api from "./Api";

export default {
  getBanner(id) {
    return Api.get("/getBanner/" + id);
  },

  getCarDetail(id) {
    return Api.get("/getCarDetail/" + id);
  },

  getCarVariant(id) {
    return Api.get("/getCarVariant/" + id);
  },

  getCarThreeSixty(id) {
    return Api.get("/getCarThreeSixty/" + id);
  },

  getCarSpecification(id) {
    return Api.get("/getCarSpecification/" + id);
  },

  getCarExteriorGallery(id) {
    return Api.get("/getCarExteriorGallery/" + id);
  },

  getCarInteriorGallery(id) {
    return Api.get("/getCarInteriorGallery/" + id);
  },

  getCollections() {
    return Api.get("/getCollections");
  },

  getCollection(id) {
    return Api.get("/getCollection/" + id);
  },
  
  getInterior(id) {
    return Api.get("/getCarInterior/" + id);
  },


  store(data) {
    // return console.log(data)
    return Api.post("/booking-drives", {
      name: data.name,
      car: data.car,
      email: data.email,
      no_telp: data.no_telp,
      address: data.address,
      date: data.date,
      dealer: data.dealer,
      contact_options: data.contact_options.toString(),
    });
  },
};
