import Api from "./Api";

const END_POINT = "/offers";

export default {
  all() {
    return Api.get("/offers");
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  store(data) {
    // return console.log(data)
    return Api.post(END_POINT, {
      car: data.car,
      phone: data.phone,
      name: data.name,
      email: data.email,
      call: data.call,
      contact_options: data.contact_options.toString(),
    });
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
