<template>
  <v-dialog
    v-model="$store.state.dialogContactDealer"
    max-width="500"
    @click:outside="e1 = 1"
  >
    <v-card>
      <v-card-text class="pa-0">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1"
              >Optional</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2"
              >Form Data</v-stepper-step
            >

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 3" step="3"
              >Link Whatsapp</v-stepper-step
            >
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    block
                    color="primary"
                    @click.stop="
                      (e1 = '2'),
                        setStatusCode(),
                        clearFormCode(),
                        ((codeSection = true), (formSection = false))
                    "
                  >
                    I Have code
                  </v-btn>
                  <v-btn
                    block
                    color="secondary"
                    class="mt-2"
                    @click="
                      (e1 = 2), ((formSection = true), (codeSection = false))
                    "
                  >
                    Dont have code
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- <v-stepper-content step="code">
              
            </v-stepper-content> -->

            <v-stepper-content step="2">
              <!-- code section -->
              <v-row v-if="codeSection">
                <v-col cols="12" md="12" v-if="!statusCode">
                  <v-form @submit.prevent="checkCode({ formGuest, dealer })">
                    <v-select
                      v-model="formGuest.sales_name"
                      :items="contactDealers"
                      item-text="name"
                      item-value="name"
                      label="*Pilih Sales"
                      :error-messages="errors.sales_name"
                    ></v-select>
                    <v-text-field
                      v-model="formGuest.code"
                      placeholder="Masukan Code"
                      required
                      :error-messages="errors.code"
                      class="mb-3"
                    ></v-text-field>

                    <div class="text-right">
                      <v-btn
                        depressed
                        color="secondary"
                        @click.stop="removeErrors(), (e1 = 1)"
                      >
                        Back
                      </v-btn>
                      <v-btn
                        depressed
                        class="ml-2"
                        type="submit"
                        color="primary"
                        >Submit</v-btn
                      >
                    </div>
                  </v-form>
                </v-col>
                <v-col cols="12" md="12" v-else>
                  Dengan mengklik tombol, saya menyetujui pengumpulan,
                  penggunaan dan / atau pengungkapan data pribadi saya oleh PT
                  Mercedes-Benz Distribution Indonesia dan dealer resmi sesuai
                  dengan peraturan perlindungan data.
                  <v-btn
                    color="primary"
                    class="mt-4"
                    block
                    depressed
                    dark
                    @click.stop="
                      $store.dispatch('toogleDialogContactDealer'),
                        backToStart()
                    "
                    :href="'https://wa.me/' + whatsapp_phone"
                    target="_blank"
                  >
                    Lanjutkan
                  </v-btn>
                </v-col>
              </v-row>
              <!-- end -->

              <form
                v-if="formSection"
                @submit.prevent="storeContactLog({ form, dealer })"
                v-show="isClick == false"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.name"
                      label="Masukan Nama anda"
                      :error-messages="errors.name"
                    ></v-text-field>
                    <v-text-field
                      v-model="form.email"
                      label="Masukan Email anda"
                      type="email"
                      :error-messages="errors.email"
                    ></v-text-field>
                    <v-text-field
                      v-model="form.phone"
                      label="Masukan No Telp Anda"
                      type="number"
                      :error-messages="errors.phone"
                    ></v-text-field>
                    <v-select
                      v-model="form.sales_name"
                      :items="contactDealers"
                      item-text="name"
                      item-value="name"
                      label="*Pilih Sales"
                      :error-messages="errors.sales_name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    Dari mana anda mengetahui tentang diadakannya pameran
                    virtual Mercedes-Benz ini?
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Social Media"
                      value="Social Media"
                      class="mt-5"
                      :error-messages="errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Website"
                      value="Website"
                      class="mt-0"
                      :error-messages="errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Sales Consultant/Referrals"
                      value="Whatsapp"
                      class="mt-0"
                      :error-messages="errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Iklan"
                      value="Iklan"
                      class="mt-0"
                      :error-messages="errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Radio"
                      value="Radio"
                      class="mt-0"
                      :error-messages="errors.source"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="form.source"
                      color="indigo"
                      label="Lainnya"
                      value="Lainnya"
                      class="mt-0"
                      :error-messages="errors.source"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    Untuk informasi lebih lanjut mengenai perlindungan data,
                    kunjungi:
                    <a href="http://mb4.me/dataprotectionID"
                      >http://mb4.me/dataprotectionID</a
                    >
                    <br />
                    <v-btn
                      color="red"
                      dark
                      small
                      class="mr-2 mt-2"
                      @click.stop="
                        $store.dispatch('toogleDialogContactDealer'),
                          backToStart()
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      small
                      class="mt-2"
                      color="primary"
                      depressed
                      dark
                      type="submit"
                    >
                      Kirim
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
              <div v-if="formSection">
                Dengan mengklik tombol, saya menyetujui pengumpulan, penggunaan
                dan / atau pengungkapan data pribadi saya oleh PT Mercedes-Benz
                Distribution Indonesia dan dealer resmi sesuai dengan peraturan
                perlindungan data.
                <v-btn
                  color="primary"
                  class="mt-4"
                  block
                  depressed
                  dark
                  v-if="whatsapp_phone && isClick"
                  @click.stop="
                    $store.dispatch('toogleDialogContactDealer'), unsetIsClick()
                  "
                  :href="'https://wa.me/' + whatsapp_phone"
                  target="_blank"
                >
                  Lanjutkan
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    contactDealers: {
      type: Array,
      required: true,
    },
    dealer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      e1: 1,
      codeSection: false,
      formSection: false,
      form: {
        name: "",
        email: "",
        phone: "",
        sales_name: "",
        source: [],
      },
      formGuest: {
        code: "",
        sales_name: "",
        type: "dealer",
      },
    };
  },
  methods: {
    ...mapActions("code", ["checkCode", "setStatusCode"]),
    ...mapActions(["removeErrors"]),
    ...mapActions("contactLog", ["storeContactLog", "unsetIsClick"]),
    clearFormCode() {
      this.formGuest.code = "";
      this.formGuest.sales_name = "";
    },
    backToStart() {
      this.e1 = 1;
    },
  },
  computed: {
    ...mapState("code", ["statusCode"]),
    ...mapState(["errors"]),
    ...mapState("contactLog", ["isClick", "whatsapp_phone"]),
    ...mapState("dealer", ["contact_dealer"]),
  },
};
</script>

<style></style>
