<template>
  <v-row>
    <v-col cols="12" v-for="(city, i) in cities" :key="i">
      <h2 v-text="city.name" class="text-h4 text-capitalize"></h2>
      <v-row>
        <v-col v-for="(dealer, i) in city.dealers" cols="12" lg="4" :key="i">
          <v-card outlined class="tw-rounded-lg">
            <v-card-title
              v-text="dealer.company"
              class="text-capitalize text-h5"
            ></v-card-title>
            <v-card-text>
              <p class="tw-text-gray-800">
                {{ dealer.address }}
                <br />
                {{ dealer.city }}
              </p>
            </v-card-text>
            <v-card-actions class="pl-3 pb-3">
              <v-btn
                v-if="hour >= 10 && hour < 22"
                fab
                x-small
                elevation="0"
                color="green accent-3"
                class="focus:tw-outline-none "
                @click.prevent="
                  contactButtonClicked(
                    dealer.id,
                    dealer.contact_dealers,
                    dealer
                  ),
                    getContactDealer(dealer.id)
                "
              >
                <v-icon color="white">mdi-whatsapp</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <modal-contact-dealer
      :id="id"
      :contactDealers="contact_dealer"
      :dealer="dealer"
    ></modal-contact-dealer>
  </v-row>
</template>

<script>
import ModalContactDealer from "./ModalContactDealer";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    ModalContactDealer,
  },
  data() {
    return {
      id: 0,
      contactDealers: [],
      dealer: {},
      minute: new Date().getMinutes(),
      hour: new Date().getHours(),
    };
  },
  methods: {
    ...mapActions("city", ["getCities"]),
    ...mapActions("dealer", ["getContactDealer"]),
    contactButtonClicked(id, contactDealers, dealer) {
      this.id = id;
      this.contactDealers = contactDealers;
      this.dealer = dealer;
      this.$store.dispatch("toogleDialogContactDealer");
    },
  },
  mounted() {
    this.getCities();
  },
  computed: {
    ...mapState("city", ["cities"]),
    ...mapState("dealer", ["contact_dealer"]),
    timeNow: function() {
      const time = this.hour + ":" + this.minute;
      console.log(typeof this.hour);
      return time;
    },
  },
};
</script>

<style></style>
