<template>
	<v-row class="lg:tw-px-24">
		<v-col cols="12" md="3">
			<!-- Category List Desktop -->
			<v-list dark color="transparent" v-show="$vuetify.breakpoint.mdAndUp">
				<v-subheader class="text-uppercase">collection & accessories</v-subheader>
				<v-list-item-group dark mandatory color="grey darken-1">
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title @click="getCollections">All Products</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-for="(category, i) in productCategories" :key="i">
						<v-list-item-content>
							<v-list-item-title v-text="category.name" @click="getCollection(category.id)"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>

			<!-- Category List Mobile -->
			<v-btn
				color="grey darken-4"
				dark
				class="ma-1"
				v-show="$vuetify.breakpoint.smAndDown"
				@click="getCollections"
			>All Products</v-btn>
			<v-btn
				color="grey darken-4"
				dark
				class="ma-1"
				v-for="(category, i) in productCategories"
				:key="i"
				v-show="$vuetify.breakpoint.smAndDown"
				@click="getCollection(category.id)"
			>{{ category.name }}</v-btn>
		</v-col>

		<v-col cols="12" md="8">
			<!-- Collection Card -->
			<v-row>
				<v-col cols="12" md="4" v-for="(collection, index) in collections" :key="index">
					<v-img
						:src="collection.image"
						@click="toggleDialogCollection(), getProduct(collection.id)"
						class="tw-cursor-pointer"
					></v-img>
					<h2
						class="white--text text-h6 tw-cursor-pointer mt-3"
						@click="toggleDialogCollection(), getProduct(collection.id)"
					>{{ collection.name }}</h2>
					<p class="white--text text-body-1">{{ collection.price | currency }}</p>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	computed: {
		...mapState("productCategory", ["productCategories"]),
		...mapState("web", ["collections"]),
	},
	methods: {
		...mapActions("productCategory", ["getProductCategories"]),
		...mapActions("product", ["getProduct"]),
		...mapActions("web", ["getCollections", "getCollection"]),
		...mapActions(["toggleDialogCollection"]),
	},
	mounted() {
		this.getProductCategories();
		this.getCollections();
	},
};
</script>