import Api from "./Api";

const END_POINT = "/contact-logs";

export default {
  all() {
    return Api.get(END_POINT);
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  store(data) {
    // return console.log(data)
    return Api.post(END_POINT, {
      dealer_id: data.dealer.id,
      phone: data.form.phone,
      name: data.form.name,
      email: data.form.email,
      contact_options: data.form.contact_options,
      sales_name: data.form.sales_name,
      source: data.form.source.toString(),
    });
  },

  update(data) {
    // return console.log(data)
    return Api.put(`${END_POINT}/${data.id}`, {
      dealer_id: data.dealer_id,
      phone: data.phone,
      name: data.name,
      email: data.email,
    });
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
