export const SET_CONTACT_LOGS = (state, payload) => {
  state.contactLogs = payload;
};

export const SET_CONTACT_LOG = (state, payload) => {
  state.contactLog = payload;
};

export const UNSET_CONTACT_LOG = (state, payload) => {
  state.contactLogs = state.contactLogs.filter((item) => {
    return item.id !== payload;
  });
};

export const SET_IS_CLICK = (state, payload) => {
  state.isClick = payload;
};

export const SET_UNCLICK = (state, payload) => {
  state.isClick = payload;
};

export const SET_PHONE = (state, payload) => {
  state.whatsapp_phone = payload;
};
