<template>
	<div v-if="form">
		<v-form>
			<v-container>
				<!-- text select name -->
				<v-text-field
					v-model="form.name"
					placeholder="Masukan name"
					required
					:clearable="false"
					prepend-icon="mdi-format-title"
					class="mb-3"
				></v-text-field>
				<!-- text error -->
				<small class="tw-text-red-500" v-if="errors.content">{{ errors.content[0] }}</small>
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="updateProductCategory(form)" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {
	
	},
	props: {
		id: null,
	},
	data: () => ({}),
	methods: {
		...mapActions("productCategory", ["getProductCategory", "updateProductCategory"]),
	},
	mounted() {
		this.getProductCategory(this.id);
	},
	computed: {
		...mapState(["errors"]),
		...mapState("productCategory", ["productCategory"]),
		form: {
			get() {
				return this.productCategory;
			},
		},
	},
};
</script>

<style>

</style> 