export const SET_GUESTS = (state, payload) => {
  state.guests = payload
}

export const SET_GUEST = (state, payload) => {
  state.guest = payload
}

export const SET_CODE_GUEST = (state, payload) => {
  state.statusCode = payload
}

export const UNSET_GUEST = (state, payload) => {
  state.guests = state.guests.filter(item => {
    return item.id !== payload
  })
}