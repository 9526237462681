export const SET_CODE_INVENTORY_LOGS = (state, payload) => {
  state.codeInventoryLogs = payload
}

export const SET_CODE_INVENTORY_LOG = (state, payload) => {
  state.codeInventoryLog = payload
}

export const UNSET_CODE_INVENTORY_LOG = (state, payload) => {
  state.codeInventoryLogs = state.codeInventoryLogs.filter(item => {
    return item.id !== payload
  })
}