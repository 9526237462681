<template>
	<div>
		<v-form>
			<v-container>
				<!-- text select name -->
				<v-text-field
					v-model="form.name"
					placeholder="Masukan name"
					clearable
					required
					prepend-icon="mdi-format-title"
					class="mb-3"
				></v-text-field>
				<!-- text select email -->
				<v-text-field
					v-model="form.email"
					placeholder="Masukan email"
					clearable
					required
					prepend-icon="mdi-email"
					class="mb-3"
				></v-text-field>
				<!-- text select no_telp -->
				<v-text-field
					v-model="form.no_telp"
					placeholder="Masukan no_telp"
					clearable
					required
					prepend-icon="mdi-phone"
					class="mb-3"
				></v-text-field>
				<!-- text select address -->
				<v-text-field
					v-model="form.address"
					placeholder="Masukan address"
					clearable
					required
					prepend-icon="mdi-map-marker"
					class="mb-3"
				></v-text-field>
				<!-- text select date -->
				<v-text-field
					v-model="form.date"
					placeholder="Masukan date"
					clearable
					required
					type="date"
					prepend-icon="mdi-calendar"
					class="mb-3"
				></v-text-field>
				<!-- text select car -->
				<v-select
					v-model="form.car_id"
					:items="cars"
					item-text="name" 
					item-value="id"
					label="Pilih Mobil"
					prepend-icon="mdi-car"
					required
				></v-select>
				<!-- text error -->
				<small class="tw-text-red-500" v-if="errors.content">{{ errors.content[0] }}</small>
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="storeBookingDrive(form)" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {
	},
	data: () => ({
		form: {
			name: "",
			email: '',
			address: '',
			date: '',
			car_id: '',
			no_telp: '',
		},
	}),
	methods: {
		...mapActions("bookingDrive", ["storeBookingDrive"]),
		...mapActions("car", ["getCars"]),
	},
	computed: {
		...mapState(["errors"]),
		...mapState("car", ["cars"]),
	},
	mounted() {
		this.getCars();
	},
};
</script>

<style>
	
</style>