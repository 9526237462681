<template>
	<div v-if="form">
		<v-form>
			<v-container>
				<v-row>
				<!-- text select car -->
					<v-col>
						<v-list-item-title class="pa-2">Mobil: {{ form.car }}</v-list-item-title>

						<v-list-item-title class="pa-2">Nama: {{ form.name }}</v-list-item-title>

						<v-list-item-title class="pa-2">Email: {{ form.email }}</v-list-item-title>

						<v-list-item-title class="pa-2">No Telp: {{ form.no_telp }}</v-list-item-title>

						<v-list-item-title class="pa-2">Alamat: {{ form.address }}</v-list-item-title>

						<v-list-item-title class="pa-2">Tanggal: {{ form.date }}</v-list-item-title>

						<v-list-item-title class="pa-2">Pilihan Kontak: {{ form.contact_options }}</v-list-item-title>

						<v-list-item-title class="pa-2">Dealer: {{ form.dealer }}</v-list-item-title>

						<v-list-item-title class="pa-2">Kode Booking: {{ form.booking_code }}</v-list-item-title>

						<v-list-item-title class="pa-2">Dibuat pada: {{ new Date(form.created_at) }}</v-list-item-title>
					</v-col>
				</v-row>
				<!-- text error -->
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	components: {},
	props: {
		id: null,
	},
	data: () => ({}),
	methods: {
		...mapActions("bookingDrive", ["getBookingDrive"]),
	},
	mounted() {
		this.getBookingDrive(this.id);
	},
	computed: {
		...mapState(["errors"]),
		...mapState("bookingDrive", ["bookingDrive"]),
		form: {
			get() {
				return this.bookingDrive;
			},
		},
	},
};
</script>

<style>

</style> 