import Web from "../../../api/Web";
// import router from '../../../router'

export const getBanner = ({ commit }, payload) => {
  Web.getBanner(payload)
    .then((response) => {
      commit("SET_CAR_BANNER", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCarDetail = ({ commit }, payload) => {
  Web.getCarDetail(payload)
    .then((response) => {
      commit("SET_CAR_DETAIL", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCarVariant = ({ commit }, payload) => {
  Web.getCarVariant(payload)
    .then((response) => {
      commit("SET_CAR_VARIANT", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCarThreeSixty = ({ commit }, payload) => {
  Web.getCarThreeSixty(payload)
    .then((response) => {
      commit("SET_CAR_THREE_SIXTY", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCarSpecification = ({ commit }, payload) => {
  Web.getCarSpecification(payload)
    .then((response) => {
      commit("SET_CAR_SPECIFICATION", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getInterior = ({ commit }, payload) => {
  Web.getInterior(payload).then(response => {
    commit('SET_CAR_INTERIOR', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getCarExteriorGallery = ({ commit }, payload) => {
  Web.getCarExteriorGallery(payload)
    .then((response) => {
      commit("SET_CAR_EXTERIOR_GALLERY", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCarInteriorGallery = ({ commit }, payload) => {
  Web.getCarInteriorGallery(payload)
    .then((response) => {
      commit("SET_CAR_INTERIOR_GALLERY", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const clearCollection = ({ commit }) => {
  commit("SET_CLEAR_COLLECTION");
};

export const getCollections = ({ commit, dispatch }) => {
  Web.getCollections()
    .then((response) => {
      dispatch("clearCollection", null);
      commit("SET_COLLECTION", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCollection = ({ commit, dispatch }, payload) => {
  Web.getCollection(payload)
    .then((response) => {
      dispatch("clearCollection", null);
      commit("SET_COLLECTION", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const removeNotifications = ({ commit }, payload) => {
  commit("UNSET_NOTIFICATION", payload);
};

export const setNotifications = ({ commit }, payload) => {
  commit("SET_NOTIFICATIONS", payload);
};

export const setIsBooking = ({ commit }, payload) => {
  commit("SET_IS_BOOKING", payload);
};

export const storeBookingDrive = ({ dispatch }, payload) => {
  Web.store(payload)
    .then(() => {
      dispatch("removeErrors", null, { root: true });
      dispatch("removeNotifications", null);
      dispatch("setIsBooking", true);
      dispatch(
        "setNotifications",
        "Terima kasih. Tim Mercedes-Benz akan segera menghubungi Anda untuk detail lebih lanjut."
      );
    })
    .catch((error) => {
      // check if validation error
      if (error.response.status === 422) {
        dispatch("getErrors", error.response.data.errors, { root: true });
      }
    });
};

export const changeUrlFrame = ({commit}, payload) => {
  commit("SET_URL_FRAME", payload);
};
