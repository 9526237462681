import PageImage from '../../../api/PageImage'
import router from '../../../router'

export const getPageImages = ({ commit }) => {
  PageImage.all().then(response => {
    commit('SET_PAGE_IMAGES', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getPageImage = ({ commit }, payload) => {
  PageImage.show(payload).then(response => {
    commit('SET_PAGE_IMAGE', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storePageImage = ({ dispatch }, payload) => {
  PageImage.store(payload).then(() => {
    // return console.log(response.data)
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-page-image' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updatePageImage = ({ dispatch }, payload) => {
  PageImage.update(payload).then(() => {
    // return console.log(response.data)
    router.push({ name: 'admin-page-image' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deletePageImage = ({ commit }, payload) => {
  PageImage.delete(payload).then(() => {
    commit('UNSET_PAGE_IMAGE', payload)
  }).catch(error => {
    console.log(error)
  });
}

//web
export const getPageImageByType = ({ commit }, payload) => {
  PageImage.getPageImageByType(payload).then(response => {
    commit('SET_PAGE_IMAGE_FINANCE', response.data)
  }).catch(error => {
    console.log(error)
  });
}
