import CodeDealerLog from '../../../api/CodeDealerLog'

export const getCodeDealerLogs = ({ commit }) => {
  CodeDealerLog.all().then(response => {
    commit('SET_CODE_DEALER_LOGS', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getCodeDealerLog = ({ commit }, payload) => {
  CodeDealerLog.show(payload).then(response => {
    commit('SET_CODE_DEALER_LOG', response.data)
  }).catch(error => {
    console.log(error)
  });
}


export const deleteCodeDealerLog = ({ commit }, payload) => {
  CodeDealerLog.delete(payload).then(() => {
    commit('UNSET_CODE_DEALER_LOG', payload)
  }).catch(error => {
    console.log(error)
  });
}
