<template>
	<v-row >
		<v-col>
			<v-col cols="12" v-for="(inventory, index) in inventoryByCategory" class="black--text" :key="index">
				<v-row >
					<v-col cols="12" md="6">
						<v-carousel hide-delimiters>
							<v-carousel-item
								v-for="(item,i) in items"
								:key="i"
								:src="item.src"
							></v-carousel-item>
						</v-carousel>
					</v-col>
					<v-col cols="12" md="6">
						<v-row >
							<v-col md="12">
								<h2 class="text-h4">
									Dealer:
									{{ inventory.dealer.company }}
								</h2>
								<h2 class="tw-text-xl">
									Model:
									{{ inventory.name }}
								</h2>
								<h2 class="tw-text-xl">
									Exterior Color:
									{{ inventory.exterior }}
								</h2>
								<h2 class="tw-text-xl">
									Interior Color:
									{{ inventory.interior }}
								</h2>
								<h2 class="tw-text-xl">
									Mileage:
									{{ inventory.speed }} Km
								</h2>
								<h2 class="tw-text-xl">
									ISP Validation:
									{{ inventory.speed }}
								</h2>
								<h2 class="tw-text-xl">
									Price:
									{{ inventory.price }}
								</h2>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-divider class="tw-bg-gray-900" v-if="index != inventoryByCategory.length - 1"></v-divider>
			</v-col>
		</v-col>
	</v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	props: {
    id: null
	},
	data: () => ({
		items: [
      {
        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
      },
      {
        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
      },
      {
        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
      },
      {
        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
      },
    ],
	}),
	methods: {
		...mapActions("inventory", ["getInventoryByCategory"]),
	},
	computed: {
		...mapState("inventory", ["inventoryByCategory"]),
	},
	mounted() {
		this.getInventoryByCategory(this.id);
	},
};
</script>

<style>
</style>