<template>
	<div>
		<!-- data table -->
		<v-data-table :headers="headers" :items="offers" mobile-breakpoint>
			<template v-slot:item="row">
				<tr>
					<td>{{ row.index + 1 }}</td>
					<td>{{ row.item.name }}</td>
					<td>{{ row.item.car }}</td>
					<td>{{ row.item.phone }}</td>
					<td>{{ row.item.email }}</td>
					<td>
						<v-btn
							fab
							x-small
							color="primary"
							:to="{name: 'admin-offer-edit', params: { id: row.item.id }}"
							class="m-2"
						>
							<v-icon>mdi-eye-outline</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data: () => ({
		headers: [
			{ text: "#", width: 10 },
			{ text: "Name", value: "name" },
			{ text: "Car", value: "Car" },
			{ text: "Phone", value: "Phone" },
			{ text: "email", value: "email" },
			{ text: "Action", width: 100 },
		],
	}),
	computed: {
		...mapState("offer", ["offers"]),
	},
	methods: {
		...mapActions(["removeErrors"]),
		...mapActions("offer", ["getOffers"]),
	},
	mounted() {
		this.getOffers();
		this.removeErrors();
	},
};
</script>

<style>
</style>