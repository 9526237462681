export const SET_INVENTORY_LOGS = (state, payload) => {
  state.inventoryLogs = payload;
};

export const SET_INVENTORY_LOG = (state, payload) => {
  state.inventoryLog = payload;
};

export const UNSET_INVENTORY_LOG = (state, payload) => {
  state.inventoryLogs = state.inventoryLogs.filter((item) => {
    return item.id !== payload;
  });
};

export const SET_IS_CLICK = (state, payload) => {
  state.isClick = payload;
};

export const SET_UNCLICK = (state, payload) => {
  state.isClick = payload;
};

export const SET_PHONE = (state, payload) => {
  state.whatsapp_phone = payload;
};
