import PageContent from '../../../api/PageContent'
import router from '../../../router'

export const getPageContents = ({ commit }) => {
  PageContent.all().then(response => {
    commit('SET_PAGE_CONTENTS', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getPageContent = ({ commit }, payload) => {
  PageContent.show(payload).then(response => {
    commit('SET_PAGE_CONTENT', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storePageContent = ({ dispatch }, payload) => {
  PageContent.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-page-content' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updatePageContent = ({ dispatch }, payload) => {
  PageContent.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-page-content' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deletePageContent = ({ commit }, payload) => {
  PageContent.delete(payload).then(() => {
    commit('UNSET_PAGE_CONTENT', payload)
  }).catch(error => {
    console.log(error)
  });
}

//web
export const getPageContentByType = ({ commit }, payload) => {
  PageContent.getPageContentByType(payload).then(response => {
    commit('SET_PAGE_CONTENT_FINANCE', response.data)
  }).catch(error => {
    console.log(error)
  });
}
