<template>
  <v-app>
    <!-- Navigation -->
    <web-navigation></web-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container>
        <v-row>
          <v-col cols="12" md="8" class="black--text">
            <!-- Price List -->
            <div class="price-list mb-16">
              <p class="mb-2">Price List 2020</p>
              <v-divider class="tw-w-24 tw-bg-black"></v-divider>
              <h2 class="text-h4 mt-3">Price List</h2>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="pt-2"
                    v-for="(pl, i) in pricelist"
                    :key="i"
                  >
                    <a :href="pl.pdf" class="black--text">
                      {{ pl.name }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!-- Brochure NGCC -->
            <div class="brochure mb-16">
              <p class="mb-2">Brochure</p>
              <v-divider class="tw-w-24 tw-bg-black"></v-divider>
              <h2 class="text-h4 mt-3">NGCC</h2>
              <v-list-item dark class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="pt-2"
                    v-for="(bn, i) in brochureNgcc"
                    :key="i"
                  >
                    <a :href="bn.pdf" class="black--text">
                      {{ bn.name }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!-- Brochure Saloon -->
            <div class="brochure mb-16">
              <p class="mb-2">Brochure</p>
              <v-divider class="tw-w-24 tw-bg-black"></v-divider>
              <h2 class="text-h4 mt-3">SALOON</h2>
              <v-list-item dark class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="pt-2"
                    v-for="(bl, i) in brochureSaloon"
                    :key="i"
                  >
                    <a :href="bl.pdf" class="black--text">
                      {{ bl.name }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!-- Brochure SUV -->
            <div class="brochure mb-16">
              <p class="mb-2">Brochure</p>
              <v-divider class="tw-w-24 tw-bg-black"></v-divider>
              <h2 class="text-h4 mt-3">SUV</h2>
              <v-list-item dark class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="pt-2"
                    v-for="(bs, i) in brochureSuv"
                    :key="i"
                  >
                    <a :href="bs.pdf" class="black--text">
                      {{ bs.name }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!-- Brochure Van -->
            <div class="price-list mb-16">
              <p class="mb-2">Brochure</p>
              <v-divider class="tw-w-24 tw-bg-black"></v-divider>
              <h2 class="text-h4 mt-3">VAN</h2>
              <v-list-item dark class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="pt-2"
                    v-for="(bv, i) in brochureVan"
                    :key="i"
                  >
                    <a :href="bv.pdf" class="black--text">
                      {{ bv.name }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!-- Brochure Dreamcar -->
            <div class="price-list mb-16">
              <p class="mb-2">Brochure</p>
              <v-divider class="tw-w-24 tw-bg-black"></v-divider>
              <h2 class="text-h4 mt-3">Dreamcars</h2>
              <v-list-item dark class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="pt-2"
                    v-for="(bdc, i) in brochureDreamCars"
                    :key="i"
                  >
                    <a :href="bdc.pdf" class="black--text">
                      {{ bdc.name }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Footer -->
    <web-footer></web-footer>
  </v-app>
</template>

<script>
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";

export default {
  components: {
    WebNavigation,
    WebFooter,
  },
  data: () => ({
    pricelist: [
      {
        name: "Download Price List - November 2020",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/pricelist/_jcr_content/par/interactions.attachments.0.MB%20Pricelist%20COMPACT%20November%202020%20Mobile_01.pdf",
      },
    ],
    brochureNgcc: [
      {
        name: "Download The new A-Class Sedan Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-ngcc/_jcr_content/par/interactions.attachments.1.A-Class%20Vert-Brochure_004withlink_compressed.pdf",
      },
      {
        name: "Download The new CLA 200 AMG Line Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-ngcc/_jcr_content/par/interactions.attachments.2.CLA%20200%20AMG%20Line_Vertical%20e-Brochure.pdf",
      },
      {
        name: "Download A-Class A 200 Progressive Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-ngcc/_jcr_content/par/interactions.attachments.5.Download%20A-Class%20Brochure.pdf",
      },
      {
        name: "Download B-Class B 200 Progressive Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-ngcc/_jcr_content/par/interactions.attachments.0.B%20200%20Progressive%20Line%20v1_01.pdf",
      },
    ],
    brochureSaloon: [
      {
        name: "Download S-Class Edition 50 Brochure",
        pdf:
          "https://ik.imagekit.io/vconexID/Mercedes/PDF/S-Class_Edition_50_01.pdf",
      },
      {
        name: "Download C 180 Avantgarde Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.3.C180%20Vert-Brochure_010-withlink.pdf",
      },
      {
        name: "Download AMG E 53 4MATIC+ Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.4.Mercedes-AMG%20E%2053%204MATIC%20.pdf",
      },
      {
        name: "Download AMG E 53 4MATIC+ Coupé Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.5.Mercedes-AMG%20E%2053%204MATIC%20%20Coupe.pdf",
      },
      {
        name: "Download AMG E 53 4MATIC+ Estate Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.6.Mercedes-AMG%20E%2053%204MATIC%20%20Estate.pdf",
      },
      {
        name:
          "Download C-Class Facelift C 200 Avantgarde & C 300 AMG Line Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.2.C-Class%20Facelift%20Brochure%20V1_03.pdf",
      },
      {
        name:
          "Download E-Class E 200 Avantgarde, E 300 Avantgarde SportStyle, E 350 AMG Line Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.7.E-Class_W%20123_E200_E300_E350_Brochure_Tablet.pdf",
      },
      {
        name: "Download Mercedes-AMG E 43 Saloon Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.8.Download%20E%2043%20AMG%20Brochure.pdf",
      },
      {
        name: "Download S-Class S 450 L Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.1.S%20450%20L_V1_01.pdf",
      },
      {
        name: "Download The Mercedes-AMG A 35 4MATIC Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.9.M-%20AMG%20A%2035%20OPT%202_withlink.pdf",
      },
      {
        name: "Download The Mercedes-AMG GT 53 4MATIC Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-saloon/_jcr_content/par/interactions.attachments.10.M-AMG%20GT%2053%204MATIC%204%20OPT%202_withlink.pdf",
      },
    ],
    brochureSuv: [
      {
        name: "Download The new GLA Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-suv/_jcr_content/par/interactions.attachments.0.GLA%20200%20Progressive%20Line.pdf",
      },
      {
        name: "Download The new GLB Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-suv/_jcr_content/par/interactions.attachments.1.GLB%20200%20Progressive%20Line.pdf",
      },
      {
        name: "Download GLS 450 AMG Line Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-suv/_jcr_content/par/interactions.attachments.4.The_GLS_450_AMGLine_brochure.pdf",
      },
      {
        name: "Download The new GLE 450 AMG Line Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-suv/_jcr_content/par/interactions.attachments.9.The%20GLE%20450%20AMG%20Line.pdf",
      },
      {
        name: "Download Mercedes-AMG GLE 43 Coupé Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-suv/_jcr_content/par/interactions.attachments.10.Download%20GLE%2043%20AMG%20Coupe%20Brochure.pdf",
      },
      {
        name: "Download Mercedes-AMG G 63 Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-suv/_jcr_content/par/interactions.attachments.11.Download%20Mercedes-AMG%20G63.pdf",
      },
      {
        name: "Download The GLC 200, GLC 300 4MATIC Coupé Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-suv/_jcr_content/par/interactions.attachments.8.Download%20GLC%20300%20AMG%20Coupe%20Brochure.pdf",
      },
      {
        name: "Download GLE 450 Coupé Brochure",
        pdf:
          "https://ik.imagekit.io/vconexID/Mercedes/PDF/GLE_450_4MATIC_Coupe__AMG_Line__CBU__V1.pdf",
      },
      {
        name: "Download Mercedes-AMG GLE 53 Coupé Brochure",
        pdf:
          "https://ik.imagekit.io/vconexID/Mercedes/PDF/GLE_53_4MATIC_Coupe__V2.pdf",
      },
    ],
    brochureVan: [
      {
        name: "Download The new V-Class Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-van/_jcr_content/par/interactions.attachments.0.New%20V-Class%20-25072020.pdf",
      },
      {
        name: "Download The Vito Tourer Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-van/_jcr_content/par/interactions.attachments.1.The%20Vito%20Tourer.pdf",
      },
    ],
    brochureDreamCars: [
      {
        name: "Download CLS 350 AMG Line Brochure",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-dreamcars/_jcr_content/par/interactions.attachments.1.Download%20CLS%20Brochure.pdf",
      },
      {
        name: "Download The C 200 Cabriolet AMG Line",
        pdf:
          "https://www.mercedes-benz.co.id/passengercars/being-an-owner/brochure-price/md-dreamcars/_jcr_content/par/interactions.attachments.2.C_200_Cabriolet_AMG_Line-withlink.pdf",
      },
      {
        name: "Download Mercedes-AMG GT R Coupé Brochure",
        pdf: "https://ik.imagekit.io/vconexID/Mercedes/PDF/AMG_GT_R_Coupe.pdf",
      },
    ],
  }),
};
</script>

<style scoped>
.background {
  background: url("https://ik.imagekit.io/vconexID/Mercedes/bg-inventory_OwUhJgCb7J.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>