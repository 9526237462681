import Api from './Api'

const END_POINT = '/cars'

export default {
  all() {
    return Api.get(END_POINT)
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`)
  },

  store(data) {
    var formData = new FormData()

    formData.append('image', data.thumbnail_image)
    formData.append('name', data.name)
    formData.append('limited', data.limited)
    formData.append('link', data.link)
    formData.append('main_image', data.main_image)

    return Api.post(END_POINT, formData)
  },

  update(data) {

    var formData = new FormData()

    formData.append('_method', "PATCH")
    formData.append('image_old', data.form.image)
    formData.append('image', data.image.thumbnail_image)
    formData.append('name', data.form.name)
    formData.append('limited', data.form.limited)
    formData.append('link', data.form.link)
    formData.append('main_image', data.image.main_image)

    return Api.post(`${END_POINT}/${data.form.id}`, formData)
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`)
  },

  showLimitedCar() {
    return Api.get('/showLimitedCar')
  }
}