<template>
  <v-app>
    <!-- Navigation -->
    <web-navigation></web-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      
      <!-- <home-slider></home-slider> -->
      <home-image-v-2></home-image-v-2>
      <v-container fluid class="py-0">
        <!-- v3 -->
        <event-info></event-info>
        <!-- version 2 -->
        
        <home-virtual-v-2></home-virtual-v-2>
        <home-infomation-v-2></home-infomation-v-2>
        <home-line-up-v-2></home-line-up-v-2>
        <home-choose-car-v-2></home-choose-car-v-2>
        <home-test-drive-v-2></home-test-drive-v-2>
        <home-video-v-2></home-video-v-2>
        <!-- version 1 -->
        <!-- Home Carousel -->
        <!-- Virtual Button -->
        <!-- <virtual-button></virtual-button> -->
        <!-- Event List -->
        <!-- <event-list></event-list> -->
        <!-- Operational Hour -->
        <!-- <operational-hour></operational-hour> -->
        <!-- Event Program -->
        <!-- <event-program></event-program> -->
      </v-container>
    </v-main>

    <!-- Footer -->
    <web-footer></web-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
// import HomeSlider from "../../components/web/HomeSlider";
// import VirtualButton from "../../components/web/VirtualButton";
// import EventList from "../../components/web/EventList";
// import EventProgram from "../../components/web/EventProgram";
// import OperationalHour from "../../components/web/OperationalHour";
import HomeImageV2 from "@/components/web/HomeImageV2";
import HomeLineUpV2 from "@/components/web/HomeLineUpV2";
import HomeInfomationV2 from "@/components/web/HomeInfomationV2";
import HomeVirtualV2 from "@/components/web/HomeVirtualV2";
import HomeChooseCarV2 from "@/components/web/HomeChooseCarV2";
import HomeTestDriveV2 from "@/components/web/HomeTestDriveV2";
import HomeVideoV2 from "@/components/web/HomeVideoV2";
import EventInfo from "@/components/web/EventInfo";

export default {
  components: {
    WebNavigation,
    WebFooter,
    // HomeSlider,
    // VirtualButton,
    // EventList,
    // EventProgram,
    // OperationalHour,
    HomeImageV2,
    HomeLineUpV2,
    HomeInfomationV2,
    HomeVirtualV2,
    HomeChooseCarV2,
    HomeTestDriveV2,
    HomeVideoV2,
    EventInfo
  },
  methods: {
    ...mapActions(["toggleDialogOffer"]),
  },
};
</script>

<style></style>
