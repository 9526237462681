<template>
  <div>
    <v-form @submit.prevent="storeFaq(form)">
      <v-container>
        <!-- question input -->
        <p>Question :</p>
        <quill-editor ref="myQuillEditor" v-model="form.question" :error-messages="errors.question" />
        <!-- answer input -->
        <p class="mt-4">Answer :</p>
        <quill-editor ref="myQuillEditor" v-model="form.answer" :error-messages="errors.answer" />
        <div class="text-left">
          <!-- button submit -->
          <v-btn color="primary" type="submit" class="mt-3">Simpan</v-btn>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data: () => ({
    form: {
      answer: "",
      question: "",
    },
  }),
  methods: {
    ...mapActions("faq", ["storeFaq"]),
  },
  computed: {
    ...mapState(["errors"]),
  },
};
</script>

<style>
.ql-container {
  height: 300px !important;
}
</style>