import Api from "./Api";

const END_POINT = "/faqs";

export default {
  all() {
    return Api.get(END_POINT);
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  store(data) {
    return Api.post(END_POINT, {
      question: data.question,
      answer: data.answer,
    });
  },

  update(data) {
    return Api.put(`${END_POINT}/${data.id}`, {
      question: data.question,
      answer: data.answer,
    });
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
