<template>
	<div>
		<router-view></router-view>

		<!-- floating button sounded -->
		<v-btn fab fixed small bottom color="#1976D2" left dark class="focus:tw-outline-none" v-if="this.$route.name !== 'virtual' && audioStatus" @click="audioButton = false, audioStatus = false">
			<v-icon>mdi-volume-high</v-icon>
		</v-btn>
		<!-- end -->

		<!-- floating button off -->
		<v-btn fab fixed small bottom left color="#1976D2" dark class="focus:tw-outline-none" v-if="this.$route.name !== 'virtual' && audioStatus == false" @click="audioButton = true, audioStatus = true">
			<v-icon>mdi-volume-off</v-icon>
		</v-btn>
		<!-- end -->

		<!-- audio music -->
		<audio autoplay loop class="tw-inline-block" v-if="this.$route.name !== 'virtual' && audioButton">
			<source src="./assets/music.mp3" type="audio/mpeg" />
		</audio>
		<!-- end -->

		<!-- set progressbar -->
		<vue-progress-bar></vue-progress-bar>
			
	</div>
</template>



<script>
export default {
	data: () => ({
		audioButton: true,
		audioStatus: true
	}),
	mounted() {
		//  [App.vue specific] When App.vue is finish loading finish the progress bar
		this.$Progress.finish();
	},
	created() {
		//  [App.vue specific] When App.vue is first loaded start the progress bar
		this.$Progress.start();
		//  hook the progress bar to start before we move router-view
		this.$router.beforeEach((to, from, next) => {
			//  does the page we want to go to have a meta.progress object
			if (to.meta.progress !== undefined) {
				let meta = to.meta.progress;
				// parse meta tags
				this.$Progress.parseMeta(meta);
			}
			//  start the progress bar
			this.$Progress.start();
			//  continue to next page
			next();
		});
		//  hook the progress bar to finish after we've finished moving router-view
		this.$router.afterEach(() => {
			//  finish the progress bar
			this.$Progress.finish();
		});
	},
};
</script>

<style scoped>
	@font-face {
		font-family: "corpoa";
		src: local("CorporateA"),
			url("./assets/font/CorporateACon-Reg.ttf") format("truetype");
	}

	@font-face {
		font-family: "corpos";
		src: local("CorporateS"),
			url("./assets/font/CorporateS-Regular.ttf") format("truetype");
	}
</style>
