<template>
  <v-app>
    <!-- Navigation -->
    <web-navigation></web-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <car-thumbnail :id="id"></car-thumbnail>
      <v-container fluid class="py-12 px-10">
        <car-detail :id="id"></car-detail>
        <car-variant :id="id"></car-variant>
        <car-three-sixty :id="id"></car-three-sixty>
        <car-virtual :id="id"></car-virtual>
        <car-link-button :id="id"></car-link-button>
      </v-container>
    </v-main>

    <!-- Footer -->
    <web-footer></web-footer>
  </v-app>
</template>

<script>
import WebNavigation from "../../components/web/WebNavigation";
import WebFooter from "../../components/web/WebFooter";
import CarDetail from "../../components/web/CarDetail";
import CarThumbnail from "../../components/web/CarThumbnail";
import CarVariant from "../../components/web/CarVariant";
import CarThreeSixty from "../../components/web/CarThreeSixty";
import CarLinkButton from "../../components/web/CarLinkButton";
import CarVirtual from "../../components/web/CarVirtual";

export default {
  props: {
    id: null,
  },
  components: {
    WebNavigation,
    WebFooter,
    CarDetail,
    CarThumbnail,
    CarVariant,
    CarThreeSixty,
    CarLinkButton,
    CarVirtual,
  },
};
</script>

<style>
</style>