import Api from "./Api";

const END_POINT = "/dealers";

export default {
  all() {
    return Api.get(END_POINT);
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  store(data) {
    return Api.post(END_POINT, {
      company: data.company,
      address: data.address,
      phone: data.phone,
      website_name: data.website_name,
      website: data.website,
      city_id: data.city_id,
    });
  },

  update(data) {
    return Api.put(`${END_POINT}/${data.id}`, {
      company: data.company,
      address: data.address,
      phone: data.phone,
      website_name: data.website_name,
      website: data.website,
      city_id: data.city_id,
    });
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },

  getContactDealer(id) {
    return Api.get("/getContactDealer/" + id);
  },
  
  getDealerByCity(id) {
    return Api.get("/getDealerByCity/" + id);
  },
};
