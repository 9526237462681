export const SET_CAR_DETAILS = (state, payload) => {
  state.carDetails = payload
}

export const SET_CAR_DETAIL = (state, payload) => {
  state.carDetail = payload
}

export const UNSET_CAR_DETAIL = (state, payload) => {
  state.carDetails = state.carDetails.filter(item => {
    return item.id !== payload
  })
}