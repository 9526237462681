export const SET_OFFERS = (state, payload) => {
  state.offers = payload
}

export const SET_OFFER = (state, payload) => {
  state.offer = payload
}

export const UNSET_OFFER = (state, payload) => {
  state.offers = state.offers.filter(item => {
    return item.id !== payload
  })
}