import ProductCategory from '../../../api/ProductCategory'
import router from '../../../router'

export const getProductCategories = ({ commit }) => {
  ProductCategory.all().then(response => {
    commit('SET_PRODUCT_CATEGORIES', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getProductCategory = ({ commit }, payload) => {
  ProductCategory.show(payload).then(response => {
    commit('SET_PRODUCT_CATEGORY', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeProductCategory = ({ dispatch }, payload) => {
  ProductCategory.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-product-category' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateProductCategory = ({ dispatch }, payload) => {
  ProductCategory.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-product-category' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteProductCategory = ({ commit }, payload) => {
  ProductCategory.delete(payload).then(() => {
    commit('UNSET_PRODUCT_CATEGORY', payload)
  }).catch(error => {
    console.log(error)
  });
}
