import BookingDrive from '../../../api/BookingDrive'
import router from '../../../router'

export const getBookingDrives = ({ commit }) => {
  BookingDrive.all().then(response => {
    commit('SET_DRIVES', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getBookingDrive = ({ commit }, payload) => {
  BookingDrive.show(payload).then(response => {
    commit('SET_DRIVE', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeBookingDrive = ({ dispatch }, payload) => {
  BookingDrive.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateBookingDrive = ({ dispatch }, payload) => {
  BookingDrive.update(payload).then(() => {
    router.push({ name: 'admin-booking-drive' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteBookingDrive = ({ commit }, payload) => {
  BookingDrive.delete(payload).then(() => {
    commit('UNSET_DRIVE', payload)
  }).catch(error => {
    console.log(error)
  });
}
