export const SET_CONTACT_DEALERS = (state, payload) => {
  state.contactDealers = payload
}

export const SET_CONTACT_DEALER = (state, payload) => {
  state.contactDealer = payload
}

export const UNSET_CONTACT_DEALER = (state, payload) => {
  state.contactDealers = state.contactDealers.filter(item => {
    return item.id !== payload
  })
}
