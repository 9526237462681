<template>
  <div>
    <!-- Navigation Drawer -->
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list shaped>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in items" :to="{name: item.url}" :key="i">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- App Header -->
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Admin</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="confirm">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    drawer: null,
    items: [
      {
        icon: "mdi-tools",
        text: "Banner",
        url: "admin-banner",
      },
      {
        icon: "mdi-card-account-details",
        text: "Booking Drive",
        url: "admin-booking-drive",
      },
      {
        icon: "mdi-car",
        text: "Car",
        url: "admin-car",
      },
      {
        icon: "mdi-text-box-search",
        text: "Car Detail",
        url: "admin-car-detail",
      },
      {
        icon: "mdi-palette",
        text: "Car Variant",
        url: "admin-car-variant",
      },
      {
        icon: "mdi-rotate-3d",
        text: "Car Three Sixties",
        url: "admin-car-three-sixty",
      },
      {
        icon: "mdi-phone",
        text: "Contact Dealer",
        url: "admin-contact-dealer",
      },
      {
        icon: "mdi-code-tags",
        text: "Code",
        url: "admin-code",
      },
      {
        icon: "mdi-head-cog",
        text: "Code Dealer Log",
        url: "admin-code-dealer-log",
      },
      {
        icon: "mdi-head-cog",
        text: "Code Inventory Log",
        url: "admin-code-inventory-log",
      },
      {
        icon: "mdi-history",
        text: "Contact Log",
        url: "admin-contact-log",
      },
      {
        icon: "mdi-office-building",
        text: "Dealer",
        url: "admin-dealer",
      },
      {
        icon: "mdi-office-building",
        text: "Faq",
        url: "admin-faq",
      },
      {
        icon: "mdi-tools",
        text: "Inventories",
        url: "admin-inventory",
      },
      {
        icon: "mdi-history",
        text: "Inventory Logs",
        url: "admin-inventory-log",
      },
      {
        icon: "mdi-home",
        text: "Inventory Galleries",
        url: "admin-inventory-gallery",
      },
      {
        icon: "mdi-car-door",
        text: "Interiors",
        url: "admin-interior",
      },
      {
        icon: "mdi-content-save-edit",
        text: "Page Content",
        url: "admin-page-content",
      },
      {
        icon: "mdi-content-save-settings",
        text: "Page Image",
        url: "admin-page-image",
      },
      {
        icon: "mdi-basket",
        text: "Product",
        url: "admin-product",
      },
      {
        icon: "mdi-shape",
        text: "Product Category",
        url: "admin-product-category",
      },
      {
        icon: "mdi-offer",
        text: "Offer",
        url: "admin-offer",
      },
      {
        icon: "mdi-city",
        text: "City",
        url: "admin-city",
      },
    ],
  }),
  methods: {
    ...mapActions("auth", ["logout"]),
    confirm() {
      this.$swal({
        title: "Are you sure?",
        text: "All your session will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Logout",
      }).then((result) => {
        if (result.value) {
          this.logout();
        }
      });
    },
  },
};
</script>

<style>
</style>