<template>
	<!-- check if banner state loaded -->
	<div v-if="banner">
		<v-form>
			<v-container>
				<!-- text form mobil -->
				<v-select
					v-model="form.car_id"
					:items="cars"
					item-text="name"
					item-value="id"
					label="Pilih Mobil"
					prepend-icon="mdi-car"
					:error-messages="errors.car_id"
					required
				></v-select>
				<!-- text form gambar -->
				<v-file-input
					v-model="image"
					:clearable="false"
					chips
					:error-messages="errors.image"
					label="File input w/ chips"
				></v-file-input>
				<!-- button submit -->
				<v-btn color="primary" @click.prevent="updateBanner({form, image})" right class="mt-3">Simpan</v-btn>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
	props: {
		id: null,
	},
	data: () => ({
		image: [],
	}),
	methods: {
		...mapActions("banner", ["getBanner", "updateBanner"]),
		...mapActions("car", ["getCars"]),
	},
	mounted() {
		this.getBanner(this.id);
		this.getCars();
	},
	computed: {
		...mapState(["errors"]),
		...mapState("car", ["cars"]),
		...mapState("banner", ["banner"]),
		...mapGetters("banner", ["formattedBanner"]),
		form: {
			get() {
				return this.formattedBanner;
			},
		},
	},
};
</script>