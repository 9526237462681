import CarDetail from '../../../api/CarDetail'
import router from '../../../router'

export const getCarDetails = ({ commit }) => {
  CarDetail.all().then(response => {
    commit('SET_CAR_DETAILS', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const getCarDetail = ({ commit }, payload) => {
  CarDetail.show(payload).then(response => {
    commit('SET_CAR_DETAIL', response.data)
  }).catch(error => {
    console.log(error)
  });
}

export const storeCarDetail = ({ dispatch }, payload) => {
  CarDetail.store(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-car-detail' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const updateCarDetail = ({ dispatch }, payload) => {
  CarDetail.update(payload).then(() => {
    dispatch('removeErrors', null, { root: true })
    router.push({ name: 'admin-car-detail' })
  }).catch(error => {
    // check if validation error
    if (error.response.status === 422) {
      dispatch('getErrors', error.response.data.errors, { root: true })
    }
  })
}

export const deleteCarDetail = ({ commit }, payload) => {
  CarDetail.delete(payload).then(() => {
    commit('UNSET_CAR_DETAIL', payload)
  }).catch(error => {
    console.log(error)
  });
}





