<template>
  <div>
    <v-row class="h-100 min-vh-100">
      <v-col xl="12" class="min-vh-100 tw-py-0">
        <iframe
          width="100%"
          height="640"
          style="width: 100%; height: 640px; border: none; max-width: 100%"
          frameborder="0"
          allowfullscreen
          allow="xr-spatial-tracking; gyroscope; accelerometer"
          scrolling="no"
          src="https://kuula.co/share/collection/7P9vs?fs=0&vr=1&gyro=0&thumbs=-1&margin=7&info=0&logo=-1"
        ></iframe>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
