<template>
  <div>
    <v-carousel
      v-show="banners"
      height="auto"
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-carousel-item
        v-for="(car, i) in banners"
        :key="i"
        :src="car.image"
      ></v-carousel-item>
    </v-carousel>
    <v-carousel
      v-show="banners"
      height="auto"
      hide-delimiters
      show-arrows-on-hover
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-carousel-item
        v-for="(car, i) in banners"
        :key="i"
        :src="car.image"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions("banner", ["getBanners"]),
  },
  mounted() {
    this.getBanners();
  },
  computed: {
    ...mapState("banner", ["banners"]),
  },
};
</script>

<style></style>
