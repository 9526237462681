import Api from "./Api";

const END_POINT = "/inventory-logs";

export default {
  all() {
    return Api.get(END_POINT);
  },

  show(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  store(data) {
    return Api.post(END_POINT, {
      phone: data.phone,
      name: data.name,
      email: data.email,
      source: data.source.toString(),
      inventory_id: data.inventory_id,
    });
  },

  update(data) {
    // return console.log(data)
    return Api.put(`${END_POINT}/${data.id}`, {
      dealer_id: data.dealer_id,
      phone: data.phone,
      name: data.name,
      email: data.email,
    });
  },

  delete(id) {
    return Api.delete(`${END_POINT}/${id}`);
  },
};
