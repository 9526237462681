<template>
  <v-dialog v-model="dialogOffer" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Claim This Offer</span>
      </v-card-title>
      <form @submit.prevent="storeOffer(form), clearForm()">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.call"
                  :error-messages="errors.call"
                  :items="calls"
                  label="*Panggilan"
                ></v-select>
                <v-text-field
                  label="*Nama"
                  v-model="form.name"
                  :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                  label="*e-mail"
                  v-model="form.email"
                  :error-messages="errors.email"
                ></v-text-field>
                <v-text-field
                  label="*Telepon"
                  type="number"
                  v-model="form.phone"
                  :error-messages="errors.phone"
                ></v-text-field>
                <v-select
                  v-model="form.car"
                  :items="limitedCar"
                  item-text="name"
                  item-value="name"
                  label="*Pilih Mobil"
                  :error-messages="errors.car"
                ></v-select>
              </v-col>
              <v-col cols="12">
                Dengan ini saya memberikan persetujuan atas data yang
                dikumpulkan untuk diproses dan digunakan oleh PT Mercedes-Benz
                Distribution Indonesia dan dealer resmi untuk tujuan memberi
                informasi dan penawaran mengenai produk serta jasa melalui:
                <v-checkbox
                  v-model="form.contact_options"
                  color="indigo"
                  label="Telepon"
                  value="phone"
                  class="mt-5"
                  :error-messages="errors.contact_options"
                ></v-checkbox>
                <v-checkbox
                  v-model="form.contact_options"
                  color="indigo"
                  label="e-mail"
                  value="email"
                  class="mt-0"
                  :error-messages="errors.contact_options"
                ></v-checkbox>
                <v-checkbox
                  v-model="form.contact_options"
                  color="indigo"
                  label="SMS/WhatsApp"
                  value="whatsapp"
                  class="mt-0"
                  :error-messages="errors.contact_options"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                Untuk informasi lebih lanjut mengenai perlindungan data,
                kunjungi:
                <a href="http://mb4.me/dataprotectionID"
                  >http://mb4.me/dataprotectionID</a
                >
                <br />*Apabila e-mail tidak di terima dalam 5 (lima) menit,
                silahkan membuka "spam box" Anda
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-5">
          <v-btn color="blue darken-1" type="submit" dark depressed>
            Save
          </v-btn>
          <v-btn
            color="red darken-1"
            dark
            depressed
            @click="toggleDialogOffer(), clearForm(), removeErrors()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
    form: {
      name: "",
      email: "",
      phone: "",
      car: "",
      call: "",
      contact_options: [],
    },
    calls: ["Bapak", "Ibu"],
  }),
  methods: {
    ...mapActions(["removeErrors", "toggleDialogOffer"]),
    ...mapActions("car", ["showLimitedCar"]),
    ...mapActions("offer", ["storeOffer"]),
    clearForm() {
      (this.form.name = ""),
        (this.form.email = ""),
        (this.form.phone = ""),
        (this.form.car = ""),
        (this.form.call = ""),
        (this.form.contact_options = []);
      this.form.source = [];
    },
  },
  mounted() {
    this.showLimitedCar();
  },
  computed: {
    ...mapState(["errors", "dialogOffer"]),
    ...mapState("car", ["limitedCar"]),
    ...mapState("offer", ["hasSubmit"]),
  },
};
</script>

<style>
</style>